
import { Flex, Row, Col, Button, Card, Collapse, Tooltip } from 'antd'

import './styles/loans.css'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getLoans, createNewApplication, Switch } from '../api/api'
import * as com from '../../Common'
import * as interview from '../../Defs/interviewdefs'
import { State } from '../../State'
import { getCurrentState, } from '../../Store'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as act from "../../Store/actions"
import * as prgr from "../../Store/progress"
import TopNavBar from './TopNavbar'

import dayjs from 'dayjs'
import LandingMenu, { CustomerHeader } from '../../Landing/LandingMenu'
import cloneDeep from 'lodash/cloneDeep'



const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(prgr.UpdateChangeProgressType(step, stage))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
})

const loanPurposeMap = {
    purchase: 'Purchase',
    purchaserehab: 'Purchase and Rehab',
    refinance: 'Refinance',
    cashoutrefinance: 'Cash-out Refinance',
}

function Loans(props) {
    const [dataLoans, setDataLoans] = useState({
        loans: [],
        loanid: ''
    })
    const isMobile = window.innerWidth < 768
    const customerId = com.getCustomerId()
    const dispatch = useDispatch()
    const subscription = useSelector(state => {

        return state.application
    })
    const history = useHistory()
    const isHardMoney = sessionStorage.getItem("isHardMoneyLoanCustomer") === "true"
    useEffect(() => {
        GetLoans()
    }, [])

    const getloanAmount = (item) => {
        if (item.state.application.product.mortgageapplied === "hardmoneyloan") {
            if (item.state.application.property.purpose === "purchaserehab") {
                return item.state.application.hardmoneyloan.initialloanamount == '' ? '$--' : `$ ${com.commaize(item.state.application.hardmoneyloan.initialloanamount)}`
            }
            if (item.state.application.property.purpose === "refinance") {
                return item.state.application.hardmoneyloan.refinanceloanamount == '' ? '$--' : `$ ${com.commaize(item.state.application.hardmoneyloan.refinanceloanamount)}`
            }
            if (item.state.application.property.purpose === "cashoutrefinance") {
                return item.state.application.hardmoneyloan.refinanceloanamount == '' ? '$--' : `$ ${com.commaize(item.state.application.hardmoneyloan.refinanceloanamount)}`
            }
            return "$-- "
        } else {
            return item.state.application.selection.loansize == '' ? '$--' : `$ ${com.commaize(item.state.application.selection.loansize)}`
        }
    }
    const getAddress = (item) => {

        if (item.state.application.property.address == '' && item.state.application.property.county == '') {
            return 'TBD'
        } else {
            if (item.state.application.property.address !== '') {
                return item.state.application.property.address
            }
            if (item.state.application.property.county !== '') {
                return com.removeCountySuffix(item.state.application.property.county) + ", " + com.convertStateToAbbr(item.state.application.property.state)
            }
        }






    }
    return (
        <div style={{ background: 'white' }}>
            {/* <CustomerHeader /> */}
            <TopNavBar></TopNavBar>
            <Flex gap="middle" wrap>
                <Row className='w-100'>
                    <Col span={24}>
                        <div className="LoansContent">
                            <div className='LoansTitle text-left'>Welcome back, {subscription.borrower.firstname}</div>
                            <div className={isMobile ? 'w-100' : 'w-100 d-flex justify-content-between text-left mt-4 align-items-center'}>
                                <div >
                                    <div className='YourLoans'>Your loans</div>
                                    <div className='loansIntroduce '>Here is an overview of your current and past loans. Select an application to continue.</div>
                                </div>
                                <div className={isMobile ? 'mt-3' : ''}>
                                    <Button className='zeitro-default-button' onClick={
                                        () => openNewApplication()
                                    }  >New application</Button>
                                </div>
                            </div>
                            {/* extra={<div className='Incomplete'>
                                        Incomplete
                                    </div>} */}
                            <Col span={24} >
                                {dataLoans.loans.sort((a, b) => {
                                    if (!a.state.progress || a.state.progress.lasttouch == null) return 1
                                    if (!b.state.progress || b.state.progress.lasttouch == null) return -1
                                    return dayjs(b.state.progress.lasttouch).valueOf() - dayjs(a.state.progress.lasttouch).valueOf()

                                }).sort((a, b) => {
                                    return dataLoans.loanid === a.loanid ? -1 : 1
                                }).map((item) => {

                                    return <Card key={item.loanid} className='mt-4 ' type="inner" title={LoansTitleDom(item)} >
                                        <Row className='align-items-center justify-content-between'>
                                            {!isMobile && <>
                                                <Row className='justify-content-between' style={{
                                                    width: '30%'
                                                }}>
                                                    <Col >
                                                        <div style={{ color: "#6E6E70", fontWeight: 500, fontSize: 14 }}>Property address</div>





                                                        <Tooltip placement="top" title={getAddress(item)} >
                                                            <div className='dataLoansText overflowH' style={{ width: 200 }} >{getAddress(item)}</div>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col >

                                                        <div style={{ color: "#6E6E70", fontWeight: 500, fontSize: 14 }} >Property type</div>
                                                        <div className='dataLoansText'>{item.state.application.property.propertytype == '' ? '--' : com.getPropertyTypeName(item.state.application.property.propertytype)}</div>
                                                    </Col>
                                                </Row>
                                                <Row className='justify-content-between ' style={{
                                                    width: '30%'
                                                }}>
                                                    <Col >
                                                        <div style={{ color: "#6E6E70", fontWeight: 500, fontSize: 14 }}>Loan officer</div>
                                                        <div className='dataLoansText'>{item.loanofficername === '' ? 'N/A' : item.loanofficername}</div>
                                                    </Col>
                                                    <Col>
                                                        <div style={{ color: "#6E6E70", fontWeight: 500, fontSize: 14 }}>Loan amount</div>
                                                        <div className='dataLoansText'>{
                                                            getloanAmount(item)
                                                        }</div>
                                                    </Col>
                                                </Row>


                                                <Col className='d-flex justify-content-end'>
                                                    <Button style={{ height: 40, minWidth: 150, fontSize: 16, fontWeight: 600 }}
                                                        onClick={() => {

                                                            onSwitch(dataLoans.loanid === item.loanid, item)
                                                        }}

                                                        type='primary'>{
                                                            dataLoans.loanid === item.loanid ? 'Continue' : "View"


                                                        }</Button>
                                                </Col></>}
                                            {
                                                isMobile && <>
                                                    <div style={{ width: "100%" }}>
                                                        <Col className='d-flex align-items-center justify-content-between borderBottom' >
                                                            <div style={{ color: "#6E6E70", width: '60%', fontWeight: 500, fontSize: 14 }}>Property address</div>
                                                            <div className='MDataLoansText' style={{ color: '' }}>{item.state.application.property.address == '' ? '--' : item.state.application.property.address}</div>
                                                        </Col>
                                                        <Col className='d-flex align-items-center justify-content-between mt-3 borderBottom' >
                                                            <div style={{ color: "#6E6E70", width: '60%', fontWeight: 500, fontSize: 14 }}>Property type</div>
                                                            <div className='MDataLoansText'>{item.state.application.property.propertytype == '' ? '--' : com.getPropertyTypeName(item.state.application.property.propertytype)}</div>
                                                        </Col>
                                                    </div>
                                                    <div style={{ width: "100%" }}>
                                                        <Col className='d-flex align-items-center justify-content-between mt-3 borderBottom' >
                                                            <div style={{ color: "#6E6E70", width: '60%', fontWeight: 500, fontSize: 14 }}>Loan officer</div>
                                                            <div className='MDataLoansText'>{item.loanofficername}</div>
                                                        </Col>
                                                        <Col className='d-flex align-items-center justify-content-between mt-3 borderBottom' >
                                                            <div style={{ color: "#6E6E70", width: '60%', fontWeight: 500, fontSize: 14 }}>Loan amount</div>
                                                            <div className='MDataLoansText'>{
                                                                item.state.application.selection.loansize == '' ? '$--' : `$ ${item.state.application.selection.loansize}`


                                                            }</div>
                                                        </Col>
                                                        <Col className="mt-3 ">
                                                            <Button style={{ height: 40, width: '100%' }}
                                                                onClick={() => {

                                                                    onSwitch(dataLoans.loanid === item.loanid, item)
                                                                }}

                                                                type='primary'>{
                                                                    dataLoans.loanid === item.loanid ? 'Continue' : "View"


                                                                }</Button>
                                                        </Col>
                                                    </div>

                                                </>
                                            }
                                        </Row>

                                    </Card>
                                })}
                            </Col>
                            {/* <Col span={24}>
                                <div className='YourLoans mt-3 text-left'>

                                    Frequently asked questions
                                </div>
                                <div className='faq_Collapse '>
                                    <Collapse
                                        bordered={false}
                                        expandIconPosition='end'
                                        items={[
                                            {
                                                key: '1',
                                                label: 'How long does loan approval take?',
                                                children: <p>The specific documents vary by loan type, but generally include proof of income, credit history, and personal identification. Your Loan Officer will provide a detailed checklist.</p>,
                                            },
                                            {
                                                key: '2',
                                                label: 'What types of loans do I qualify for?',
                                                children: <p>The specific documents vary by loan type, but generally include proof of income, credit history, and personal identification. Your Loan Officer will provide a detailed checklist.</p>,
                                            },
                                            {
                                                key: '3',
                                                label: 'What documents do I need to provide?',
                                                children: <p>The specific documents vary by loan type, but generally include proof of income, credit history, and personal identification. Your Loan Officer will provide a detailed checklist.</p>,
                                            },
                                        ]}
                                    />
                                </div>

                            </Col> */}
                        </div>
                    </Col>

                </Row>
            </Flex>
        </div>
    )
    async function GetLoans() {
        const { loanid, loans } = await getLoans()
        loans.forEach(loan => {
            loan.state = JSON.parse(loan.state)
            loan.lostate = JSON.parse(loan.lostate)
        })
        setDataLoans({
            loans,
            loanid
        })


    }
    async function openNewApplication() {

        try {
            const newS = JSON.parse((() => { let a = new State(); return a.toJson() })())
            let data = { LoanID: dataLoans.loanid, NewState: com.ascii2hex(JSON.stringify(newS)) }


            const { Status, State: ApplicationState, Text } = await createNewApplication(data)
            if (Status !== "OK") {
                console.log(Text)
                alert("Looks like there was a problem. Please contact your loan officer for help.")
            } else {
                let st = JSON.parse(ApplicationState)
                props.updateChangeApplication(st.application)
                // dispatch({ type: interview.CHANGE_APPLICATION, data: st.application })
                getCurrentState()
                sessionStorage.removeItem("state")
                sessionStorage.removeItem("originalstate")
                let url = "/services/preapp?customerid=" + customerId
                if (isHardMoney) {
                    let newState = cloneDeep(st)
                    newState.application.product.mortgageapplied = "hardmoneyloan"
                    sessionStorage.setItem("state", JSON.stringify(newState))
                    sessionStorage.setItem("originalstate", JSON.stringify(newState))
                    url = "/application/hardmoneyloan/interview/main/borrower"
                }
                window.location.href = url
            }
        } catch (error) {
            window.document.dispatchEvent(new Event('reauthenticate'), "")
        }


    }
    async function onSwitch(isCurrent, loan) {
        let js = { LoanID: loan.loanid }
        if (isCurrent) {

            let interviewIndex = Math.min(11, parseInt(props.progress.interview.step))
            // depreciate this later
            let isPrequal = props.application.property.purpose === "purchase" && !props.application.property.hascontract
            let url = "/application/interview/main/" + interviewIndex
            try {
                const { Status, State: StateSwitch } = await Switch(js)
                if (Status === 'OK') {
                    let st = JSON.parse(StateSwitch)
                    props.updateChangeApplication(st.application)
                    getCurrentState()
                    url = "/application/interview/main/" + interviewIndex
                    if (st.application.product.mortgageapplied === "hardmoneyloan") {
                        url = "/application/hardmoneyloan/interview/main/" + interviewIndex
                    }
                    history.push(url)
                } else {
                    alert("Something went wrong. Please try later or contact your loan officer for help.")
                }
            } catch (error) {
                window.document.dispatchEvent(new Event('reauthenticate'), "")
            }
        } else {
            try {
                const { Status, State: StateSwitch } = await Switch(js)
                if (Status === 'OK') {
                    let st = JSON.parse(StateSwitch)
                    let isHardMoneyLoan = st.application.product.mortgageapplied === "hardmoneyloan"
                    props.updateChangeApplication(st.application)
                    getCurrentState()
                    let u = "/application/interview/main"
                    if (isHardMoneyLoan) {
                        u = "/application/hardmoneyloan/interview/main/overview"
                    }

                    window.location.href = u
                } else {
                    alert("Something went wrong. Please try later or contact your loan officer for help.")
                }
            } catch (error) {
                window.document.dispatchEvent(new Event('reauthenticate'), "")
            }


        }

    }
    function LoansTitleDom(item) {

        const getLoanPurpose = () => {
            if (item.state.application.property.purpose == null) return 'Loan Purpose TBD'
            if (item.state.application.product.mortgageapplied === "hardmoneyloan")
                return 'Hard Money - ' + loanPurposeMap[item.state.application.property.purpose]
            if (item.state.application.product.mortgageapplied === "nonqm")
                return 'Non QM - ' + loanPurposeMap[item.state.application.property.purpose]
            return 'Home' + " " + loanPurposeMap[item.state.application.property.purpose]
        }

        return (
            <div style={{ maxWidth: '100%' }} className='d-flex align-items-center '>
                <div>
                    {item.state.application.property.purpose !== 'purchase' ? <img src='/money.svg'></img> : <img src='/house.svg'></img>}

                </div>
                <div className='ml-2' style={{

                    fontWeight: 500,
                    color: '#333',
                    fontSize: 16

                }}>
                    {getLoanPurpose()}
                    <span className='ml-1'> {!isMobile ? '·' : ''}  </span>
                    <span style={isMobile ? { display: 'block', color: '#6E6E70', fontWeight: 400, fontSize: 16 } : { color: '#6E6E70', fontWeight: 400, fontSize: 16 }}>
                        {item.state.progress && item.state.progress.lasttouch ? dayjs(item.state.progress.lasttouch).format('MM/DD/YYYY HH:mm A') : '--'}</span>
                </div>

            </div>


        )

    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Loans)