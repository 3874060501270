import { useState, useRef } from 'react';
import {
    Checkbox,
    Radio,
    InputNumber,
    Button,
    Modal,
    Form,
    Col,
    Row,
} from 'antd';
import "./Common.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import "./Declarations.css";
import { useHistory } from "react-router-dom"
import { bankruptcyTypeOptions, DeclarationsYesNoOptions } from './constants'

const mapStateToProps = (state) => {
    return {
        status: state.application.status,
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
    changeDeclaration: (val, verb) => {
        dispatch(act.ChangeDeclaration(val, "borrower", verb))
    },
});


export default function Declarations(props) {
    const interviewfinished = props.status === 'interviewfinished'
    let declarations = props.application.borrower.declarations
    const bankrupcies = ["chapter7", "chapter11", "chapter12", "chapter13"]
    const initialList = [
        {
            question: 'Will you occupy the property as your primary residence?',
            id: 'occupyasprimary',
            select: declarations.occupyasprimary,
        },
        {
            question: 'If this is a Purchase Transaction. Do you have a family relationship or business affiliation with the seller of the property?',
            id: 'familyrelationship',
            select: declarations.familyrelationship,
        },
        {
            question: 'Are you borrowing any money for this real estate transaction (e.g. money for your closing costs or down payment) or obtaining any money from another party such as the seller or realtor, that you have not disclosed on this loan application?',
            id: 'downpaymentborrowed',
            select: declarations.downpaymentborrowed,
            money: declarations.moneyborrowed,
        },
        {
            question: 'Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?',
            id: 'applyingonanother',
            select: declarations.applyingonanother,
        },
        {
            question: 'Have you or will you be applying for any new credit (e.g. installment loan, credit card, etc.) on or before closing this loan that is not disclosed with this application?',
            id: 'applyingnewcredit',
            select: declarations.applyingnewcredit,
        },
        {
            question: 'Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?',
            id: 'cleanenergylien',
            select: declarations.cleanenergylien,
        },
        {
            question: 'Are there any outstanding judgments against you?',
            id: 'judgements',
            select: declarations.judgements,
        },
        {
            question: 'Are you currently delinquent or in default on a Federal Debt?',
            id: 'delinquentondebt',
            select: declarations.delinquentondebt,
        },
        {
            question: 'Are you a party to a lawsuit in which you potentially have any personal financial liability?',
            id: 'lawsuit',
            select: declarations.lawsuit,
        },
        {
            question: 'Have you conveyed title to any property in lieu of foreclosure in the past 7 years?',
            id: 'conveyedtitle',
            select: declarations.conveyedtitle,
        },
        {
            question: 'Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?',
            id: 'loanforeclosure',
            select: declarations.loanforeclosure,
        },
        {
            question: 'Have you had property foreclosed upon in the last 7 years?',
            id: 'propertyforeclosure',
            select: declarations.propertyforeclosure,
        },
        {
            question: 'Have you declared bankruptcy within the past 7 years?',
            id: 'bancrupt',
            select: declarations.bancrupt,
            bankruptcyType: bankrupcies.filter(n => declarations[n] === true),
        }
    ]
    const [questionList, setQuestionList] = useState(initialList);
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const onChangeRadio = (item, radio) => {
        setQuestionList(questionList.map(question => {
            if (question.id === item.id) {
                props.changeDeclaration(radio.value, item.id)
                return { ...question, select: radio.value };
            } else {
                return question;
            }
        }));
    }
    const onChangeBankruptcyType = (checkedValues) => {
        setQuestionList(questionList.map(question => {
            if (question.id === 'bancrupt') {
                bankrupcies.map(b => {
                    if (checkedValues.includes(b)) {
                        props.changeDeclaration(true, b)
                    } else {
                        props.changeDeclaration(false, b)
                    }
                })
                return { ...question, bankruptcyType: checkedValues };
            } else {
                return question;
            }
        }));
    }
    const onChangeMoney = (value) => {
        setQuestionList(questionList.map(question => {
            if (question.id === 'downpaymentborrowed') {
                props.changeDeclaration(String(value), "moneyborrowed")
                return { ...question, money: value };
            } else {
                return question;
            }
        }));
    };
    const btnDisable = () => {
        let disable = questionList.some(item => item.select === '' || item.select === null || item.select === undefined)
        let questionId3 = questionList.find(item => item.id === 'downpaymentborrowed')
        let questionId13 = questionList.find(item => item.id === 'bancrupt')
        if (questionId3.select === 'yes' && !questionId3.money) {
            disable = true
        } else if (questionId13.select === 'yes' && questionId13.bankruptcyType.length === 0) {
            disable = true
        }
        return disable
    }

    const notifyLO = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let tosend =
            JSON.stringify({
                notification: "interviewfinished",
            })
        fetch('/borrower/notifylo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return;
                }
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }

    const renderActionButtons = () => <Row gutter={10} style={{ marginTop: 50 }}>
        <Col>
            <Form.Item>
                <Button
                    type="default"
                    className='secondary-btn'
                    onClick={props.back}
                >
                    Back
                </Button>
            </Form.Item>
        </Col>
        {interviewfinished ? null:
            <Col>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        disabled={btnDisable()}
                        className={`common-btn ${btnDisable() ? 'disabled' : ''}`}
                        onClick={() => {
                            props.finish(questionList)
                            if (props.application.status === "interviewstarted") {
                                notifyLO()
                            }
                        }}
                    >
                        Preview Application
                    </Button>
                </Form.Item>
            </Col>
        }
    </Row>

    return (
        <div className="application-form text-left">
            <div className='form-title'>About this property and your money for this loan</div>
            <div className='question-list'>
                {
                    questionList.map((item, index) =>
                        <>
                            <div className='question-item' key={item.id}>
                                <div className='question-content'>{item.question}</div>
                                    <div className='question-options'>
                                    {
                                        DeclarationsYesNoOptions.map((radio, radioIndex) => <div
                                            className={`question-btn ${item.select === radio.value ? 'select' : ''}`}
                                            key={`${item.id}${radioIndex}`}
                                            onClick={interviewfinished ? props.onSubmittedDisableClick : () => onChangeRadio(item, radio)}
                                        >
                                            <div className='radio'>
                                                {
                                                    item.select === radio.value && (
                                                        <div className='circle-center'></div>
                                                    )
                                                }
                                            </div>
                                            <span className='radio-text'>{radio.label}</span>
                                        </div>
                                        )
                                    }
                                    </div>
                            </div>
                            {
                                item.id === 'downpaymentborrowed' && item.select === 'yes' && (
                                    <div className='sub-select money'>
                                        <span className='sub-select-text'> · What is the amount of this money?</span>
                                        <InputNumber
                                            disabled={interviewfinished}
                                            prefix="$"
                                            size="large"
                                            style={{ width: '212px' }}
                                            min={0}
                                            formatter={inputFormatter}
                                            parser={inputParser}
                                            onChange={onChangeMoney}
                                            defaultValue={props.application.borrower.declarations.moneyborrowed}
                                        />
                                    </div>
                                )
                            }
                            {
                                item.id === 'bancrupt' && item.select === 'yes' && (
                                    <div className='sub-select bankruptcy'>
                                        <span className='sub-select-text'> · Please identify the type(s) of bankruptcy.</span>
                                        <Checkbox.Group
                                            disabled={interviewfinished}
                                            options={bankruptcyTypeOptions}
                                            onChange={onChangeBankruptcyType}
                                            defaultValue={bankrupcies.filter(n => props.application.borrower.declarations[n] === true)}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </div>
            {renderActionButtons()}
        </div>
    )
}

Declarations = connect(mapStateToProps, mapDispatchToProps)(Declarations);
