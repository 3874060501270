import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Alert, Popover, Button, Modal, Tooltip, Input, Select} from "antd";
import * as act from "../../Store/actions"
import "./MyRates.css";
import * as com from "../../Common"
import {CheckCircleOutlined, CheckCircleFilled} from "@ant-design/icons";

const mapStateToProps = (state) => {
  return {
    property: state.application.property,
    application: state.application,
    progress: state.progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
});

export default function MyRates(props) {
    const [notifyingLO, setNotifyingLO] = useState(false)
    const [remindingLO, setRemindingLO] = useState(false)
    const [showReminderSentModal, setShowReminderSentModal] = useState(false)

    useEffect(() => {
        props.changeContinueButtonText("Declarations")
    }, [])

    const contactLO = () => {
        setRemindingLO(true)
        let token = sessionStorage.getItem("ZeitroA")
        let tosend =
            JSON.stringify({
                notification: "productselectionstarted",
            })
        fetch('/borrower/notifylo', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return;
                }
                setRemindingLO(false)
                setShowReminderSentModal(true)
            }
        ).catch((err) => {
            setRemindingLO(false)
            console.log('Fetch Error :', err);
        });
    }

    const showAlert = () => {
        let selectedLoan = props.application.selection.selectedloan
        if (selectedLoan.hash  !== "") {
            return <div className="ratesTips d-flex mt-2">
                <div><img src="/images/checkEmail.png" style={{height:18, width: 21, marginRight: 10}} /></div>
                <div className="ml-2">
                    <div className="fw-500">You've selected a rate!</div>
                    <div className="mt-1">Your loan officer will review your application and get in touch about what comes next.</div>
                </div>
            </div>
        }
        let loSelection = props.application.selection.loSelectedLoans
        if (loSelection.length === 0) {
            return <div className="ratesTips d-flex mt-2">
                <div><img src="/images/maganifier.png" style={{width:44, height:42}} /></div>
                <div className="ml-2">
                    <div className="fw-500 text-wrap">Your officer is working on your rates</div>
                    <div className="mt-1 text-wrap">Your Loan Officer is reviewing your documents and you will be notified by email when this page is updated with your rate.</div>
                    <Button type="primary" className="mt-3" loading={remindingLO} onClick={()=>contactLO()}>Remind my officer</Button>
                </div>
            </div>
        }

        return <div className="taskTips">
        <Alert
        message={<div><img src="/images/checkEmail.png" style={{height:18, width: 21, marginRight: 10}} />Your loan officer has chosen {loSelection.length} rates for you!</div>}
        className="mt-2"
        type="info"
        closable
        />
    </div>
    }

    const chooseRate = (loan) => {
        props.updateSelectField(loan, "selectedloan")

        if (("" === props.application.borrower.expenses.futuremortgageinsurance ||
            "0" === props.application.borrower.expenses.futuremortgageinsurance
        ) &&
            0 !== loan.pmi) {
            let insurance = (parseFloat(loan.monthly_payment) * loan.pmi).toFixed(2)

            props.updateExpenses({ target: { value: "monthly" } },
                "borrower", "futuremortgageinsuranceperiod")
            props.updateExpenses({ target: { value: insurance } },
                "borrower", "futuremortgageinsurance")
        } else {
            if (0 === loan.pmi) {
                props.updateExpenses({ target: { value: 0 } },
                    "borrower", "futuremortgageinsurance")
            }
        }
        notifyLO(loan)

    }
    
    const notifyLO = (rate) => {
        setNotifyingLO(true)
        let token = sessionStorage.getItem("ZeitroA")
        let tosend =
            JSON.stringify({
                base_rate: String(rate.base_rate),
                apr: String(rate.APR),
                monthly_payment: String(rate.monthly_payment),
                closing_cost: String(rate.closing_cost),
                points: String(rate.points),
                amortization: String(rate.amortization),
                term: String(rate.Term),
                mtype: String(rate.Mtype),
                loansize: String(rate.loansize),
                downpayment: String(rate.downpayment),
            })
        fetch('/borrower/notifyloaboutchosenrate', {
            method: 'POST',
            body: tosend,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    setNotifyingLO(false)
                    return;
                } else {
                    setNotifyingLO(false)
                    alert("Your loan officer has been notified! They will be in contact with you shortly to discuss your application and provide further guidance.")
                }
            }
        ).catch((err) => {
            setNotifyingLO(false)
            console.log(err)
        });
    }

    const getLTV = () => {
        if (props.property.purpose === "purchase") {
            return Math.floor(100 * (parseFloat(props.property.salesprice)-parseFloat(props.application.selection.downpayment)) / parseFloat(props.property.salesprice))
        } else {
            return Math.ceil(100 * (parseFloat(props.property.appraisal)-parseFloat(props.application.selection.paydown)-com.safeParseInt(props.application.loanbalance)) / parseFloat(props.property.appraisal))
        }
    }

    const displayLoSelectedRateCard = (rate, i) => {
        let isSelected = rate.hash === props.application.selection.selectedloan.hash
        let getDownpayment = () => {
            if (rate.purpose === "purchase") {
                return "$"+com.commaize(rate.downpayment)+"/"+getLTV()+"%"
            } else if (rate.purpose === "cashoutrefinance") {
                return "$"+com.commaize(rate.cashout)+"/"+getLTV()+"%"
            } else {
                return "$"+com.commaize(rate.paydown)+"/"+getLTV()+"%"
            }
        }
        let getDownpaymentTitle = () => {
            if (rate.purpose === "purchase") {
                return "Down payment/LTV"
            } else if (rate.purpose === "cashoutrefinance") {
                return "Cash out/LTV"
            } else {
                return "Paydown principal/LTV"
            }
        }
        return <div className="loSelectedRateCardWrap">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div style={{padding:4, backgroundColor:"#F5F5F7", borderRadius:4}}><img src="/images/rates.png" style={{width: 25, height: 25}} /></div>
                    <div className="fw-500 fs-16 ml-2">My Rate {i+1}</div>
                </div>
                {isSelected ? 
                <div className="d-flex">
                    <div className="chosenRateIcon"><CheckCircleFilled/></div>
                    <Button loading={notifyingLO} className="chosenRateButton" onClick={()=>notifyLO(rate)}>Confirm Selection</Button>
                </div>
                :
                <Button className="unchosenRateButton" onClick={()=>chooseRate(rate)}><CheckCircleOutlined /><div style={{color:"black", marginLeft:5}}>Select rate</div></Button>
                }
            </div>
            <div className="d-flex justify-content-between mt-3">
                <div>
                    <div className="title-5">Term, type</div>
                    <div className="fw-500">{rate.Term}, {rate.amortization}</div>
                </div>
                <div>
                    <div className="title-5">Loan size</div>
                    <div className="fw-500">${com.commaize(rate.loansize)}</div>
                </div>
                <div>
                    <div className="title-5">{getDownpaymentTitle()}</div>
                    <div className="fw-500">{getDownpayment()}</div>
                </div>
            </div>
            <div className="d-flex justify-content-between greyBackground mt-2">
                <div>
                    <div className="title-5">Rate/APR</div>
                    <div className="fw-500">{rate.base_rate}%/{rate.APR}%</div>
                </div>
                <div>
                    <div className="title-5">Mo. payment</div>
                    <div className="fw-500">${com.commaize(rate.monthly_payment)}</div>
                </div>
                <div>
                    <div className="title-5">Est. closing costs</div>
                    <div className="fw-500">${com.commaize(rate.closing_cost)}</div>
                </div>
                <div>
                    <div className="title-5">Points</div>
                    <div className="fw-500">{rate.points}%</div>
                </div>
            </div>
        </div>
    }

    return (
        <div className="text-left newInterviewPaneEmployment">
            <div className="title-4">My Rates</div>
            {showAlert()}
            <div className="mt-3">{props.application.selection.loSelectedLoans.map((rate, i) => displayLoSelectedRateCard(rate, i))}</div>
            <Modal title="Reminder sent" open={showReminderSentModal} footer={[
                <Button key="back" type="primary" onClick={()=>setShowReminderSentModal(false)}>
                    OK
                </Button>
                ]}>
                <p>Your loan officer has received a reminder to choose your rates. You will receive an email notification once your rates have been selected.</p>
            </Modal>
        </div>
    )
}

MyRates = connect(mapStateToProps, mapDispatchToProps)(MyRates);