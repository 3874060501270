import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import HorizontalSlider from '../Common/HorizontalSlider'
import ScrollBar from '../Common/components/ScrollBar';
import Dropdown from 'react-bootstrap/Dropdown'
import UpDown from '../UpDown'
import { isMobileOnly } from 'react-device-detect';
import { Redirect } from 'react-router-dom'
import { ChosenLoan, LoanProduct, service, fees } from '../State'
import * as com from "../Common.js"
import 'react-credit-cards/es/styles-compiled.css';
import Multiselect from '../Common/Multiselect';
import { connect } from 'react-redux'
import { MonhtlyChangeBP } from '../Common/Tooltip'
import { Skeleton, Alert } from 'antd';
import { tooltip, tooltipFlat, Tips } from '../Common/Tooltip'
import Details from './Details'
import Comparison from './Comparison'
import * as act from "../Store/actions"
import { getCurrentState } from '../Store'
import { Link } from 'react-router-dom'
import * as st from "../State"

const lender2icon = {
    NewFi: '/newfi.png',
    RocketPro: '/rocketpro.png',
    FinanceOfAmerica: '/fomo.png',
    Plaza: '/plaza.png',
    Caliber: '/caliber.png',
    HomePoint: '/homepoint.png',
    HomePointDelegated: '/homepoint.png',
    Amwest: '/amwest.png',
    PreferredRate: '/preferredrate.png',
    Flagstar: '/flagstar.png',
    AmeriHome: '/amerihome.png',
    Chase: '/chase.png',
    Fairway: '/fairway.png',
    PennyMac: '/pennymac.png',
    PRMG: '/prmg.png',
    ResiCentral: '/resicentral.png',
    Sprout: '/sprout.png',
    USBank: '/usbank.png',
    FifthThirdBank: '/53bank.png',
    Provident: '/provident.png',
    LoanDepot: '/loandepot.png',
    DivineMortgage: '/divinemortgage.png',
    WholesaleMortgageBankers: '/wmb.png',
    LoanStore: '/loanstore.png',
    NewWaveLending: '/newwavelending.png'
}

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    changeMainPropertyUnits: (input) => {
        dispatch(act.ChangeMainPropertyUnits(input))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    updatePrepaidDefault: (name, period, prepaidperiods, amount) => {
        dispatch(act.UpdatePrepaidDefault(name, period, prepaidperiods, amount))
    },
});

var hash = require('object-hash');



const sorts = {
    selectbyrate: "Base Rate",
    selectbyapr: "Best APR",
    //selectbymonthlypayment: "Smallest Monthly Payment",
    selectbyclosingcosts: "Smallest Upfront costs",

}

const typeoptions = [
    { name: 'ARM 5', id: "arm51", cliff: 5 },
    { name: 'ARM 7', id: "arm71", cliff: 7 },
    { name: 'ARM 10', id: "arm101", cliff: 10 },
    { name: 'Fixed', id: "fixed", cliff: 0 }]

const termoptions = [
    { name: '30 year', id: "year30", years: 30 },
    { name: '25 year', id: "year25", years: 25 },
    { name: '20 year', id: "year20", years: 20 },
    { name: '15 year', id: "year15", years: 15 },
    { name: '10 year', id: "year10", years: 10 }]

export const pointsExplained = <div><b>Mortgage points</b> are percentage of the loan that you pay to the lender for a <b>lower monthly rate</b>.
    <div className='my-2'>Conversely, <b>negative points</b> are lender credits that can be used by you to <b>lower your closing costs</b>, or be put into an impound account to pay your insurance and taxes. </div>Lender credits are paid to you in exchange for a <b>higher interest rate</b></div>
const customstyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)

        fontSize: '14px',
    },
    searchBox: { // To change search box element look
        fontSize: '.875rem',
        minHeight: 'calc(1.5em + .5rem + 2px)',
        padding: '.25rem .5rem',
        marginBottom: '0px !important',

    },
    inputField: { // To change input field position or margin
        margin: '0px'
    },
    chips: { // To change css chips(Selected options)
        background: 'rgba(0,48,116,1)',
        marginBottom: '2px !important',
        fontSize: '12px',
        padding: '2px 10px',
    },
    optionContainer: { // To change css for option container 
        fontSize: '12px',
    }
}

export const getFees = function (loanid) {
    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    fetch('/data/getFees', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
        },
    }).then(
        response => {
            if (response.status !== 200) {
                alert("Sorry, an internal error occurred. Please contact your loan officer for assistance.")
                this.setState({
                    loading: false
                })
                return;
            }
            response.json().then(_fees => {

                let f = new fees(_fees)
                f.cannotshopservices = _fees.cannotshopservices
                f.canshopservices = _fees.canshopservices
                f.closingcostsfinanced = _fees.closingcostsfinanced
                f.deposit = _fees.deposit
                f.downpayment = _fees.downpayment
                f.fundsforborrower = _fees.fundsforborrower
                f.initialescrowpaymentsatclosing = _fees.initialescrowpaymentsatclosing
                f.othercosts = _fees.othercosts
                f.prepaids = _fees.prepaids
                f.recordingandgovernmentfees = _fees.recordingandgovernmentfees
                f.transfertax = _fees.transfertax
                f.sellerscredits = _fees.sellerscredits
                f.adjustmentsothercredits = _fees.adjustmentsothercredits

                this.props.updateSelectField(f, "estimatedclosingcosts")
                getCurrentState()
                setTimeout(() => this.getDTI(loanid), 3000)
            })
        }

    ).catch((err) => {

        this.setState({ loading: false })
        alert("Network error")
    });
}
export const getDTI = function (loanid) {
    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    console.log(loanid)
    fetch('/data/getPreDTI', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
            "X-Loan": typeof loanid === "undefined" ? "" : loanid,
        },

    }).then(
        response => {
            if (response.status !== 200) {
                alert("Bad response, should not happen")
                this.setState({
                    loading: false
                })
                return;
            }
            response.json().then(pr => {
                this.setState({ loading: false })

                if (typeof pr["Error"] !== "undefined") {
                    console.log(pr.Error)
                    alert("Sorry, but we are unable to provide rates right now. Please contact contact@zeitro.com for help.")
                } else {
                    this.loaded = true
                    this.fees = pr.dtiData[0].fees
                    this.fico = pr.fico
                    this.zeitro_fee = pr.zeitro_fee
                    this.prepaid_interest_days = pr.prepaid_interest_days
                    //this.props.updateSelectField(this.zeitro_fee.default, "ourfee")
                    this.props.updateSelectField(this.prepaid_interest_days, "prepaiddays")

                    this.productReload(pr)
                }
            })
        }

    ).catch((err) => {

        this.setState({ loading: false })
        alert("Network error")
    });
}
export const getCitizenship = function () {
    let status = this.props.application.borrower.citizenship
    if (status === "citizen") {
        return ["US_CITIZENS", ""]
    }
    if (status === "permanent") {
        return ["PERMANENT_RESIDENT_ALIEN", ""]
    }
    return ["NON_PERMANENT_RESIDENT_ALIEN", this.props.application.borrower.visa.replace('-', '_')]

}
export const getOccupancy = function () {
    switch (this.props.application.property.occupancy) {
        case "principal":
            return "PRIMARY"
        case "secondhome":
            return "SECOND_HOME"
        case "investment":
            return "INVESTMENT"
        default:
            alert("wrong occupancy")
            return "PRIMARY"
    }
}
export const getPropertyType = function () {
    let remap = {
        condominiumdetached: "CONDOMINIUM_DETACHED",
        condominium: "CONDOMINIUM_ATTACHED",
        pudattached: "PUD_ATTACHED",
        puddetached: "PUD_DETACHED",
        singlefamilyattached: "SINGLE_FAMILY_ATTACHED",
        singlefamilydetached: "SINGLE_FAMILY_DETACHED",
        twotofourfamily: "TWO_TO_FOUR_FAMILY",
        manufacturedhome: "MANUFACTURED_HOME"
    }
    let out = remap[this.props.application.property.propertytype]
    if (this.props.application.property.isManufacturedHome === true) {
        out = "MANUFACTURED_HOME"
    }
    return out
}

export const productReload = function (predti) {
    if (null == this.loaded)
        return
    // this one needs work
    let firstTimeHomeBuyer = (this.props.application.firsttimehomebuyer)
    let [citizenship, visa] = this.getCitizenship()

    let request = {
        state: this.props.application.property.state,
        county: this.props.application.property.county,
        purpose: com.Purpose2Calculator(this.props.property.purpose),
        loanAmount: com.safeParseInt(this.state.loansize),
        appraisal: this.props.application.property.appraisal,        
        estimatedClosingCosts: this.fees,
        firstTimeHomeBuyer: firstTimeHomeBuyer,
        units: this.props.application.property.propertytype === "twotofourfamily" ? com.safeParseInt(this.props.application.property.units) : 1,
        occupancy: this.getOccupancy(), // propagated
        property: this.getPropertyType(),
        selfemployed: this.props.application.borrower.occupation.selfemployed,
        citizenship: citizenship,
        visaType: visa,
        preDTI: predti,
        property: this.props.application.property.propertytype,
    }

    let token = sessionStorage.getItem("ZeitroA")
    this.setState({ loading: true })
    fetch('/data/getproducts', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
            "Content-Type": "application/json",
            "X-Borrower": this.props.borrowerid,
        },
        body: JSON.stringify(request)
    }).then(
        response => {
            if (response.status !== 200) {

                this.setState({
                    loading: false, errorText: <Alert className='text-wrap mt-3' showIcon message="Sorry, an internal error occurred. Please contact your loan officer for assistance." type="warning" />,
                    products: [], topProducts: []
                })
                if (this.failedProduct != null)
                    this.failedProduct()
                return;
            }
            response.json().then(pr => {

                let products = pr.result
                let status = pr.status
                if ("Ok" !== status) {
                    /*
                    - "0" # InternalError
                    - "1" # LowFICOError
                    - "2" # HighDTIError
                    - "3" # HighLTVError
                    - "4" # LowIncomeError
                    - "5" # MatrixNotFoundError
                    - "6" # UnqualifiedPropertyError
                    */

                    let msg = ""
                    /*
                    switch (pr["status code"]) {
                        case "0":
                        default:
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>{pr.status}:{pr.message}</h4>
                            break;
                        case "1":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, it looks like your FICO score is too low to qualify for a loan.</h4>
                            break
                        case "2":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, your debt to income ratio is too high to qualify for a loan. <br />
                                You may want to consider paying off some debts.</h4>
                            break
                        case "3":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, your loan amount to property value ratio is too high to qualify for a loan. <br />
                                You may want to consider taking a smaller loan. Use the slider in the top left corner to investigate.</h4>
                            break
                        case "4":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, your income is too low to qualify for a loan of this size.</h4>
                            break
                        case "5":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, we could not find a loan product for your particular situation. You may want to consider taking a smaller loan. Use the slider in the top left corner to investigate.</h4>
                            break
                        case "6":
                            msg = <h4 className='sectionquestion mt-5 text-wrap'>Unfortunately, your property is not qualified for a loan.</h4>
                            break

                    }
                    */
                    let showDetails = () => {
                        if (pr == null) return ""
                        if ("undefined" !== typeof this.props.borrowerid) {
                            let out = []
                            let reasons = pr["ineligible_reasons"]
                            if (reasons == null) return ""
                            for (const [key, value] of Object.entries(reasons)) {
                                let entry = <div className="mt-2" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{com.capitalize(key)}</div>
                                out.push(<div> {entry} </div>)
                                if (value.LenderLevelReason !== "")
                                    out.push(<div>{value.LenderLevelReason}</div>)
                                else {
                                    let preasons = value.ProductLevelReasons
                                    /* eslint-disable-next-line no-unused-vars */
                                    for (const [key, value] of Object.entries(preasons)) {
                                        out.push(<div>{value.ProductName}: {value.IneligibleReason} </div>)
                                    }
                                }

                            }
                            return out
                        } else
                            return ""

                    }
                    msg = <h5 className='sectionquestion my-5 text-wrap text-center'><span style={{ color: '#993333' }}>Matching engine could not find a suitable loan. Reason: </span> <br />{pr["message"]}<br /> <div className="text-left" style={{ fontSize: '0.7em' }}>{showDetails()}</div> </h5>
                    this.setState({
                        loading: false, errorText: msg, errorCode: pr["status code"],
                        products: [], topProducts: []
                    })
                    if (this.failedProduct != null)
                        this.failedProduct()
                    return
                }
                if (this.askedForAssistance()) {
                    products = this.filterForAssistance(products)
                }
                this.processProducts(products)
                this.setState({ loading: false })
            })
        }

    ).catch((err) => {

        this.setState({ loading: false })
        this.setState({
            loading: false, errorText: <h1 className='mt-5'>{"Network Error"} </h1>,
            products: [], topProducts: []
        })
        if (this.failedProduct != null)
            this.failedProduct()
    });
}
export const getLTV = function () {
    let ltv = com.safeParseInt(this.state.loansize) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    let cltv = (com.safeParseInt(this.state.loansize) + com.safeParseInt(this.cltv)) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    let hcltv = (com.safeParseInt(this.state.loansize) + com.safeParseInt(this.hcltv)) * 100 / com.safeParseInt(this.props.application.property.appraisal)
    return [ltv, cltv, hcltv]
}

export class ProductPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeselected: [],
            termselected: [],
            yearstohold: 7,

            minclosingcost: 0,
            maxclosingcost: 100000,
            lowclosingcost: 0,
            highclosingcost: 0,

            loansize: 0,
            topProducts: [],
            loading: false,
            weightclosing: this.props.selection.weightclosing,
            weighttotalcost: this.props.selection.weighttotalcost,
            weightmonthly: this.props.selection.ƒTriangggweightmonthly,
            showSelf: false,
            ltv: 60.0,
            cltv: 0,
            fico: 720,
            income: 18000.0,
            occupancy: "PRIMARY",
            property: "SINGLE_FAMILY_DETACHED",
            errorText: "",
            downpayment: 30000,
            defdownpayment: 30000,
            viewkey: 0,
            paydown: 0,
            cashout: 30000,
            redirect: "",
            positionbyemail: {},
            statusloaded: false,
            showHelp: true,
            showMore: true,
            show: false,
            detailedCard: null,
            lenders: [],
            selectedlenders: [],
            selectedmaxreserves: -1,
            maxreserves: -1,
            showPriceChanged: false,
            selectedImage: null,
            needsAdjustment: false,
            comparisonSelections: {},
            showComparison: false,
            mincashout: 0,
            dtiSpreadsheetLoading: false,
        }
        this.hcltv = null
        this.cltv = null
        this.timer = 0
        this.lenders = []
        this.changeshown = false
        this.oldmaxupfrontcost = this.props.selection.maxupfrontcost
        if (isNaN(this.oldmaxupfrontcost))
            this.oldmaxupfrontcost = null
        this.howmany2display = 5
        if (this.props.borrowerid)
            this.howmany2display *= 3

        switch (this.props.property.purpose) {
            case "purchase":

                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = com.safeParseInt(this.props.property.salesprice) - this.state.loansize
                    this.props.updateSelectField(this.state.downpayment, "downpayment")
                } else {
                    // if first time arriving this page
                    console.log(this.props.selection.downpayment)
                    if (this.props.selection.downpayment === 0) {
                        this.state.downpayment = com.safeParseInt(this.props.property.salesprice) * 0.2
                        this.state.defdownpayment = com.safeParseInt(this.props.property.salesprice) * 0.2
                        this.state.loansize = com.safeParseInt(this.props.property.salesprice) * 0.8
                        this.props.updateSelectField(com.safeParseInt(this.props.property.salesprice) * 0.2, "downpayment")
                        this.props.updateSelectField(com.safeParseInt(this.props.property.salesprice) * 0.8, "loansize")

                    } else {
                        this.state.downpayment = com.safeParseInt(this.props.selection.downpayment)
                        this.state.defdownpayment = com.safeParseInt(this.props.selection.downpayment)
                        this.state.loansize = com.safeParseInt(this.props.property.salesprice) - com.safeParseInt(this.state.downpayment)
                    }
                }
                break;
            case "refinance":

                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = com.safeParseInt(this.props.application.loanbalance) - this.state.loansize
                    this.props.updateSelectField(this.state.downpayment, "paydown")

                } else {
                    this.state.downpayment = com.safeParseInt(this.props.selection.paydown)
                    this.state.defdownpayment = com.safeParseInt(this.props.selection.paydown)
                    this.state.loansize = com.safeParseInt(this.props.application.loanbalance) - com.safeParseInt(this.props.selection.paydown)
                }
                break;
            case "cashoutrefinance":

                this.state.lmincashout = this.props.selection.selectedloan.min
                if (this.props.selection.selectedloan.hash !== "") {
                    this.state.loansize = this.props.selection.selectedloan.loansize
                    this.state.downpayment = this.state.defdownpayment = this.state.loansize - com.safeParseInt(this.props.application.loanbalance)
                    if (this.state.downpayment <= 0) {
                        this.state.downpayment = this.state.defdownpayment = 30000
                    }
                    this.props.updateSelectField(this.state.downpayment, "cashout")
                } else {
                    this.state.downpayment = com.safeParseInt(this.props.selection.cashout)
                    this.state.defdownpayment = com.safeParseInt(this.props.selection.cashout)
                    this.state.loansize = com.safeParseInt(this.props.application.loanbalance) + com.safeParseInt(this.props.selection.cashout)
                }
                break;
            default:
                alert("should not see this!")
                break;
        }

        this.props.updateSelectField(this.state.loansize.toString(), "loansize")
        this.downloadDTIspreadsheetRef = React.createRef();

        termoptions.forEach(term => {
            if (this.props.selection.loanterms.includes(term.id))
                this.state.termselected.push(term)
        })
        if (this.state.termselected.length === 0)
            this.state.termselected = [termoptions[0]]


        typeoptions.forEach(typ => {
            if (this.props.selection.loanamortizations.includes(typ.id))
                this.state.typeselected.push(typ)

        })
        if (this.state.typeselected.length === 0)
            this.state.typeselected = typeoptions
        this.cull = (a) => a
        this.selectsort(this.props.selection.sortby)

        this.width = 250

        this.height = Math.floor((this.width / 2) * Math.tan(3.14 / 3.0))
        if (this.props.property.salesprice === "") {
            this.state.redirect = <Redirect to="/app/interview/main?section=start" />
        }
        this.rawproducts = []
        this.checkStatus()

        // backup
        this.loanbalance = this.state.loansize.toString()
        this.down = this.state.defdownpayment

        this.amortizations = [...this.props.selection.loanamortizations]
        this.terms = [...this.props.selection.loanterms]

        /* unit test!
                let l = ChosenLoan.fromJson(this.props.selection.selectedloan)
                l.hash = "ho ho ho" 
                this.props.updateSelectField(l, "selectedloan") 
        */
    }
    reset = () => {

        let termselected = []
        let typeselected = []
        this.props.updateSelectField(this.terms, "loanterms")
        this.props.updateSelectField(this.amortizations, "loanamortizations")

        termoptions.forEach(term => {
            if (this.terms.includes(term.id))
                termselected.push(term)
        })
        if (termselected.length === 0)
            termselected = [termoptions[0]]

        typeoptions.forEach(typ => {
            if (this.amortizations.includes(typ.id))
                typeselected.push(typ)

        })
        if (typeselected.length === 0)
            typeselected = typeoptions

        this.setState({ termselected, typeselected }, () => {
            this.processProducts(this.rawproducts);
            this.forceUpdate()
        }
        )
    }
    getFees = getFees.bind(this)
    getDTI = getDTI.bind(this)
    getCitizenship = getCitizenship.bind(this)
    getOccupancy = getOccupancy.bind(this)
    getPropertyType = getPropertyType.bind(this)
    productReload = productReload.bind(this)
    getLTV = getLTV.bind(this)
    compareRate = (a, b) => {
        if (a.base_rate !== b.base_rate)
            return a.base_rate - b.base_rate

        return a.closing_cost - b.closing_cost // take into account remaining credits
    }

    cullRate = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let base_rate = products[0].base_rate
        let counter = 0
        for (let i = 1; i < products.length; i++) {
            if (products[i].base_rate !== base_rate) {
                newproducts.push(products[i])
                base_rate = products[i].base_rate
                counter = 1
            } else {


                counter += 1
                if (counter < 5) {
                    newproducts.push(products[i])
                    base_rate = products[i].base_rate
                }
            }
        }
        return newproducts
    }
    compareAPR = (a, b) => {
        if (a.APR !== b.APR)
            return a.APR - b.APR
        if (a.base_rate !== b.base_rate)
            return a.base_rate - b.base_rate
        return a.closing_cost - b.closing_cost
    }
    cullAPR = products => {
        if (products.length === 0)
            return products
        let newproducts = []
        newproducts.push(products[0])
        let APR = products[0].APR
        for (let i = 1; i < products.length; i++) {
            if (products[i].APR !== APR) {
                newproducts.push(products[i])
                APR = products[i].APR
            } else {
            }
        }
        return newproducts
    }
    compareMoneyUpfront = (a, b) => {
        let acc = this.getClosingCosts(a)
        let bcc = this.getClosingCosts(b)
        if (acc !== bcc)
            return acc - bcc
        return a.base_rate - b.base_rate
    }

    cullMoneyUpfront = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let closing_cost = this.getClosingCosts(products[0])
        for (let i = 1; i < products.length; i++) {
            if (this.getClosingCosts(products[i]) !== closing_cost) {
                newproducts.push(products[i])
                closing_cost = this.getClosingCosts(products[i])
            } else {
            }
        }
        return newproducts
    }

    compareMonthly = (a, b) => {
        if (a.monthly_payment !== b.monthly_payment)
            return a.monthly_payment - b.monthly_payment
        return a.closing_cost - b.closing_cost
    }

    cullMonthly = (products) => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let monthly_payment = products[0].monthly_payment
        for (let i = 1; i < products.length; i++) {
            if (products[i].monthly_payment !== monthly_payment) {
                newproducts.push(products[i])
                monthly_payment = products[i].monthly_payment
            } else {
            }
        }
        return newproducts
    }
    compareTotalOwnership = (a, b) => {
        let loansize = com.safeParseInt(this.state.loansize)
        let ca = com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, a.Term) //+ a.closing_cost
        let cb = com.getClosedInterestPaid(0.01 * b.base_rate, loansize, b.Term, b.Term) //+  b.closing_cost

        if (ca !== cb)
            return ca - cb

        return a.base_rate - b.base_rate
    }
    cullTotalOwnership = (products) => {
        if (products.length === 0)
            return products

        let getCost = a => {
            let loansize = com.safeParseInt(this.state.loansize)
            return com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, a.Term) //+ a.closing_cost            
        }
        let newproducts = []
        newproducts.push(products[0])
        let cost = getCost(products[0])

        for (let i = 1; i < products.length; i++) {
            if (cost !== getCost(products[i])) {
                newproducts.push(products[i])
                cost = getCost(products[i])
            } else {
            }
        }
        return newproducts
    }

    compareHoldingOwnership = (a, b) => {
        let loansize = com.safeParseInt(this.state.loansize)
        let ca = com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, this.state.yearstohold) // + a.closing_cost
        let cb = com.getClosedInterestPaid(0.01 * b.base_rate, loansize, b.Term, this.state.yearstohold) // +  b.closing_cost

        if (ca !== cb)
            return ca - cb

        return a.base_rate - b.base_rate
    }

    cullHoldingOwnership = (products) => {
        if (products === [])
            return products

        let getCost = a => {
            let loansize = com.safeParseInt(this.state.loansize)
            return com.getClosedInterestPaid(0.01 * a.base_rate, loansize, a.Term, this.state.yearstohold) + a.closing_cost
        }
        let newproducts = []
        newproducts.push(products[0])
        let cost = getCost(products[0])

        for (let i = 1; i < products.length; i++) {
            if (cost !== getCost(products[i])) {
                newproducts.push(products[i])
                cost = getCost(products[i])
            } else {
            }
        }
        return newproducts
    }

    askedForAssistance = () => {
        return true === this.props.application.askedforassistance && this.props.application.property.purpose === "purchase"
    }
    componentDidMount() {

        this.getFees()
    }

    filterForAssistance = products => {
        let out = []
        products.forEach(x => {
            if (["RocketPro", "Plaza"].includes(x.Lender)) {
                out.push(x)
            }
        })
        return out
    }

    updateLender = lender => {

        let found = false
        let Lender = com.capitalize(lender)
        this.lenders.forEach(x => {
            if (x.name === Lender) {
                found = true
            }
        })
        if (found)
            return
        this.lenders.push({
            name: Lender, id: lender
        })
        this.setState({ selectedlenders: this.lenders, lenders: this.lenders })
    }
    isUnchecked = (lender) => {
        let found = false
        this.state.selectedlenders.forEach(x => {
            if (x.id === lender) {
                found = true
            }
        })
        return !found
    }
    processProducts = (products) => {
        if ("undefined" === typeof products || products.length === 0)
            return
        this.rawproducts = products
        let rearranged = []

        let minclosingcost = 10000000
        let maxclosingcost = 0
        let mintotalinterest = 100000000
        let maxtotalinterest = 0
        let maxreserves = 0
        let _types = []
        this.state.typeselected.forEach(a => {
            _types.push(a.cliff)
        })
        let _terms = []
        this.state.termselected.forEach(a => {
            _terms.push(com.safeParseInt(a.years))
        })
        let _totalterms = []
        products.forEach(product => {
            let lender = product.Lender
            this.updateLender(lender)
            if (this.isUnchecked(lender))
                return
            product.ProductRatesCosts.forEach(pr => {
                let commonproduct = pr.product
                let ausengine = pr.AUSEngine
                pr.rateCosts.forEach(rate => {
                    if (!_totalterms.includes(com.safeParseInt(commonproduct.Term))) {

                        _totalterms.push(com.safeParseInt(commonproduct.Term))
                    }

                    if (_terms.includes(com.safeParseInt(commonproduct.Term))) {
                        let typ = commonproduct.Arm
                        if (typ === null || typeof typ === "undefined")
                            typ = 0
                        else
                            typ = typ.fixedperiod
                        // filter out non-30 day locks
                        if (rate.lockDay !== 30)
                            return

                        if (_types.includes(typ)) {

                            let cc = rate.closing_cost // + rate.prepaid_interest
                            if (cc > maxclosingcost)
                                maxclosingcost = cc
                            if (cc < minclosingcost)
                                minclosingcost = cc
                            let interest = com.getClosedInterestPaid(0.01 * rate.base_rate, com.safeParseInt(this.state.loansize), commonproduct.Term,
                                this.state.yearstohold)

                            if (interest > maxtotalinterest)
                                maxtotalinterest = interest
                            if (interest < mintotalinterest)
                                mintotalinterest = interest
                            if (rate.min_months_reserves > maxreserves)
                                maxreserves = rate.min_months_reserves
                        } else {
                        }
                    } else {
                    }
                    let pdkt = {
                        Lender: lender,
                        ...commonproduct,
                        Aus: ausengine,
                        ...rate
                    }

                    rearranged.push(pdkt)
                })
            })
        });


        this.setState({ maxreserves })

        if (this.state.selectedmaxreserves < 0 || this.state.selectedmaxreserves > maxreserves) {
            this.setState({ selectedmaxreserves: maxreserves })
        // eslint-disable-next-line react/no-direct-mutation-state           
            this.state.selectedmaxreserves = maxreserves
        }


        if (minclosingcost < 0)
            minclosingcost = 0
        let lowclosingcost = minclosingcost
        let highclosingcost = maxclosingcost
        // if (highclosingcost > 2000 && highclosingcost === maxclosingcost && lowclosingcost < 1000 && maxclosingcost < 20000)
        //     highclosingcost = 2000
        // else {
        //     if (highclosingcost > 4000 && highclosingcost === maxclosingcost && lowclosingcost < 2000)
        //         highclosingcost = 4000
        //     else {
        //         if (highclosingcost > 8000 && highclosingcost === maxclosingcost && lowclosingcost < 4000)
        //             highclosingcost = 8000
        //         else {
        //             if (highclosingcost > 10000 && highclosingcost === maxclosingcost && lowclosingcost < 5000)
        //                 highclosingcost = 10000
        //             else {
        //                 if (highclosingcost > 16000 && highclosingcost === maxclosingcost && lowclosingcost < 8000)
        //                     highclosingcost = 16000
        //                 else {
        //                     if (highclosingcost > 22000 && highclosingcost === maxclosingcost && lowclosingcost < 11000)
        //                         highclosingcost = 22000

        //                 }
        //             }
        //         }

        //     }
        // }

        console.log(highclosingcost, this.state.highclosingcost)
        let oldmaxupfrontcost = this.oldmaxupfrontcost

        if (null !== oldmaxupfrontcost && "" !== oldmaxupfrontcost) {
            oldmaxupfrontcost = parseInt(oldmaxupfrontcost)
            if (oldmaxupfrontcost <= minclosingcost)
                oldmaxupfrontcost = minclosingcost
            if (oldmaxupfrontcost >= maxclosingcost)
                oldmaxupfrontcost = maxclosingcost
            highclosingcost = oldmaxupfrontcost
        }
        console.log(highclosingcost, this.state.highclosingcost)
        this.oldmaxupfrontcost = highclosingcost
        if (oldmaxupfrontcost !== highclosingcost) {
            let st = highclosingcost.toString()

            this.props.updateSelectField(st, "maxupfrontcost")
        }

        let newterms = []
        termoptions.forEach(a => {
            if (_totalterms.includes(a.years))
                newterms.push(a)
        })

        this.setState({
            lowclosingcost: lowclosingcost, highclosingcost: highclosingcost,
            minclosingcost: minclosingcost, maxclosingcost: maxclosingcost,
            mintotalinterest: mintotalinterest, maxtotalinterest: maxtotalinterest
        })
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lowclosingcost = lowclosingcost
        // eslint-disable-next-line react/no-direct-mutation-state        
        console.log(highclosingcost, this.state.highclosingcost)
        this.state.highclosingcost = highclosingcost
        console.log(highclosingcost, this.state.highclosingcost)
        let terms = []
        rearranged.forEach(pr => {
            let term = pr.Arm ? pr.Arm.fixedperiod : "fixed"
            if (!terms.includes(term))
                terms.push(term)
        })

        // Lender, Name
        let plainproducts = []
        this.referi = {}
        rearranged.forEach(pr => {
            if (pr.lockDay !== 30)
                return
            
            const { Lender, Name, Aus, lenderinfo, features, DTI, high_cost_loan, high_priced_loan, ...partialObject } = pr;

            let hsh = hash(partialObject)
            if (typeof this.referi[hsh] === "undefined") {
                plainproducts.push({ hash: hsh, ...partialObject })
                this.referi[hsh] = [{ Lender: Lender, Name: Name, Aus: Aus, LenderInfo: lenderinfo, Rate: partialObject }]
            } else {
                
                this.referi[hsh].push({ Lender: Lender, Name: Name, Aus: Aus, LenderInfo: lenderinfo, Rate: partialObject })
            }
        })

        this.products = plainproducts
        this.filter()

        this.getSelectedIcon(this.props.selection.selectedloan)
        if (!this.changeshown) {
            if (this.props.application.selection.selectedloan == null ||
                this.props.application.selection.selectedloan.hash === "" ) {

            } else {
                if (this.referi[this.props.application.selection.selectedloan.hash] == null) {
                    let suggestedLoan = this.findClosestLoan()
                    this.setState({showPriceChanged: true, suggestedLoan})
                }
            }
            this.changeshown = true
        }

    }
    getSelectedIcon = (selectedloan) => {
        // prep the logo for the selected loan
        if (selectedloan != null && selectedloan.hash !== "") {
            let lenders = this.referi[selectedloan.hash]

            if (lenders != null) {
                let lender = lenders[0]

                let src = this.props.borrowerid ? lender2icon[lender.Lender] : "/loan.png"
                let image = <img alt="lender" height={24} style={{ height: '24', width: 'auto' }} className="mr-2" src={src}></img>
                this.setState({ selectedImage: image, needsAdjustment: false })
            } else {
                if (selectedloan.loansize !== this.state.loansize) {
                    // keep old
                } else {
                    let src = "/pricingchange.png"
                    let image = <Link onClick={this.onPriceChange}><img alt="ho ho ho" height={24} style={{ height: '24', width: 'auto' }} className="mr-2 oscillate" src={src}></img> </Link>
                    this.setState({ selectedImage: image, needsAdjustment: true })
                }
            }

        } else {
            let src = "/pricingchange.png"
            let image = <Link onClick={this.onPriceChange}><img alt="ho ho ho" height={24} style={{ height: '24', width: 'auto' }} className="mr-2 oscillate" src={src}></img> </Link>
            this.setState({ selectedImage: image, needsAdjustment: true })
        }
    }
    findClosestLoan = () => {
        let loan = this.props.selection.selectedloan
        let nextbest = null
        if(this.products.length === 0) {
            return
        }
        this.products.forEach(p => {
            if (p.base_rate !== loan.base_rate)
                return
            if (p.Term !== loan.Term)
                return
            if (loan.ARM != null) {
                if (p.ARM == null)
                    return
                if (loan.ARM.fixedperiod !== p.ARM.fixedperiod)
                    return
            }
            if (nextbest == null) {
                nextbest = p
                return
            }
            if (nextbest.closing_cost < 0 && p.closing_cost > 0)
                return
            if (nextbest.closing_cost < 0 && p.closing_cost < 0) {
                if (nextbest.closing_cost > p.closing_cost) {
                    nextbest = p
                    return
                }
            } else {
                if (nextbest.closing_cost > 0 && p.closing_cost > 0) {
                    if (nextbest.closing_cost > p.closing_cost) {
                        nextbest = p
                        return
                    }
                } else {
                    if (nextbest.closing_cost > 0 && p.closing_cost <= 0) {
                        nextbest = p
                        return
                    }
                }
            }
        })

        let suggested = new ChosenLoan()
        suggested.hash = nextbest.hash

        suggested.APR = nextbest.APR
        if (nextbest.Arm !== null)
            suggested.Arm = nextbest.Arm
        suggested.amortization = nextbest.MortgageAmortization
        suggested.Mtype = nextbest.Mtype
        suggested.Term = nextbest.Term
        suggested.base_rate = nextbest.base_rate
        suggested.closing_cost = nextbest.closing_cost
        suggested.features = nextbest.features
        suggested.high_cost_mortgage = nextbest.high_cost_mortgage
        suggested.lender_credit = nextbest.lender_credit
        suggested.lender_fee = nextbest.lender_fee
        suggested.lockDay = nextbest.lockDay
        suggested.min_months_reserves = nextbest.min_months_reserves
        suggested.monthly_payment = nextbest.monthly_payment
        suggested.prepaid_interest = nextbest.prepaid_interest
        suggested.points = nextbest.points
        suggested.pmi = nextbest.PMICost
        suggested.FHAMIP = nextbest.FHAMIP
        suggested.DTI = nextbest.DTI
        suggested.matchingproducts = []
        suggested.loansize = loan.loansize
        suggested.purpose = this.props.property.purpose
        suggested.ourfee = nextbest.zeitro_fee
        suggested.prepaiddays = this.prepaid_interest_days
        suggested.downpayment = loan.downpayment
        suggested.paydown = loan.paydown
        suggested.checkout = loan.checkout
        suggested.aus = loan.aus        
        suggested.matchingproducts = []
        if(nextbest.maxcachout != null)
            suggested.maxcashout = nextbest.maxcashout

        this.referi[nextbest.hash].forEach(
            pr => {
                let l = new LoanProduct()

                l.Lender = pr.Lender
                l.Name = pr.Name
                l.lenderinfo = pr.LenderInfo
                l.aus = pr.Aus
                suggested.matchingproducts.push(l)
            }
        )
        // suggested.lender = lender2lender[suggested.matchingproducts[0].Lender]
        // preserve edits if they were already made
        if (loan != null && loan.estimatedclosingcosts != null) {
            suggested.estimatedclosingcosts = loan.estimatedclosingcosts
        } else {
            suggested.estimatedclosingcosts = this.props.selection.estimatedclosingcosts
        }
        return suggested
    }
    onPriceChange = () => {
        let suggestedLoan = this.findClosestLoan()
        this.setState({ showPriceChanged: true, suggestedLoan })

    }
    filter = () => {
        if (!this.products)
            return
        let types = []
        this.state.typeselected.forEach(a => {
            types.push(a.cliff)
        })
        let terms = []
        this.state.termselected.forEach(a => {
            terms.push(a.years)
        })

        let filteredbyterm = this.products.filter(p => terms.includes(p.Term));

        let filteredbytype = filteredbyterm.filter(p => {
            let typ = p.Arm
            if (typ === null || typeof typ === "undefined")
                typ = 0
            else
                typ = typ.fixedperiod
            return types.includes(typ)
        })
        // recalculate min and max here!

        let minclosingcost = 10000000
        let maxclosingcost = 0

        filteredbytype.forEach(a => {


            if (a.closing_cost < minclosingcost) {
                minclosingcost = parseFloat(a.closing_cost)
            }
            if (a.closing_cost > maxclosingcost) {
                maxclosingcost = parseFloat(a.closing_cost)
            }
        })
        if (minclosingcost < 0)
            minclosingcost = 0

        this.setState({
            minclosingcost: minclosingcost, maxclosingcost: maxclosingcost,
            // lowclosingcost: minclosingcost, highclosingcost: maxclosingcost,
        })


        let filteredbyclosing = filteredbytype.filter(a => {
            return (this.getClosingCosts(a) >= this.state.lowclosingcost) &&
                (this.getClosingCosts(a) <= this.state.highclosingcost)
        })

        if (this.state.maxreserves > 0) {
            filteredbyclosing = filteredbyclosing.filter(a => {
                return a.min_months_reserves <= this.state.selectedmaxreserves
            })
        }


        filteredbyclosing.sort(this.compare)
        // now need to filter appropriately
        if (this.props.application.hascoborrower === "alone" && false === this.props.application.aloneMarriedWantsFHA) {
            // do not show FHA
            filteredbyclosing = filteredbyclosing.filter(x => {
                return x.FHAMIP == null
            })
        }

        let culled = this.cull(filteredbyclosing)
        //let top = filteredbyclosing.slice(0, 10)

        culled.splice(this.howmany2display)
        let top = culled
        
        if(this.props.property.purpose ===  "cashoutrefinance" && this.props.selection.mincashout > 0) {
            if(parseInt(this.props.selection.cashout) < this.props.selection.mincashout) {
                
                this.setState({
                    errorText: <div className="mb-4 text-center"><h4 className='mt-5'>Minimum cashout size is ${com.commaizeFloat(this.props.selection.mincashout)}.
                    </h4><br />
                        <div style={{ fontSize: '1.5em' }} className="mb-3">Please increase the cashout size</div>
                        </div>, topProducts: []
                })       
                return         
            }
        }

        this.setState({ topProducts: top })

        if (this.products && this.products.length > 0 && this.state.topProducts.length === 0) {
            this.setState({
                errorText: <div className="mb-4 text-center"><h4 className='mt-5'>There are no qualifying loan products that fit your filters.
                </h4><br />
                    <div style={{ fontSize: '1.5em' }} className="mb-3">Move the range sliders, change the term/amortization selections, or</div>
                    <Button variant='link' onClick={this.resetDefault} > <div style={{ fontSize: '1em' }}>Reset filters to default </div></Button> </div>
            })
        }

    }

    resetDefault = () => {
        let termselected = [termoptions[0]]
        let typeselected = []
        typeoptions.forEach(typ => {
            typeselected.push(typ)
        })
        this.setState({ typeselected, termselected })
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.processProducts(this.rawproducts)
            this.forceUpdate()
        }, 1000)
    }
    renderProducts = () => {
        return this.state.topProducts.map(this.displayCard)
    }
    onTriangleUpdate = (l1, l2) => {
        l1 = parseFloat(l1)
        l2 = parseFloat(l2)
        this.setState({ weightclosing: l2, weighttotalcost: l1, weightmonthly: Math.max(0.0, 1 - l2 - l1) })

        this.props.updateSelectField(l2, "weightclosing")
        this.props.updateSelectField(l1, "weighttotalcost")
        this.props.updateSelectField(Math.max(0.0, 1 - l2 - l1), "weightmonthly")

        this.filter()
    }
    updateTypes = e => {
        let out = []
        e.forEach(t => {
            out.push(t.id)
        })
        this.props.updateSelectField(out, "loanamortizations")
    }
    updateTerms = e => {
        let out = []
        e.forEach(t => {
            out.push(t.id)
        })
        this.props.updateSelectField(out, "loanterms")
    }
    onSelect = (e) => {
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.typeselected = e
        this.setState({ typeselected: e })
        this.updateTypes(e)
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }
    onRemove = (e) => {
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.typeselected = e
        this.setState({ typeselected: e })
        this.updateTypes(e)
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }
    onSelectTerms = (e) => {
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.termselected = e
        this.setState({ termselected: e })
        this.updateTerms(e)
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }
    onRemoveTerms = (e) => {
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.termselected = e
        this.setState({ termselected: e })
        this.updateTerms(e)
        this.processProducts(this.rawproducts)
        this.forceUpdate()
    }

    onSelectLenders = (e) => {

        this.setState({ selectedlenders: e }, () => {
            this.updateTerms(e)
            this.processProducts(this.rawproducts)
            this.forceUpdate()
        })
    }
    onRemoveLenders = (e) => {
        this.setState({ selectedlenders: e }, () => {
            this.updateTerms(e)
            this.processProducts(this.rawproducts)
            this.forceUpdate()
        })
    }

    selectsort = e => {
        this.props.updateSelectField(e, "sortby")
        switch (e) {
            case "selectbyrate":
                this.compare = this.compareRate
                this.cull = this.cullRate
                break
            case "selectbyapr":
                this.compare = this.compareAPR
                this.cull = this.cullAPR
                break
            case "selectbyinterestterm":
                this.compare = this.compareHoldingOwnership
                this.cull = this.cullHoldingOwnership
                break
            case "selectbyinteresttotal":
                this.compare = this.compareTotalOwnership
                this.cull = this.cullTotalOwnership
                break
            case "selectbymonthlypayment":
                this.compare = this.compareMonthly
                this.cull = this.cullMonthly
                break
            case "selectbyclosingcosts":
                this.compare = this.compareMoneyUpfront
                this.cull = this.cullMoneyUpfront
                break

            default:
                break
        }
        this.filter()
        return
    }
    updateYears = e => {
        this.setState({ yearstohold: e })
    }
    changeYears = e => {
        this.setState({ yearstohold: e })
        this.props.updateApplicationAttribute(com.safeParseInt(e), "keepinghousefor")
        return false
    }
    //--- begin callbacks that cause a reload
    updateDownpayment = e => {
        let dp = e
        if (isNaN(dp))
            return
        this.setState({ downpayment: com.safeParseInt(dp) })
        return false
    }
    changeDownpayment = e => {
        let dp = e
        if (isNaN(dp))
            return

        let ls = this.props.property.salesprice - parseFloat(dp)
        this.setState({ downpayment: dp, loansize: ls })
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.loansize = ls
        this.props.updateSelectField(com.safeParseInt(dp), "downpayment")
        this.props.updateSelectField(ls.toString(), "loansize")
        // no need to do setState here
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (ls) / this.props.property.salesprice

        this.getDTI()
        return false
    }
    updatePaydown = e => {
        let dp = e

        this.setState({ downpayment: dp })
        return false
    }
    changePaydown = e => {
        let dp = e
        if (isNaN(dp))
            dp = 0

        let ls = com.safeParseInt(this.props.application.loanbalance) - parseFloat(dp)
        this.setState({ downpayment: com.safeParseInt(dp), loansize: ls })
        this.props.updateSelectField(com.safeParseInt(dp), "paydown")
        this.props.updateSelectField(ls.toString(), "loansize")
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.loansize = ls
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (com.safeParseInt(ls) / this.props.property.salesprice)

        this.getDTI()
        return false
    }

    onDownpaymentInput = e => {
        function decommaizeNumeric(v) {
            let n = v.replace(/[^0-9-]/g, '');
            if (n === "")
                return n
            return com.safeParseInt(n)
        }
        // TODO – validate max?

        let val = decommaizeNumeric(e.target.value.toString())

        let ls
        if (val < 0)
            val = 0
        if (val === "")
            val = 0
        switch (this.props.property.purpose) {
            case "purchase":
                ls = this.props.property.salesprice - val
                if (val > this.props.property.salesprice)
                    val = this.props.property.salesprice
                this.props.updateSelectField(parseInt(val), "downpayment")
                break;
            case "refinance":
                ls = com.safeParseInt(this.props.application.loanbalance) - val
                if (val > this.props.application.loanbalance)
                    val = this.props.application.loanbalance
                this.props.updateSelectField(parseInt(val), "paydown")
                break;
            case "cashoutrefinance":
                ls = com.safeParseInt(this.props.application.loanbalance) + val
                if (val > com.safeParseInt(this.props.property.appraisal) - com.safeParseInt(this.props.application.loanbalance))
                    val = com.safeParseInt(this.props.property.appraisal) - com.safeParseInt(this.props.application.loanbalance)
                if (val < 0)
                    val = 0
                this.props.updateSelectField(parseInt(val), "cashout")
                break;
            default:
                alert("should not see this!")
                break;
        }
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.downpayment = val
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.loansize = ls
        this.setState({ defdownpayment: val, loansize: ls })
        this.props.updateSelectField(ls.toString(), "loansize")

        this.getDTI()
    }
    updateCashout = e => {
        let dp = e
        this.setState({ downpayment: com.safeParseInt(dp) })
        return false
    }
    changeCashout = e => {
        let dp = e

        let ls = com.safeParseInt(this.props.application.loanbalance) + parseFloat(dp)
        this.setState({ downpayment: dp, loansize: ls })
        this.props.updateSelectField(ls.toString(), "loansize")
                // eslint-disable-next-line react/no-direct-mutation-state
        this.state.ltv = 100 * (ls) / this.props.property.salesprice

        this.props.updateSelectField(com.safeParseInt(dp), "cashout")
        this.getDTI()
        return false
    }
    //--- end callbacks that cause a reload

    updateCost = e => {
        let high = e

        if (isNaN(high))
            high = this.state.maxclosingcost
        if (high !== this.state.highclosingcost) {
            this.setState({ highclosingcost: high })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.highclosingcost = high
        }
        this.oldmaxupfrontcost = high
        this.filter()
    }
    changeCost = e => {
        let high = e

        if (isNaN(high))
            high = this.state.maxclosingcost
        this.oldmaxupfrontcost = high
        if (high !== this.state.highclosingcost) {
            this.setState({ highclosingcost: high })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.highclosingcost = high
        }
        this.props.updateSelectField(high.toString(), "maxupfrontcost")
        console.log(this.oldmaxupfrontcost)
        this.filter()
    }

    updateReserves = e => {
        let [reserves] = e
        if (this.state.selectedmaxreserves < 0)
            return
        if (isNaN(reserves))
            reserves = this.state.maxreserves
        if (reserves !== this.state.selectedmaxreserves) {
            this.setState({ selectedmaxreserves: reserves })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.selectedmaxreserves = reserves
        }

        this.filter()
    }
    changeReserves = e => {
        let [reserves] = e
        if (this.state.selectedmaxreserves < 0)
            return
        if (isNaN(reserves))
            reserves = this.state.maxreserves
        if (reserves !== this.state.selectedmaxreserves) {
            this.setState({ selectedmaxreserves: reserves })
        // eslint-disable-next-line react/no-direct-mutation-state
            this.state.selectedmaxreserves = reserves
        }

        this.filter()
    }

    showSpinner = () => {
        return (
            <div id="spinner">
                <Row><Col className="text-center mt-5 mb-5 pb-5 pt-5">
                    <img className="loader" alt="loader" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img></Col></Row>
            </div>
        )
    }
    removeSelection(hash) {
        let comparisonSelections = this.state.comparisonSelections
        delete comparisonSelections[hash]

        this.setState({ comparisonSelections })
    }
    selectLoan(hash) {
        let ob = this.referi[hash][0]
        let loan = new ChosenLoan()
        loan.hash = hash

        loan.APR = ob.Rate.APR
        if (ob.Rate.Arm !== null)
            loan.Arm = ob.Rate.Arm
        loan.amortization = ob.Rate.MortgageAmortization
        loan.Mtype = ob.Rate.Mtype
        loan.Term = ob.Rate.Term
        loan.base_rate = ob.Rate.base_rate
        loan.closing_cost = ob.Rate.closing_cost
        loan.features = ob.Rate.features
        loan.high_cost_mortgage = ob.Rate.high_cost_mortgage
        loan.lender_credit = ob.Rate.lender_credit
        loan.lender_fee = ob.Rate.lender_fee
        loan.lockDay = ob.Rate.lockDay
        loan.min_months_reserves = ob.Rate.min_months_reserves
        loan.monthly_payment = ob.Rate.monthly_payment
        loan.prepaid_interest = ob.Rate.prepaid_interest
        loan.points = ob.Rate.points
        loan.pmi = ob.Rate.PMICost
        loan.FHAMIP = ob.Rate.FHAMIP
        loan.DTI = ob.Rate.DTI
        loan.matchingproducts = []
        loan.loansize = this.state.loansize
        loan.purpose = this.props.property.purpose
        loan.ourfee = this.zeitro_fee
        loan.prepaiddays = this.prepaid_interest_days
        
        if(ob.Rate.max_cashout != null) {
            loan.maxcashout = ob.Rate.max_cashout
        }
        // preserve edits if they were already made
        if (this.props.selection.selectedloan != null && this.props.selection.selectedloan.estimatedclosingcosts != null) {
            loan.estimatedclosingcosts = this.props.selection.selectedloan.estimatedclosingcosts
        } else {
            loan.estimatedclosingcosts = this.props.selection.estimatedclosingcosts
        }
        let cc = loan.estimatedclosingcosts
        if (loan.FHAMIP != null) {

            let addFha = () => {
                let found = false

                cc.cannotshopservices.forEach(key => {
                    if (key.servicename === com.upfrontMI) {
                        key.estimateamount = loan.FHAMIP.upfrontMIP
                        found = true
                    }
                })
                if (!found) {
                    let s = new service()
                    s.servicename = com.upfrontMI
                    s.estimateamount = loan.FHAMIP.upfrontMIP
                    cc.cannotshopservices.push(s)
                }
            }
            addFha()
        } else {

            for (let i = 0; i < cc.cannotshopservices.length; i++) {
                if (cc.cannotshopservices[i].servicename === com.upfrontMI) {
                    cc.cannotshopservices = cc.cannotshopservices.splice(i, 1)
                    break
                }
            }
        }

        switch (this.props.property.purpose) {
            case "purchase":
                loan.downpayment = this.state.downpayment
                break;
            case "refinance":
                loan.paydown = this.state.downpayment
                break;
            case "cashoutrefinance":
                loan.cashout = this.state.downpayment
                break;
            default:
                break;
        }

        loan.aus = ob.Aus

        this.referi[hash].forEach(
            pr => {
                let l = new LoanProduct()

                l.Lender = pr.Lender
                l.Name = pr.Name
                l.lenderinfo = pr.LenderInfo
                l.aus = pr.Aus
                loan.matchingproducts.push(l)
            }
        )
        // loan.lender = lender2lender[loan.matchingproducts[0].Lender ]

        this.props.updateSelectField(loan, "selectedloan")

        if (("" === this.props.application.borrower.expenses.futuremortgageinsurance ||
            "0" === this.props.application.borrower.expenses.futuremortgageinsurance
        ) &&
            0 !== loan.pmi) {
            let insurance = (parseFloat(loan.monthly_payment) * loan.pmi).toFixed(2)

            this.props.updateExpenses({ target: { value: "monthly" } },
                "borrower", "futuremortgageinsuranceperiod")
            this.props.updateExpenses({ target: { value: insurance } },
                "borrower", "futuremortgageinsurance")
        } else {
            if (0 === loan.pmi) {
                this.props.updateExpenses({ target: { value: 0 } },
                    "borrower", "futuremortgageinsurance")
            }
        }

        this.getSelectedIcon(loan)
    }
    selectCard = card => {
        com.touch()
        this.selectLoan(card.hash)
    }

    getOldInterestRate = () => {

        if (this.props.application.property.purpose !== st.POL_Refinance)
            return
        // just scan all the loans for rate
        // first regular loans from credit report
        let rate = 0
        for (let i = 0; i < this.props.application.assetsandliabilities.loans.length; i++) {
            if (this.props.application.assetsandliabilities.loans[i].forsubjectproperty &&
                this.props.application.assetsandliabilities.loans[i].satisfiedupon === "withproceeds") {
                rate = this.props.application.assetsandliabilities.loans[i].rate
            }
        }

        if (0 === rate) {
            if (this.props.application.otherliens !== null) {
                for (let i = 0; i < this.props.application.otherliens.length; i++) {
                    if (this.props.application.otherliens[i].satisfiedupon === "withproceeds") {
                        rate = this.props.application.otherliens[i].rate
                    }
                }
            }
        }
        return rate
    }
    displayCard = (card, index) => {

        let currentMonthly = com.getMonthlyValue(this.props.application.borrower.expenses.currentfirstmortgage,
            this.props.application.borrower.expenses.currentfirstmortgageperiod)
        let mostyle = {}
        if (card.monthly_payment > parseFloat(currentMonthly)) {
            mostyle = { color: '#c33' }
        } else {
            mostyle = { color: '#393' }
        }
        let showSavings = () => {

            if (this.props.property.purpose !== "refinance")
                return ""
            if (card.monthly_payment > parseFloat(currentMonthly)) {
                return <i className="ml-1 fas fa-arrow-up"></i>
            }
            return <i className="ml-1 fas fa-arrow-down"></i>
        }

        if (this.props.selection.selectedloan !== null && (this.props.selection.selectedloan.hash === card.hash))
            return ""
        let getName = () => {
            if (this.props.borrowerid && this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash][0]
                return r["Lender"] + ", " + r["Name"]
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let getType = () => {
            let tp = ""
            if (card.Arm == null )
                tp = "Fixed rate"
            else
                tp = "ARM " + card.Arm.fixedperiod + "/6"
            return tp
        }

        let selectCard = e => { return this.selectCard(card) }
        let fingerclass = "fas fa-hand-point-left mr-1"
        if (index === 0)
            fingerclass += " fingerclass"
        let points = com.commaizeFloat((card.points * com.safeParseInt(this.state.loansize) / 100).toFixed(2))
        let moPayment = () => {
            let payment = card.monthly_payment
            if (card.PMICost !== 0) {
                payment = payment + card.PMICost
            } else if (card.FHAMIP != null) {
                payment = payment + card.FHAMIP.monthlyMIP
            }
            payment = com.commaizeFloat(payment.toFixed(2))
            return payment
        }
        let src = ""
        // if (this.referi != null && this.referi && this.referi[card.hash] != null) {
        //     let lender = this.referi[card.hash][0]
        //     src = this.props.borrowerid ? lender2icon[lender.Lender] : "/loan.png"
        // }
        let oncheckbox = (e) => {
            let ch = e.target.checked
            let ar = this.state.comparisonSelections
            if (ar[card.hash] != null)
                delete ar[card.hash]

            if (ch) {
                ar[card.hash] = this.referi[card.hash]
                ar[card.hash].loansize = this.state.loansize
                ar[card.hash].paydown = this.props.selection.paydown
                ar[card.hash].downpayment = this.props.selection.downpayment
                ar[card.hash].cashout = this.props.selection.cashout
            }
            this.setState({ comparisonSelections: ar })
        }


        let getBreakPoint = (loan) => {
            let interestRate = this.getOldInterestRate()

            function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

                let monthlyrate = rate / 12.0
                let expn = Math.pow(1 + monthlyrate, termmonths)
                let payment = loansize * monthlyrate * expn / (expn - 1)
                payment = (Math.round(payment * 100) / 100)

                let accumulatedinterest = (loansize * monthlyrate - payment) *
                    (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
                    spanmonths * payment
                return accumulatedinterest
            }

            let breakPoint = -1
            if (parseFloat(interestRate) <= loan.base_rate) {
                return breakPoint
            }

            for (let i = 1; i <= loan.Term * 12; i++) {
                let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, this.state.loansize, loan.Term * 12, i)
                let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, this.state.loansize, loan.Term * 12, i)

                accumulatedNew += loan.closing_cost
                if (accumulatedNew < accumulatedOld) {
                    breakPoint = i
                    break
                }
            }
            return breakPoint
        }

        return (
            <Container key={card.hash} className="drop-shadow w-100 px-0 text-left">
                <Row className="bluishbackground text-left mt-3 mb-0 py-2">
                    <Col style={{ alignItems:"center", fontWeight: 500 }} className="d-flex text-left ">
                        {getName()}
                        {this.props.borrowerid ? "" : <div>, {getType()}, {card.Term} years </div>}
                    </Col>
                    <Col xs={"auto"} className="text-right align-top loancard " >
                        <div className='d-flex align-items-center'>
                        <a className='aAsLink mr-2' onClick={selectCard} >Choose rate</a>
                        <a className='aAsLink ' onClick={() => {
                                    // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.detailedCard = card
                            this.setState({ detailedCard: card, show: true })
                        }
                        }>Cost Breakdown</a>
                        </div>
                    </Col>
                </Row>
                <Row className="text-left mt-0 mb-0 mx-0">

                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div className='littleheading'>Rate / APR</div>
                        <div>{card.base_rate}% / {card.APR}%</div>
                    </Col>
                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div className='littleheading'>Mo. Payment</div>
                        {this.props.property.purpose === "refinance" ? MonhtlyChangeBP(currentMonthly, card.monthly_payment, getBreakPoint(card),
                            <span style={mostyle} ><b>${moPayment()}</b>{showSavings()}</span>
                        ) : <b>${moPayment()}</b>}                    </Col>
                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div className='littleheading'>Estimated Closing Cost</div>
                        <b>${com.commaizeFloat((this.getClosingCosts(card)).toFixed(2))}</b>
                    </Col>
                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div className='littleheading'>Points</div>
                        <div>{com.commaizeFloat(card.points.toFixed(3))}%{card.points <= 0 ? "" : <span>/${points}</span>}</div>
                    </Col>
                </Row>
                <Row className="text-left mt-2 mb-0 mx-0 d-flow">

                    <Col className=" d-flow loancard" style={{ color: "#6E6E70" }}>
                        {card.PMICost !== 0 ? <span className="mr-3">Requires mortgage insurance ${com.commaizeFloat(card.PMICost)}/mo</span> : ""}

                        {com.safeParseInt(card.min_months_reserves) > 0 ?
                            <span className=" py-2  mr-3">
                                Reserves required: {card.min_months_reserves} months
                            </span> : ""}

                        {card.FHAMIP != null ?
                            <span className=" py-2  mr-3">
                                Upfront mortgage insurance premium: ${card.FHAMIP.upfrontMIP}, monthly insurance payment: ${com.commaizeFloat(card.FHAMIP.monthlyMIP)}
                            </span>
                            : ""}
                    </Col>

                </Row>

            </Container>
        )
    }
    acceptFiddle = () => {
        this.setState({ showSelf: false })
        this.componentDidMount()
    }

    handleSubmit = (event) => {
        if (this.props.application.hascoborrower === "multiple") {
            let notready = 0
            let remotes = this.props.application.remotecoborrowers

            for (let i = 0; i < remotes.length; i++) {
                let pos = this.state.positionbyemail[remotes[i].email]
                if (pos < com.prefillLimit)
                    notready += 1
            }
            if (notready > 0) {
                alert("We can't proceed to the product selection until all the coborrowers fill their applications to this point!")
                return false
            }
        }

        if (this.props.selection.selectedloan.hash === "") {
            alert("Please select a suitable loan")
            return false
        }
        return true
    }
    onDownPurchase = () => {
        let newd = this.state.downpayment - 1
        if (newd < 0)
            newd = 0
        this.setState({ downpayment: newd })
    }
    onDone = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            switch (this.props.property.purpose) {
                case "purchase":
                    this.props.updateSelectField(parseInt(this.state.downpayment), "downpayment")
                    break;
                case "refinance":
                    this.props.updateSelectField(parseInt(this.state.downpayment), "paydown")
                    break;
                case "cashoutrefinance":
                    this.props.updateSelectField(parseInt(this.state.downpayment), "cashout")
                    break;
                default:
                    alert("should not see this!")
                    break;
            }
            this.getDTI()
        }, 100)
    }
    onUpPurchase = () => {
        let newd = this.state.downpayment + 1
        if (newd > this.props.property.salesprice)
            newd = this.props.property.salesprice
        this.setState({ downpayment: newd })
    }
    printDownpayment = () => {
        let getPercent = () => {
            return Math.floor(100 * (parseFloat(this.props.property.salesprice)-parseFloat(this.state.downpayment)) / parseFloat(this.props.property.salesprice))
        }
        
        let purchase = () => {

            return <div style={{fontWeight:"600"}}>
                <div style={{ fontSize: '1.1em', fontWeight: '600', width: '100%', textAlign: 'left' }}>Adjust downpayment:</div>
                <Row className="mt-2 px-0"><Col xs={6} className='pl-0'>Purchase Price:</Col> <Col xs={6} >${com.commaize(this.props.property.salesprice)} </Col></Row>
                <Row className='px-0'><Col className="mt-1 pl-0" xs={6}>Loan amount:</Col> <Col className="mt-1" xs={6}>${com.commaize(this.props.property.salesprice - this.state.downpayment)} </Col></Row>
                <Row className='px-0'><Col className="mt-1 pl-0"  xs={6}>LTV:</Col> <Col className="mt-1" xs={6} >{getPercent()}% </Col></Row>
                <Row className="mb-1 px-0"><Col className="mt-2 pl-0" xs="auto">Downpayment:</Col> 
                    {/* <Col className="text-left">
                        <div className="downpayment d-flex" style={{ fontSize: '0.9em', width: '110px !important' }} >
                            <span><input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} className="d-block" style={{ width: '110px !important' }} type="text" size="10"
                                pattern="^[0-9,]+$"
                                onChange={this.onDownpaymentInput} required={false}
                                value={com.commaize(this.state.downpayment)} />
                                </span>
                            <span className=" pl-1 mt-1">({getPercent()}%)</span></div>
                    </Col> */}
                </Row>
            </div>
        }
        let refirate = () => {
            let onUpRefirate = () => {
                let newd = this.state.downpayment + 1
                if (newd > com.safeParseInt(this.props.application.loanbalance))
                    newd = com.safeParseInt(this.props.application.loanbalance)
                this.setState({ downpayment: newd })
            }
            return <div style={{fontWeight:"600"}}>
                <div style={{ fontSize: '1.1em', fontWeight: '600', width: '100%', textAlign: 'left' }}>Adjust loan amount:</div>
                <Row className="mt-2 px-0"><Col className='pl-0'>Current balance:</Col> <Col>${com.commaize(this.props.application.loanbalance)} </Col></Row>
                <Row className='px-0'><Col className='pl-0'>Loan amount:</Col> <Col >${com.commaize(com.safeParseInt(this.props.application.loanbalance) - this.state.downpayment)} </Col></Row>
                <Row className="mt-2 mb-1 px-0"><Col className='pl-0'>Paydown principal:</Col> </Row>

            </div>
        }
        let cashout = () => {
            let onUpCashout = () => {
                let newd = this.state.downpayment + 1

                if (newd > this.props.property.appraisal - com.safeParseInt(this.props.application.loanbalance))
                    newd = this.props.property.appraisal - com.safeParseInt(this.props.application.loanbalance)
                this.setState({ downpayment: newd })
            }
            return <div style={{fontWeight:"600"}}>
                <div style={{ fontSize: '1.1em', fontWeight: '600', width: '100%', textAlign: 'left' }}>Adjust cash out</div>
                <Row className="mt-2 px-0"><Col className='pl-0'>Current balance:</Col> <Col>${com.commaize(this.props.application.loanbalance)} </Col></Row>
                <Row className='px-0'><Col className='pl-0'>Loan amount:</Col> <Col>${com.commaize(com.safeParseInt(this.props.application.loanbalance) + com.safeParseInt(this.state.downpayment))} </Col></Row>
                <Row className="mb-1 mt-2 px-0"><Col className='pl-0'>Cash out:</Col></Row>

            </div>
        }

        switch (this.props.property.purpose) {
            case "purchase":
                return (
                    <div>
                        <div style={{ fontSize: '1.0em' }} className=" text-left"> {purchase()} </div>
                        <Row className="mt-1 px-0">
                        <Col className='px-0'><ScrollBar scrollChangeValue={this.changeDownpayment} step={1000} min={0} max={com.safeParseInt(this.props.property.salesprice)}  initVal={this.state.defdownpayment} /></Col>
                            {/* <Col><HorizontalSlider onUpdate={this.updateDownpayment} onChange={this.changeDownpayment} step={1} domain={[0, com.safeParseInt(this.props.property.salesprice)]} values={[this.state.defdownpayment]} width="240px" style={{ width: "240px" }} /></Col> */}
                        </Row>
                    </div>
                )
            case "refinance":
                return (
                    <div>
                        <div style={{ fontSize: '1.0em' }} className="mb-0 ml-1 text-left"> {refirate()} </div>
                        <Row className="mt-1 px-0">
                            <Col className='px-0'><ScrollBar scrollChangeValue={e => this.onDownpaymentInput({target:{value: e}})} step={1000} min={0} max={com.safeParseInt(this.props.application.loanbalance)}  initVal={this.state.defdownpayment} /></Col>
                            {/* <Col><HorizontalSlider onUpdate={this.updatePaydown} onChange={this.changePaydown} step={1} domain={[0, com.safeParseInt(this.props.application.loanbalance)]} values={[this.state.defdownpayment]} width="240px" style={{ width: "240px" }} /></Col> */}
                        </Row>
                    </div>
                )
            case "cashoutrefinance":
                return (
                    <div>
                        <div style={{ fontSize: '1.0em' }} className="mb-0 text-left"> {cashout()} </div>
                        <Row className="mt-1 px-0">
                            <Col className='px-0'><ScrollBar scrollChangeValue={this.changeCashout} step={1000} min={0} max={com.safeParseInt(this.props.property.appraisal - this.props.application.loanbalance)}  initVal={this.state.defdownpayment} /></Col>
                            {/* <Col><HorizontalSlider onUpdate={this.updateCashout} onChange={this.changeCashout} step={1} domain={[0, com.safeParseInt(this.props.property.appraisal - this.props.application.loanbalance)]} values={[this.state.defdownpayment]} width="240px" style={{ width: "240px" }} /></Col> */}
                        </Row>
                    </div>
                )
            default:
                alert("should not see this!")
                return "";
        }
    }
    getClosingCosts = (card) => {

        let cc = card.closing_cost

        if (cc < 0)
            cc = 0
        return cc
    }
    getRemainintLenderCredits = (card) => {

        let cc = card.closing_cost

        if (cc > 0)
            cc = 0
        return -cc
    }
    onCCInput = e => {

        let val = com.decommaizeNumeric(e.target.value.toString())
        this.setState({ highclosingcost: val })
        if (val < this.state.minclosingcost || val > this.state.maxclosingcost)
            return
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.highclosingcost = val
        this.oldmaxupfrontcost = val
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.processProducts(this.rawproducts), 400)
        //
    }


    getDownpayment = (card) => {
        switch (this.props.property.purpose) {
            case "purchase":
                return card.downpayment
            case "refinance":
                return card.paydown
            case "cashoutrefinance":
                return 0
            default:
                return 0;
        }

    }
    deleteSelection = () => {
        com.touch()
        this.props.updateSelectField(new ChosenLoan(), "selectedloan")
    }
    displaySelectedCard = () => {
        if  (this.props.selection.selectedloan === null || this.props.selection.selectedloan.hash === "") {
            return ""
        }

        let card = this.props.selection.selectedloan
        let currentMonthly = com.getMonthlyValue(this.props.application.borrower.expenses.currentfirstmortgage,
            this.props.application.borrower.expenses.currentfirstmortgageperiod)
        let mostyle = {}
        if (card.monthly_payment > parseFloat(currentMonthly)) {
            mostyle = { color: '#c33' }
        } else {
            mostyle = { color: '#393' }
        }
        let showSavings = () => {
            if (this.props.property.purpose !== "refinance")
                return ""
            if (card.monthly_payment > parseFloat(currentMonthly)) {
                return <i className="ml-1 fas fa-arrow-up"></i>
            }
            return <i className="ml-1 fas fa-arrow-down"></i>
        }
        let getName = () => {
            if (this.props.borrowerid && this.referi && this.referi[card.hash] != null) {
                let r = this.referi[card.hash]
                if (r !== undefined && r[0] !== undefined)
                    return com.capitalize(r[0]["Lender"]) + ", " + r[0]["Name"]
            }
            return com.capitalize(card.Mtype.replace('_', ' ').toLowerCase().replace('fha', 'FHA'))
        }
        let getType = (card) => {
            if (null == card)
                return ""
            if (card.Arm == null || card.amortization === "fixed")
                return "Fixed rate"
            return "ARM " + card.Arm.fixedperiod + "/6"
        }
        let loansize = com.safeParseInt(card.loansize)


        let points = com.commaizeFloat((card.points * com.safeParseInt(card.loansize) / 100).toFixed(2))
        let moPayment = () => {

            let payment = card.monthly_payment
            if (card.pmi !== 0) {
                payment = payment + card.pmi
            } else if (card.FHAMIP != null) {
                payment = payment + card.FHAMIP.monthlyMIP
            }
            payment = com.commaizeFloat(payment.toFixed(2))
            return payment
        }

        let getBreakPoint = (loan) => {
            let interestRate = this.getOldInterestRate()

            function getClosedInterestPaid(rate, loansize, termmonths, spanmonths) {

                let monthlyrate = rate / 12.0
                let expn = Math.pow(1 + monthlyrate, termmonths)
                let payment = loansize * monthlyrate * expn / (expn - 1)
                payment = (Math.round(payment * 100) / 100)

                let accumulatedinterest = (loansize * monthlyrate - payment) *
                    (Math.pow(1 + monthlyrate, spanmonths) - 1) / monthlyrate +
                    spanmonths * payment
                return accumulatedinterest
            }

            let breakPoint = -1
            if (parseFloat(interestRate) <= loan.base_rate) {
                return breakPoint
            }

            for (let i = 1; i <= loan.Term * 12; i++) {
                let accumulatedOld = getClosedInterestPaid(parseFloat(interestRate) / 100, loan.loansize, loan.Term * 12, i)
                let accumulatedNew = getClosedInterestPaid(loan.base_rate / 100, loan.loansize, loan.Term * 12, i)

                accumulatedNew += loan.closing_cost
                if (accumulatedNew < accumulatedOld) {
                    breakPoint = i
                    break
                }
            }
            return breakPoint
        }
        let image = this.state.selectedImage
        let onHidePriceChanged = () => {
            this.setState({ showPriceChanged: false })
        }
        let useSuggestion = () => {
            this.props.updateSelectField(this.state.suggestedLoan, "selectedloan")
            this.getSelectedIcon(this.state.suggestedLoan)
            this.setState({ showPriceChanged: false })
        }

        let oncheckbox = (e) => {
            let ch = e.target.checked
            let ar = this.state.comparisonSelections
            if (ar[card.hash] != null)
                delete ar[card.hash]

            if (ch) {
                ar[card.hash] = this.referi[card.hash]
                ar[card.hash].loansize = card.loansize
                ar[card.hash].paydown = card.paydown
                ar[card.hash].downpayment = card.downpayment
                ar[card.hash].cashout = card.cashout
            }
            this.setState({ comparisonSelections: ar })
        }
        let st = {}
        try {
            if (this.state.suggestedLoan.closing_cost > this.props.application.selection.selectedloan.closing_cost) {
                st = { color: '#633' }
            } else {
                if (this.state.suggestedLoan.closing_cost < this.props.application.selection.selectedloan.closing_cost) {
                    st = { color: '#363' }
                }
            }
        } catch (x) {

        }

        return <div className="mb-4" key={card.hash}>

            <Modal show={this.state.showPriceChanged} onHide={onHidePriceChanged} >
                <Modal.Header closeButton>
                    <Modal.Title className="capitalize">The closing cost of your selected loan has changed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.suggestedLoan != null ?
                        <div>
                            <Row><Col className="mb-3 sectionquestion text-center">Here's a new suggested loan: </Col> </Row>
                            <Row><Col>Loan type:</Col><Col>{getType(this.state.suggestedLoan)},  {this.state.suggestedLoan.Term} years</Col> </Row>


                            <Row><Col>Loan amount:</Col><Col> ${com.commaizeFloat(this.state.suggestedLoan.loansize)}</Col> </Row>
                            <Row><Col>Base rate: </Col><Col>{this.state.suggestedLoan.base_rate}%</Col> </Row>
                            {this.state.suggestedLoan.base_rate !== this.props.application.selection.selectedloan.base_rate ?
                                <Row><Col>Old rate: </Col><Col>{this.props.application.selection.selectedloan.base_rate}% </Col> </Row>
                                : ""
                            }
                            <Row><Col>APR:</Col><Col> {this.state.suggestedLoan.APR}%</Col> </Row>
                            <Row><Col>Monthly payment: </Col><Col>  ${com.commaizeFloat(this.state.suggestedLoan.monthly_payment)}</Col> </Row>
                            {this.state.suggestedLoan.closing_cost >= 0 ?
                                <Row><Col>Closing costs:</Col><Col style={st}>  ${com.commaizeFloat(this.state.suggestedLoan.closing_cost.toFixed(2))}</Col> </Row>
                                :
                                <Row><Col>Closing costs: $0</Col><Col style={st} >Remaining credits: ${com.commaizeFloat(-this.state.suggestedLoan.closing_cost.toFixed(2))}
                                </Col> </Row>
                            }
                            {this.state.suggestedLoan.closing_cost !== this.props.application.selection.selectedloan.closing_cost ?
                                (this.props.application.selection.selectedloan.closing_cost >= 0 ?
                                    <Row><Col> Old closing costs:</Col><Col> ${com.commaizeFloat(this.props.application.selection.selectedloan.closing_cost.toFixed(2))} </Col> </Row> :
                                    <Row><Col>  Old remaining credits:</Col><Col> ${com.commaizeFloat(this.props.application.selection.selectedloan.closing_cost.toFixed(2))} </Col> </Row>
                                )
                                : ""
                            }

                        </div>
                        : ""
                    }
                </Modal.Body>
                <Modal.Footer className="text-center d-block" >
                    <Row className="text-center">
                        <Col>
                            <Button variant="zeitro-primary" onClick={useSuggestion}>Choose suggestion</Button>
                        </Col>
                        <Col>
                            <Button variant="zeitro-outline" onClick={e => { this.deleteSelection(); onHidePriceChanged() }}>Remove chosen</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Container key={card.hash} className="drop-shadow mt-2 w-100 px-0 pb-3 text-left mt-3">
                <Row className='bluishbackground'><div className="chosenRatesLabel ">CHOSEN RATE</div></Row>
                <Row className="bluishbackground text-left  mb-0 mx-0 py-2">
                    <Col className="d-flex text-left  ">
                        <div className="d-flex text-left " style={{ alignItems:"center", fontWeight: 500 }}>
                            {getName()}
                            {this.props.borrowerid ? "" : <div>, &nbsp;{getType(card)}, {card.Term} years</div>}
                        </div>
                    </Col>
                    <Col xs="auto" className="text-right  loancard" >
                        {this.state.needsAdjustment ?
                            <a onClick={this.onPriceChange} className='aAsLink' style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }}>Click to update the loan</a>
                            :
                            <a onClick={this.deleteSelection} className='aAsLink'>Remove choice</a>
                        }
                    </Col>
                </Row>
                <Row className="text-left mt-0 mb-0 mx-0">

                    <Col xs={12} md={3} className="loancard pt-2 loancardmobile">
                        <div>Rate / APR</div>
                        <b>{card.base_rate}% / {card.APR}%</b>
                    </Col>
                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div>Mo. Payment</div>
                        {this.props.property.purpose === "refinance" ? MonhtlyChangeBP(currentMonthly, card.monthly_payment, getBreakPoint(card),
                            <span style={mostyle} ><b>${moPayment()}</b>{showSavings()}</span>
                        ) : <b>${moPayment()}</b>}
                    </Col>
                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div>Closing Cost</div>
                        <b>${com.commaizeFloat((this.getClosingCosts(card)).toFixed(2))}</b>
                    </Col>

                    <Col xs={12} md={3} className=" loancard pt-2 loancardmobile">
                        <div> Points</div>
                        {com.commaizeFloat(card.points.toFixed(3))}%{card.points <= 0 ? "" : <span>/${points}</span>}
                    </Col>
                </Row>
                <Row className="text-left text-wrap mt-2 mb-0 mx-0 ">
                    <Col className=" d-flow loancard" style={{ color: "#6E6E70" }}>
                        {card.pmi !== 0 ? <span className="mr-3">Requires mortgage insurance ${com.commaizeFloat(card.pmi)}/mo</span> : ""}

                        {com.safeParseInt(card.min_months_reserves) > 0 ?
                            <span className=" py-2  mr-3">
                                Reserves required: {card.min_months_reserves} months
                            </span> : ""}
                        {card.FHAMIP != null ?
                            <span className=" py-2  mr-3">
                                Upfront mortgage insurance premium: ${card.FHAMIP.upfrontMIP}, monthly insurance payment: ${com.commaizeFloat(card.FHAMIP.monthlyMIP)}
                            </span>
                            : ""}
                    </Col>

                </Row>
            </Container>

        </div>

    }
    checkStatus = () => {
        let token = sessionStorage.getItem("ZeitroA")
        if (this.props.application.remotecoborrowers.length > 0) {
            this.setState({ statusloaded: false })
            fetch('/data/coborrowerstatus', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrowerid,
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        //alert('Looks like there was a problem. Status Code: ' +
                        //    response.status);
                        //window.document.dispatchEvent(new Event('reauthenticate'), "");

                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {
                        let positionbyemail = {}
                        let proc = (a, i) => {
                            let email = a.Email
                            let position = a.Position
                            positionbyemail[email] = position
                        }
                        js.map(proc)
                        this.setState({ positionbyemail: positionbyemail, statusloaded: true })
                    });
                }
            ).catch((err) => {
                console.log('Fetch Error :', err);
            });

        }
    }

    renderRemoteCoborrowers = (rb, i) => {
        let getStatus = () => {
            let status = "Invited"
            if (rb.email in this.state.positionbyemail) {
                let pos = this.state.positionbyemail[rb.email]

                status = com.positionToStatus(pos)
            }
            return status
        }
        if (this.state.positionbyemail[rb.email] >= com.prefillLimit)
            return <div></div>
        else {
            clearTimeout(this.timer)
            this.timer = setTimeout(this.checkStatus, 30000)
            return (
                <div className="viewport mb-3 drop-shadow text-left" key={rb.key}>
                    <Form.Row>
                        <Col  >
                            <Form.Group controlId={"first_name" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >First Name:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.firstname}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"last_name" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Last Name:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.lastname}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"email" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Email:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{rb.email}</div>
                            </Form.Group>
                        </Col>
                        <Col  >
                            <Form.Group controlId={"email" + rb.key} className="text-left" >
                                <Form.Label className="text-left" >Status:</Form.Label><br />
                                <div style={{ fontSize: '1.2em' }}>{getStatus()}</div>
                            </Form.Group>
                        </Col>

                    </Form.Row>
                </div>
            )
        }
    }

    waiting = (num) => {

        return (
            <div className="mx-2 px-2 text-left  mb-4 bg-">
                <h2 className=" heading">
                    Product selector
                </h2>
                <div className="viewport text-center mt-4 ">
                    <div className="mb-3 sectionquestion">{
                        (num === 1) ? <div>Look like your coborrower has not filled enough of their application to allow product selection:</div>
                            : <div>Look like some of your coborrowers have not filled enough of their application to allow product selection:</div>
                    }</div>
                    {this.props.application.remotecoborrowers.map(this.renderRemoteCoborrowers)}

                </div>
            </div>
        )
    }

    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="ratesDropdown "
        >
            <div style={{color:"black", fontWeight:"400", paddingTop:"4px"}} ><img src={children==="Sort by"?'/images/sort.png':'/images/filter.png'}  style={{width:"17px", height:"17px", marginRight:"8px"}}/>{children}</div>
            <img src='/images/DropdownSign.png' style={{width:"35px", height:"30px"}}/>
        </a>
      ));

    contactLO = () => {
        this.setState({showContactModal: true})

        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/contactloaboutrates', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log(response)
                    return;
                }
                return
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });

    }
    showSortButton = () => {
        return (
            <div className='displayButtons'>
                <Button variant='zeitro-primary' onClick={()=>this.contactLO()}>Contact LO</Button>
                <div className='displayFilters'>
                <Dropdown onSelect={this.selectsort} size="sm" className="dropdownsort px-2">
                <Dropdown.Toggle as={this.CustomToggle} id="dropdownsort" size="sm" >
                    Sort by
                </Dropdown.Toggle>

                <Dropdown.Menu size="sm" >
                    {Object.keys(sorts).map(k => {
                        return (
                            <Dropdown.Item key={k} eventKey={k} active={k === this.props.selection.sortby} >
                                {sorts[k]}
                            </Dropdown.Item>)
                    })}
                </Dropdown.Menu>
                </Dropdown>
                <Dropdown onSelect={this.selectsort} size="sm" className="dropdownsort hideOnDesktop">
                    <Dropdown.Toggle as={this.CustomToggle} id="dropdownsort" size="sm" >
                        Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu size="sm" className='ratesfilterDropdown'>
                    <div className="p-3 ">
                                {this.displayFilters()}
                            </div>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </div>
        )
    }
    displayFilters = () => {
        return        <div>
                            <div >
                                {this.printDownpayment()}
                            </div>
                            {/*
                            <div style={{ fontSize: '0.9em' }} className="mt-3 mb-0 ml-1 text-left"><b style={{fontSize: '1.2em'}}>Limit mo. payment range:</b><br/>${com.commaize(this.state.lowpayment)} - ${com.commaize(this.state.highpayment)} </div>
                            <Row>
                                <Col><HorizontalSlider onUpdate={this.updatePayment} onChange={this.changePayment} step={10} domain={[this.state.minpayment, this.state.maxpayment]} values={[this.state.lowpayment, this.state.highpayment]} width="280px" style={{ width: "280px" }} /></Col>
                            </Row>
                            */}
                            <Row className='pt-2 px-0 mb-1'><Col className='text-left pl-0' style={{fontWeight:500}}>Maximum closing costs: <Tips 
                                content={<div><div>By moving this slider, you select your <b>trade off between low closing costs and best rate</b></div>
                                <div className="mt-2">Set it all the way to the left to find the best rate product with the <b>lowest closing costs</b></div>
                                <div className="mt-2">If you want to <b>buy down a better rate</b>, move the slider to the right</div>
                            </div>}
                                placement="right"
                                title=""
                            />  
                                </Col></Row>

                            <Row className='px-0'>
                                <Col className='px-0'><ScrollBar  scrollChangeValue={this.changeCost} step={1000} min={this.state.minclosingcost} max={this.state.maxclosingcost} initVal={this.state.highclosingcost} /></Col>
                            </Row>
                            {this.state.maxreserves > 0 ?
                                <div>
                                    <Row className="mt-2 mb-1"  ><Col style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Limit reserves:</Col><Col>

                                        <div className="downpayment" style={{ fontSize: '0.9em', width: '110px !important' }} >

                                            <div style={{ paddingTop: '3px', width: '110px !important' }} type="text" size="10"
                                            >
                                                {this.state.selectedmaxreserves} mo.</div>


                                        </div>

                                    </Col></Row>
                                    <Row>
                                        <Col><HorizontalSlider onUpdate={this.updateReserves} onChange={this.changeReserves} step={1} domain={[0, this.state.maxreserves]} values={[this.state.selectedmaxreserves]} width="240px" style={{ width: "240px" }} /></Col>
                                    </Row>
                                </div> : ""}

                            <Row >
                                <Col xs={12} md={"auto"} className="border mr-2 text-left text-wrap p-0 mt-2">
                                    <div className="text-left mb-2 bluishbackground pl-1" >Amortization
                                    </div>
                                    <Form.Group controlId={"types"} className="px-2 text-left" >
                                        <Multiselect
                                            showCheckbox={true}
                                            closeIcon="close"
                                            style={customstyle}
                                            options={typeoptions} // Options to display in the dropdown
                                            selectedValues={this.state.typeselected} // Preselected value to persist in dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            onRemove={this.onRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6} className="border text-left text-wrap ml-0 p-0 mt-2">
                                    <div className="text-left mb-2 bluishbackground pl-1"  >Loan Terms</div>
                                    <Form.Group controlId={"terms"} className="px-2 text-left" >
                                        <Multiselect
                                            closeIcon="close"
                                            showCheckbox={true}
                                            className="p-0"
                                            style={customstyle}
                                            radio="true"
                                            // name="loantermsselector"
                                            options={termoptions} // Options to display in the dropdown
                                            selectedValues={this.state.termselected} // Preselected value to persist in dropdown
                                            onSelect={this.onSelectTerms} // Function will trigger on select event
                                            onRemove={this.onRemoveTerms} // Function will trigger on remove event
                                            // displayValue="name" // Property name to display in the dropdown options
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {
                                this.props.borrowerid ? (
                                    <Row className="mt-3 pr-4 mt-4 pt-2">
                                    <Col xs className="border mr-2 text-left text-wrap ml-1 p-0" style={{fontWeight:"600"}}>
                                        <div className="text-left mb-2 bluishbackground pl-1" >Lenders
                                        </div>
                                        <Form.Group controlId={"types"} className="px-2 text-left" >
                                            <Multiselect
                                                showCheckbox={true}
                                                closeIcon="close"
                                                style={customstyle}
                                                options={this.state.lenders} // Options to display in the dropdown
                                                selectedValues={this.state.selectedlenders} // Preselected value to persist in dropdown
                                                onSelect={this.onSelectLenders} // Function will trigger on select event
                                                onRemove={this.onRemoveLenders} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                ) : ("")
                            }
                            
                            {this.props.borrowerid ?
                                <Row>
                                    <Col className="mt-2">
                                        <Button variant="primary" className="lpbutton" size="sm" onClick={this.reset}

                                        >
                                            Reset</Button>

                                    </Col>
                                </Row> : ""}
                        </div>
    }
    render = () => {
        let showMore = () => {
            this.setState({ showMore: false })
            this.howmany2display = 10
            if (this.props.borrowerid)
                this.howmany2display *= 3
            this.processProducts(this.rawproducts)
        }
        let showLess = () => {
            this.setState({ showMore: true })
            this.howmany2display = 5
            if (this.props.borrowerid)
                this.howmany2display *= 3
            this.processProducts(this.rawproducts)
        }

        if (this.props.application.hascoborrower === "multiple") {
            if (!this.state.statusloaded) {
                return <div className="mb-5 pb-3">
                    <Row><Col className="text-center mt-5 pt-5"><img alt="" className="rotate" src="/logo.svg"></img></Col></Row>

                </div>
            }

            let notready = 0
            let remotes = this.props.application.remotecoborrowers

            for (let i = 0; i < remotes.length; i++) {
                let pos = this.state.positionbyemail[remotes[i].email]
                if (pos < com.prefillLimit)
                    notready += 1
            }
            if (notready > 0) {
                return <div>
                    {this.waiting(notready)}
                </div>
            }
        }

        return (
            <Container id="picker" className="text-left mt-0 mb-2 p-0 w-100 px-0 mx-0" fluid>
            
                <Modal show={this.state.show} dialogClassName="loandetails mt-5"
                    onHide={() => this.setState({ show: false })}>
                    <Modal.Header closeButton >
                        <Modal.Title><img alt="" src="/jackhammer.png" style={{ width: '32px' }}></img>Loan Drill Down</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Details seletion={this.props.selection.selectedloan} card={this.state.detailedCard} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ show: false })}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showComparison} dialogClassName="loandetails mt-5"
                    onHide={() => this.setState({ showComparison: false })}>
                    <Modal.Header closeButton >
                        <Modal.Title><img alt="" src="/jackhammer.png" style={{ width: '32px' }}></img>Loan Comparison</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Comparison
                            hide={
                                () => {
                                    this.setState({ showComparison: false })
                                }}
                            calldelete={this.removeSelection.bind(this)}
                            callselect={this.selectLoan.bind(this)}
                            selection={this.state.comparisonSelections} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({ showComparison: false })}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Row className='ratesHeader'>
                    <Col xs={12} md={6} ><div className='yourRatesTitle prequalInterviewTitle pt-3 '>My Rates</div></Col>
                    <Col xs={12} md={6} className="text-center px-0">{this.showSortButton()}</Col>
                </Row>
                <Row className="text-left  ">
                    <Col xs="auto" className="viewport text-center mx-0 px-0  ml-2 pr-3 hideOnMobile">
                        {this.displayFilters()}
                    </Col>


                    <Col className="text-left">
                        {this.displaySelectedCard()}

                        {this.state.loading ? <div className='p-5'><Skeleton active/><Skeleton active/></div> : (this.state.topProducts.length > 0 ? this.renderProducts() : this.state.errorText)}

                        {this.state.topProducts.length > 0 ?
                            <Row className="mt-2"><Col className="text-right pr-4">
                                {this.state.showMore ? <Button variant="link" onClick={showMore} className='primary-dark-color'>Show More...</Button>
                                    : <Button variant="link" onClick={showLess} className='primary-dark-color'>Show Less...</Button>}
                            </Col>
                            </Row>
                            : ""}
                    </Col>
                </Row>

            </Container>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ProductPicker)
