import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'


import '../BorrowerApplication.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Button as But } from "antd"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import DateOfBirth from '../DateOfBirth'
import * as com from "../Common.js"
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Autocomplete from '../Autocomplete'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import { tooltip } from '../Common/Tooltip'
import { FutureExpenses } from '../State'
import { titles } from '../occupations.js'
import CheckGroup from "../Common/components/CheckGroup"
import { Tips } from "../Common/Tooltip"
import { isEmpty } from 'lodash'
import eventBus from '../Bus'
import Military from "./Military"
import dayjs from 'dayjs'
import { Input, InputNumber, Space, Select, Checkbox, DatePicker, Skeleton } from "antd"
import { DollarInput } from "../Common/components/DollarInput"
export function calculateEmploymentIncome(app, who) {
    let income = 0
    let employment = app[who].occupation

    let getIncomeType = (employment, token) => {
        switch (employment.income[token + "monthly"]) {
            case "monthly": {
                let v = parseFloat(employment.income[token])
                if (!isNaN(v))
                    return v
                break
            }
            case "yearly": {
                let v = parseFloat(employment.income[token])
                if (!isNaN(v))
                    return v / 12
                break
            }
            case "hourly": {
                let v = parseFloat(employment.income[token])
                let h = parseFloat(employment.income[token + "hoursperweek"])
                if (!isNaN(v) && !isNaN(h))
                    return v * h * 52 / 12
                break
            }
            default:
                break
        }
        return 0
    }
    if (app[who].occupation.hasoccupation === "employed") {
        if (app[who].occupation.selfemployed === true) {
            switch (employment.incomeorlossperiod) {
                case "monthly": {
                    let v = parseFloat(employment.incomeorloss)
                    if (!isNaN(v))
                        income += v
                    break
                }
                case "yearly": {
                    let v = parseFloat(employment.incomeorloss)
                    if (!isNaN(v))
                        income += v / 12
                    break
                }
                default:
                    break
            }
        } else {

            income += getIncomeType(employment, "base")
            income += getIncomeType(employment, "commissions")
            income += getIncomeType(employment, "overtime")
            income += getIncomeType(employment, "bonuses")
        }
        // other current income:
        for (let i = 0; app[who].otheroccupations !== null && (i < app[who].otheroccupations.length); i++) {
            let employment = app[who].otheroccupations[i]
            income += getIncomeType(employment, "base")
            income += getIncomeType(employment, "commissions")
            income += getIncomeType(employment, "overtime")
            income += getIncomeType(employment, "bonuses")
        }

    }
    return income
}
export function calculateDividendIncome(app, who) {
    let income = 0
    if (app[who].income.hasdividends) {
        let dividends = parseFloat(app[who].income.dividends)
        if (!isNaN(dividends)) {

            switch (app[who].income.dividendsmonthly) {
                case "monthly": {
                    income += dividends
                    break
                }
                case "yearly": {
                    income += dividends / 12
                    break
                }
                default:
                    break
            }

        }
    }
    return income
}

export function calculateOtherIncome(app, who) {
    let income = 0
    if (app[who].income.otherincome !== null && app[who].income.otherincome.length > 0) {
        app[who].income.otherincome.forEach(
            inc => {
                let v = parseFloat(inc.amount)
                if (isNaN(v))
                    return
                switch (inc.period) {
                    case "monthly": {
                        income += v
                        break
                    }
                    case "yearly": {
                        income += v / 12
                        break
                    }
                    default:
                        break
                }
            }
        )
    }
    return income
}

export function calculateIncomeWho(app, who) {
    let income = calculateEmploymentIncome(app, who)
    income += calculateDividendIncome(app, who)
    income += calculateOtherIncome(app, who)

    return income
}

const mapStateToPropsDetails = (state) => {
    return {
        application: state.application
    }
}

const mapDispatchToPropsDetails = (dispatch) => ({
    changeNonQMInfo: (event, who, verb) => {
        dispatch(act.ChangeNonQMInfo(event, who, verb));
      },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    setBorrowerHasAdditionalIncome: (value, who) => {
        dispatch(act.SetBorrowerHasAdditionalIncome(value, who))
    },
    updateIncomeMonthly: (event, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(event.target.value, who, verb))
    },
    addBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.AddBorrowerAdditionalIncome(t, who))
    },
    removeBorrowerAdditionalIncome: (t, who) => {
        dispatch(act.RemoveBorrowerAdditionalIncome(t, who))
    },
    clearBorrowerAdditionalIncome: (who) => {
        dispatch(act.ClearBorrowerAdditionalIncome(who))
    },

    updateBorrowerAdditionalIncome: (event, who, verb, n) => {

        dispatch(act.UpdateBorrowerAdditionalIncome(event.target.value, who, verb, n))
    },
    changeIncomeCheck: (checked, who, verb) => {
        dispatch(act.UpdateIncomeMonthly(checked, who, verb))
    },
    changeCurrentOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(!event.target.checked, who, verb))
    },
    changeCurrentOccupation: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event, who, verb))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changePhoneInfo: (phone, who, verb) => {
        dispatch(act.ChangeOccupationInfo(phone, who, verb))
    },
    changeOccupationCheck: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.checked, who, verb))
    },
    addAdditionaOccupation: (event, who) => {
        dispatch(act.AddAdditionaOccupation(event, who))
    },
    removeAdditionalOccupation: (event, who, i) => {
        dispatch(act.RemoveAdditionalOccupation(event.target.value, who, i))
    },
    clearAdditionalOccupations: (event, who) => {
        dispatch(act.ClearAdditionalOccupations(event, who))
    },
    changePastOccupationInfo: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.value, who, verb, index))
    },
    changePastOccupationDate: (date, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(date, who, verb, index))
    },
    changePastOccupationPhone: (phone, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(phone, who, verb, index))
    },
    changePastOccupationCheck: (event, who, verb, index) => {
        dispatch(act.ChangePastOccupationInfo(event.target.checked, who, verb, index))
    },


    addBorrowerOtherOccupation: (who) => {
        dispatch(act.AddBorrowerOtherOccupation(who))
    },
    removeBorrowerOtherOccupation: (who, index) => {
        dispatch(act.RemoveBorrowerOtherOccupation(who, index))
    },
    clearBorrowerOtherOccupations: (who) => {
        dispatch(act.ClearBorrowerOtherOccupations(who))
    },
    changeBorrowerOtherOccupationValue: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationValue(t, i, who, verb))
    },
    changeBorrowerOtherOccupationIncome: (t, i, who, verb) => {
        dispatch(act.ChangeBorrowerOtherOccupationIncome(t, i, who, verb))
    },
    changeMainProperty: (t, verb) => {
        dispatch(act.ChangeMainProperty(t, verb))
    },
    updateIncomeAtttribute: (t, who, verb) => {
        dispatch(act.UpdateIncomeAtttribute(t, who, verb))
    },
})

const getEmploymentType = (app, who) => {
    const e = app[who].occupation.hasoccupation
    const s = app[who].occupation.selfemployed

    if (e == "employed" && s) {
        return "selfemployed"
    } else if (e === "employed" && !s) {
        return "employed"
    }
    return e
}

export default class Income extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            isNonQMCustomer: null,
            validated: false,
            showSelf: false,
            isbasevalid: false,
            iscomissionvalid: false,
            isovertimevalid: false,
            isbonusesvalid: false,
            ismilitaryvalid: false,
            isothervalid: false,
            isdividendsvalid: false,
            employmentType: '',
            tabAvtive: 0,
            isotherJob: false,
            showEmploymentModal: false,
            showPreviousEmploymentModal: false,
            who: com.borrower,
            tabs: [
                {
                    tab: 'Primary',
                    value: this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
                }
            ],
            isEdit: false,
            hasborrowerTab: true,
            editIndex: -1,
            selfemployed: false,
            employername: '',
            positiontitle: '',
            yearsatlineofwork: '',
            monthsatlineofwork: '',
            income: {
                basemonthly: '',
                basehoursperweek: '',
                base: '',
                overtimemonthly: '',
                overtime: 0,
                bonusesmonthly: '',
                bonuses: 0,
                commissionsmonthly: '',
                commissions: 0,
                militarymonthly: '',
                military: 0,
                othermonthly: '',
                other: 0
            },
            previousoccupation: {
                employername: '',
                from: '',
                to: '',
                employeraddress: '',
                positiontitle: '',
                income: '',
                selfemployed: false,
                employedbyagent: false
            },
            employedbyagent: false,
            percentownership: '',
            incomeorlossperiod: '',
            incomeorloss: '',
            selfincomedoc: '',
        }
        this.refsfrom1 = React.createRef()
        this.refsto1 = React.createRef()
        this.refsfrom = []
        this.refsto = []
        if (this.props.application[this.state.who].previousoccupations !== null) {
            for (let i = 0; i < this.props.application[this.state.who].previousoccupations.length; i++) {
                this.refsfrom.push(React.createRef())
                this.refsto.push(React.createRef())
            }
        }

        this.crefsfrom = []
        if (this.props.application[this.state.who].otheroccupations !== null) {
            for (let i = 0; i < this.props.application[this.state.who].otheroccupations.length; i++) {
                this.crefsfrom.push(React.createRef())

            }
        }

        this.isValidated = this.isValidated.bind(this)
    }

    getIsNonQMCustomer = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/getaccountconfig', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
            }
        }).then(response => {
            if (response.status !== 200) {
                this.setState({ isNonQMCustomer: false })
                return
            }
            response.json().then(data => {
                this.setState({ isNonQMCustomer: data.isNonQMCustomer })
            })
        })
    }
    componentDidMount() {
        this.getIsNonQMCustomer()

        this.setState({ employmentType: getEmploymentType(this.props.application, this.state.who) })
        if (this.personalCoborrower()) {
            this.setState(prevState => ({
                tabs: [
                    ...prevState.tabs,
                    {
                        tab: 'Co-borrower',
                        value: this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname
                    }
                ]
            }))
        }
        this.loadExpenses()
        this.completion()
        if (this.props.application[this.state.who].income.hasdividends === null) {
            this.props.updateIncomeAtttribute(false, this.state.who, "hasdividends")
        }
        if (this.props.application[this.state.who].otheroccupations === null) {
            this.props.clearBorrowerOtherOccupations(this.state.who)
        }
        if (this.props.application[this.state.who].otherincome === null) {
            this.props.clearBorrowerAdditionalIncome(this.state.who)
        }

    }
    componentDidUpdate() {
        this.completion()
    }
    personalCoborrower = () => {
        return this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)

    }
    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length !== 0) {
            let totalCount = 5
            let fillNum = 5
            if (isEmpty(this.state.employmentType)) {
                fillNum = 4
            }
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (element.type !== 'button' && element.type !== 'fieldset' && element.type !== 'submit') {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem("progressValue", progressValue)
        }
    }
    loadExpenses = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/data/getexpences', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
                "X-Borrower": this.props.borrowerid,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log("Bad response, should not happen")
                    return
                }
                response.json().then(fees => {
                    let ex = new FutureExpenses()
                    ex.otherfinancing = fees.otherfinancing
                    ex.homeownersinsurance = fees.hazardinsurance
                    ex.realestatetaxes = fees.realestatetaxes
                    ex.hoa = fees.dues
                    ex.floodinsurance = fees.other
                    this.props.changeMainProperty(ex, "expenses")
                })
            }

        ).catch((err) => {

            this.setState({ loading: false })
            alert("Network error")
        })
    }
    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    aupdateMonthly = (who, verb) => {
        return (e) => {
            const event = {
                target: {

                    value: e
                }
            }
            com.touch()
            this.props.updateIncomeMonthly(event, who, verb)
        }
    }
    updateMonthly = (who, verb) => {
        return e => {
            com.touch()
            this.props.updateIncomeMonthly(e, who, verb)
        }
    }

    onBaseAmountValid = (val) => {
        this.setState({ isbasevalid: val })
    }
    onComissionsAmountValid = (val) => {
        this.setState({ iscomissionvalid: val })
    }
    onOvertimeAmountValid = (val) => {
        this.setState({ isovertimevalid: val })
    }
    onBonusesAmountValid = (val) => {
        this.setState({ isbonusesvalid: val })
    }
    onMilitaryAmountValid = (val) => {
        this.setState({ ismilitaryvalid: val })
    }
    onOtherAmountValid = (val) => {
        this.setState({ isothervalid: val })
    }
    onDividendsValid = (val) => {
        this.setState({ isdividendsvalid: val })

    }
    canShowDividends = () => {
        if (this.props.application[this.state.who].occupation.hasoccupation === null)
            return false

        if (this.props.application[this.state.who].occupation.hasoccupation !== "employed")
            return true

        if (this.state.isbasevalid && this.props.application[this.state.who].income.hasbonuses === false)
            return true

        if (this.state.isbasevalid && this.props.application[this.state.who].income.hasbonuses === true &&
            this.state.iscomissionvalid && this.state.isovertimevalid && this.state.isbonusesvalid
        )
            return true

        return false
    }

    PreviousEmploymentForm = () => {
        let achangePastOccupationDate = (event, who, verb) => {
            // console.log()
            com.touch()
            let from = dayjs(event).valueOf()
            let to = this.state.previousoccupation.to === '' ? 0 : dayjs(this.state.previousoccupation.to).valueOf()


            this.setState({ previousoccupation: { ...this.state.previousoccupation, [verb]: dayjs(dayjs(event).format('MM/DD/YYYY')) } })
            // this.props.changePastOccupationDate(dayjs(event).format('MM/DD/YYYY'), who, verb, 0)
        }
        let changePastOccupationDate = (who, verb) => {
            return (event) => {
                com.touch()
                let fixed = com.fixDateInput(event.target.value)
                let from = this.refsfrom1.current.value
                let to = this.refsto1.current.value
                let expr = /^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$/ig

                if (to && from && to.match(expr) && from.match(expr)) {
                    let t = new Date(to)
                    let f = new Date(from)
                    this.refsfrom1.current.setCustomValidity("")
                    this.refsto1.current.setCustomValidity("")
                    let old = new Date("01/01/1900")
                    let now = new Date()
                    if (f > t) {
                        this.refsfrom1.current.focus()
                        this.refsfrom1.current.setCustomValidity("Must be earlier than To")
                    }
                    if (t < old) {
                        this.refsfrom1.current.focus()
                        this.refsto1.current.setCustomValidity("Too old to be true")
                    }
                    if (f < old) {
                        this.refsfrom1.current.focus()
                        this.refsfrom1.current.setCustomValidity("Too old to be true")
                    }
                    if (t >= now) {
                        this.refsto1.current.focus()
                        this.refsto1.current.setCustomValidity("Can't be in the future")
                    }
                    if (f >= now) {
                        this.refsto1.current.focus()
                        this.refsfrom1.current.setCustomValidity("Can't be in the future")
                    }
                }
                this.setState({ previousoccupation: { ...this.state.previousoccupation, [verb]: fixed } })
                // return this.props.changePastOccupationDate(fixed, who, verb, index)
            }
        }
        let changePastOccupationCheck = (verb) => {
            return event => {
                com.touch()
                if (event.target.checked && verb === "selfemployed" && this.state.employmentType !== 'unemployed' && this.state.employmentType !== 'retired') {
                    this.setState({ showSelf: true, currentJob: false })
                }
                this.setState({ previousoccupation: { ...this.state.previousoccupation, [verb]: event.target.checked } })
            }
        }
        return (
            <div className="m-0 mb-3 ">
                <Row>
                    <Col className="p-0 pl-2">
                        <Form.Row >
                            <Col >
                                <Form.Group controlId="Employer1" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Previous employer</Form.Label>
                                    <Form.Control isValid={false}
                                        autocomplete='off'
                                        required
                                        name="employer"
                                        type="text"
                                        defaultValue={this.state.previousoccupation.employername}
                                        // onChange={changePastOccupationInfo(this.state.who, "employername")}
                                        onChange={(e) => { this.setState({ previousoccupation: { ...this.state.previousoccupation, employername: e.target.value } }) }}
                                    />

                                    <Form.Control.Feedback type="invalid" >
                                        Provide employer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs="auto"  >
                                <Form.Group controlId="from" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Dates from</Form.Label>
                                    <div>
                                        <DatePicker
                                            name="dateofbirth"
                                            style={{ width: '100%' }}

                                            required
                                            size="sm"
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            value={this.state.previousoccupation.from}
                                            onChange={

                                                (e) => achangePastOccupationDate(e, this.state.who, "from")

                                            }
                                        />
                                    </div>
                                    {/* <DateOfBirth
                                        isValid={false}
                                        //ref={this.refsfrom[index]}
                                        onRef={ref => { this.refsfrom1 = ref }}
                                        //reff={this.refsfrom[index]}
                                        required
                                        placeholder="MM/DD/YYYY"
                                        name="ocfrom"
                                        type="text"
                                        value={this.state.previousoccupation.from}
                                        onChange={changePastOccupationDate(this.state.who, "from")}
                                        negativefeedback="Provide valid start"
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    /> */}
                                </Form.Group>
                            </Col>
                            <Col xs="auto" >
                                <Form.Group controlId="to" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >To</Form.Label>
                                    <DatePicker
                                        name="dateofbirth"
                                        style={{ width: '100%' }}
                                        disabledDate={this.disabledDate}
                                        required
                                        size="sm"
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        value={this.state.previousoccupation.to}
                                        onChange={

                                            (e) => achangePastOccupationDate(e, this.state.who, "to")

                                        }
                                    />
                                    {/* <DateOfBirth isValid={false}
                                        required
                                        //ref={this.refsto[index]}
                                        onRef={ref => { this.refsto1 = ref }}
                                        reff={this.refsto}
                                        placeholder="MM/DD/YYYY"
                                        name="octo"
                                        type="text"
                                        value={this.state.previousoccupation.to}
                                        onChange={changePastOccupationDate(this.state.who, "to")}
                                        negativefeedback="Provide valid end"
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    /> */}
                                </Form.Group>
                            </Col>


                        </Form.Row >
                        <Form.Row className="mt-4">
                            <Col  >
                                <AddressAutocomplete id="EmployerAddr1" label="Address"
                                    name="employeraddr"

                                    defaultValue={this.state.previousoccupation.employeraddress}
                                    // onChange={changePastOccupationInfo(this.state.who, "employeraddress")}
                                    onChange={(e) => { this.setState({ previousoccupation: { ...this.state.previousoccupation, employeraddress: e.target.value } }) }}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    placeholder="Enter employer address"

                                    goodfeedback=""
                                    badfeedback="Please provide address."
                                />
                            </Col>
                            <Col >
                                <Autocomplete
                                    required
                                    id="PrevTitle"
                                    suggestions={titles}
                                    name="position"
                                    type="text"
                                    label="Position/title"
                                    valid=""
                                    invalid="Please enter position"
                                    value={this.state.previousoccupation.positiontitle}
                                    // onChange={changePastOccupationInfo(this.state.who, "positiontitle")}
                                    onChange={(e) => {


                                        this.setState({ previousoccupation: { ...this.state.previousoccupation, positiontitle: e.target.value } })
                                    }}
                                />

                            </Col>


                            <Col xs="auto">
                                <Form.Group controlId="income0" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Yearly income</Form.Label>
                                    <div>


                                        <Input type="number" prefix='$' min={0} value={this.state.previousoccupation.income} onChange={(e) => {
                                            if (e.target.value.length > 0) {
                                                this.setState({ previousoccupation: { ...this.state.previousoccupation, income: e.target.value } })
                                            } else {
                                                this.setState({ previousoccupation: { ...this.state.previousoccupation, income: 0 } })
                                            }

                                        }}></Input>
                                    </div>
                                    {/* <NumericalInput isValid={false}
                                        size="md"
                                        required
                                        name="income"
                                        defaultValue={this.state.previousoccupation.income}
                                        // onChange={changePastOccupationInfo(this.state.who, "income")}
                                        onChange={(e) => { this.setState({ previousoccupation: { ...this.state.previousoccupation, income: e.target.value } }) }}
                                        min={0}
                                    />

                                    
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide yearly income.
                                    </Form.Control.Feedback> */}
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row >
                            <Col xs="auto" className="text-left nowrap d-block " >
                                &nbsp;<br />
                                <Form.Group controlId="income" className="form-inline" >
                                    <Form.Check inline
                                        checked={this.state.previousoccupation.selfemployed}
                                        onChange={changePastOccupationCheck("selfemployed")}
                                        // onChange={(e) => {this.setState({previousoccupation: {...this.state.previousoccupation, selfemployed: e.target.checked}})}}
                                        key="selfemployed"
                                        type='checkbox' id='selfemployment' label='Self&nbsp;Employed' />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs="auto" className="text-left nowrap d-block mt-4">
                                <Form.Group controlId="pastemployedbyagent" className="text-left d-block text-nowrap " >
                                    <Form.Check type="checkbox" inline
                                        className="text-wrap"
                                        checked={this.state.previousoccupation.employedbyagent}
                                        key={Math.random()}
                                        onChange={changePastOccupationCheck("employedbyagent")}
                                        // onChange={(e) => {this.setState({previousoccupation: {...this.state.previousoccupation, employedbyagent: e.target.checked}})}}
                                        label="Check if employed by a family member,
                            property seller, real estate agent, or other
                            party to the transaction"></Form.Check>
                                </Form.Group>

                            </Col>
                        </Form.Row>
                    </Col>
                    {/* <Col xs="auto" as="div" className="px-0 text-right aligh-top">
                        <i hidden={index !== this.props.application[this.state.who].previousoccupations.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAdditionaOccupation(this.state.who)} ></i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAdditionalOccupation(this.state.who)} ></i>
                    </Col> */}
                </Row>
            </div>
        )
    }
    EmploymentForm = () => {
        let ondocs = e => {
            this.setState({ selfincomedoc: e.target.value })
        }
        let onOwner = e => {
            let check = e.target.value === "true"
            com.touch()
            this.setState({ selfemployed: check })
        }
        let key = null
        let IncomeType = (commissions) => {
            return (
                <Col md={6} xs={12} className="d-block mt-4 p-0">
                    <div className="px-0" style={{ width: "100%" }}>
                        <Form.Group controlId={"amount" + commissions} className="text-left" style={{

                            alignItems: 'baseline', width: '100%', justifyContent: 'space-between'

                        }}>
                            <Form.Label className="text-left text-nowrap employment-label" >
                                {com.capitalize(commissions)}{commissions === 'base' ? '*':''}</Form.Label>
                            <div style={{ width: "100%" }}>
                                <Space.Compact style={{ width: "96%" }}>
                                    <DollarInput value={this.state.income[commissions]} onChange={(e) => { this.setState({ income: { ...this.state.income, [commissions]: e } }) }} />
                                    <Select style={{ height: 44 }} defaultValue="monthly" value={this.state.income[commissions + "monthly"]}
                                        onChange={(e) => { this.setState({ income: { ...this.state.income, [commissions + "monthly"]: e } }) }}
                                    >

                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Select>
                                </Space.Compact>
                            </div>
                        </Form.Group>
                    </div>

                    <div>

                        {
                            (this.state.income[commissions + "monthly"] === "hourly") ?
                                <Form.Group controlId="basehoursperweek" className="text-left mt-4" style={{ width: '96%' }}>
                                    <Form.Label className="text-left text-nowrap" >Hours Per Week:</Form.Label>

                                    <Form.Control isValid={false} size="md"

                                        required
                                        min={1}
                                        max={80}
                                        step={1}
                                        defaultValue={this.state.income[commissions + "hoursperweek"]}
                                        onChange={(e) => { this.setState({ income: { ...this.state.income, [commissions + "hoursperweek"]: e.target.value } }) }}
                                    />

                                    <Form.Control.Feedback type="invalid" >
                                        Please provide overtime income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                : ""
                        }
                    </div>

                </Col>)
        }
        return (
            <div>
                <Form.Row >
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0 ">
                                <Form.Group controlId="CEmployer1" className="text-left input-groups" >
                                    <Form.Label className="text-left text-nowrap employment-label" >Employer*</Form.Label>
                                    <Form.Control isValid={false}
                                        size="md"
                                        required
                                        name="cemployer"
                                        placeholder='Enter your employer'
                                        type="text"
                                        defaultValue={this.state.employername}
                                        onChange={(e) => { this.setState({ employername: e.target.value }) }}


                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        Provide employer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row> </Col>
                </Form.Row >

                <Form.Row >
                    <Col>
                        <Row>
                            <Col className="ml-0 pl-0 employment-autocomplete mt-4" md={6} xs={12}>
                                <Autocomplete
                                    required
                                    id="cOtherTitle"
                                    suggestions={titles}
                                    name="position"
                                    type="text"
                                    label="Position/Title*"
                                    valid=""
                                    invalid="Please enter position"
                                    value={this.state.positiontitle}
                                    onChange={(e) => {


                                        this.setState({ positiontitle: e.target.value })
                                    }}
                                />
                            </Col>
                            <Col className="ml-0 pl-0 mt-4" md={3} xs={12} >
                                <Form.Group controlId="yearsatthiswork0" className="text-left" >
                                    <Form.Label className="employment-label">Years in this work* <Tips
                                        content={<>This is important for people who have been doing brief engagements with multiple companies while doing the same work professionally.</>}
                                        placement="top"
                                        title="Specify the number of years you have been in the profession"
                                    />
                                    </Form.Label>
                                    <div style={{ position: 'relative' }}>
                                        <Input suffix='years' style={{ height: 44 }} value={this.state.yearsatlineofwork} type="number" onChange={(e) => {
                                            let value = parseInt(e.target.value)
                                            if (value < 0) {
                                                value = 0
                                            }

                                            this.setState({ yearsatlineofwork: value })
                                        }}></Input>
                                    </div>
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter years at line of work.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col className="ml-0 pl-0 mt-4" md={3} xs={12}>
                                <Form.Group controlId="moatthiswork0" className="text-left" >
                                    <Form.Label style={{ width: '100%', height: '15px' }} className="text-left text-nowrap employment-label" >
                                    </Form.Label>
                                    <Input min={0}
                                        max={11} suffix='months' style={{ height: 44, marginTop: 2 }} value={this.state.monthsatlineofwork} type="number" onChange={(e) => {

                                            let value = parseInt(e.target.value)

                                            if (value < 0) {
                                                value = 0
                                            }
                                            if (value > 11) {
                                                value = 11
                                            }

                                            this.setState({ monthsatlineofwork: value })
                                        }}></Input>
                                    {/* <div style={{ opacity: 0 }}>
                                        <Form.Control isValid={false}
                                            size="md"
                                            ref={this.yearsatlineofwork}
                                            name="monthsatthiswork0"
                                            type="number"
                                            min={0}
                                            max={11}
                                            defaultValue={this.state.monthsatlineofwork}
                                            onChange={(e) => { this.setState({ monthsatlineofwork: e.target.value }) }}
                                        />
                                        <span className="income-after-unit">months</span>
                                    </div> */}
                                    <Form.Control.Feedback type="invalid" >
                                        Please enter months at line of work.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
                {
                    this.state.isotherJob && (
                        <Form.Row className="">
                            <Col as="div" className="align-middle"  >
                                <div className="mt-2 w-100 align-middle  text-wrap sectionquestion businessquestion">Are you self-employed or owner of this business?</div>
                                <div >

                                    <CheckGroup
                                        style={{
                                            width
                                                : '100%'
                                        }}
                                        data={[{
                                            value: "false",
                                            label: "No",
                                        },
                                        {
                                            value: "true",
                                            label: 'Yes',
                                        },
                                        ]}
                                        onChangeSelect={e =>

                                            this.setState({ selfemployed: e === "true" })
                                        }
                                        value={
                                            this.state.selfemployed ? 'true' : "false"
                                        }
                                        type='radio'
                                        direction="horizontal" />
                                </div>
                                {/* <Form.Group>
                                    <fieldset>
                                        <div className="d-flex mt-2">
                                            <div className="mr-3 ">
                                                <input required onChange={onOwner} value="false"
                                                    checked={false === this.state.selfemployed}
                                                    type="radio" id="selfemployednot" name="selfemployed"></input>
                                                <label className=" zeitro-radio divlink" htmlFor="selfemployednot">No</label></div>
                                            <div className="mr-3 ">
                                                <input required onChange={onOwner} value="true"
                                                    checked={true === this.state.selfemployed}
                                                    type="radio" id="selfemployed" name="selfemployed"></input>
                                                <label className="zeitro-radio  divlink" htmlFor="selfemployed">Yes</label></div>
                                        </div>
                                    </fieldset>
                                    <Form.Control.Feedback type="invalid" >
                                        Please specify if you are self-employed
                                    </Form.Control.Feedback>
                                </Form.Group> */}
                            </Col>
                        </Form.Row >
                    )
                }
                {this.state.selfemployed === false ?
                    <div >
                        <Row >
                            <Col className=" p-0" >
                                <Row>
                                    {IncomeType("base")}
                                    {IncomeType("commissions")}
                                </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col className=" p-0" >
                                <Row>
                                    {IncomeType("overtime")}
                                    {IncomeType("bonuses")}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className=" p-0" >
                                <Row>
                                    {IncomeType("military")}
                                    {IncomeType("other")}
                                </Row>
                            </Col>
                        </Row>

                    </div> : ""}

                {this.state.selfemployed === true ?
                    <>
                        <Form.Row className="p-0">
                            <Col className="mr-3 ml-0 mt-4" md={3} xs={12}>

                                <Form.Group controlId="share" className="text-left" style={{ width: "96%" }}>
                                    <Form.Label className="text-left text-nowrap" > Ownership share
                                    </Form.Label>
                                    <div>
                                        <Input suffix='%' onChange={(e) => {
                                            if (e.target.value > 100) {
                                                this.setState({ percentownership: 100 })
                                            } else {
                                                this.setState({ percentownership: e.target.value.replaceAll('.', '') })
                                            }

                                        }} pattern="^[0-9.]+$" value={this.state.percentownership}></Input>
                                    </div>
                                    {/* <InputGroup size="md" >

                                        <Form.Control isValid={false}
                                            size="md"
                                            required
                                            name="share"
                                            type="text"
                                            pattern="^[0-9.]+$"
                                            defaultValue={this.state.percentownership}
                                            onChange={(e) => { this.setState({ percentownership: e.target.value }) }}

                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text className="pl-3" size="md">%</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup> */}
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide phone number or N/A.1
                                    </Form.Control.Feedback>
                                </Form.Group>


                            </Col>
                            <Col className="mr-3 ml-0 mt-4" md={3} xs={12}>
                                <Form.Group controlId="firsttime" className="text-left mr-3" style={{ width: "100%" }}>
                                    <Form.Label>Income or Loss</Form.Label>
                                    <div>
                                        <Select style={{ width: "96%", height: 44 }} value={this.state.incomeorlossperiod} onChange={(e) => { this.setState({ incomeorlossperiod: e }) }}>
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Yearly</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col className="mr-3 ml-0 mt-4" md={3} xs={12}>
                                <Form.Group controlId="dividend" className="text-left">
                                    <Form.Label className="text-left text-nowrap" >Amount</Form.Label>
                                    <div className="InputWithSignWrap">
                                        <div className="InputWithSign-prefix">$</div>
                                        <Input
                                            className="InputWithSign-input"
                                            value={this.state.incomeorloss}
                                            onChange={(e) => { this.setState({ incomeorloss: e.target.value }) }}
                                        />
                                    </div>

                                    <Form.Control.Feedback type="invalid" >
                                        Please provide amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row >

                        {isNaN(parseInt(this.state.percentownership)) ? "" :
                            parseInt(this.state.percentownership) >= 25 ?
                                <div>
                                    <Form.Row className="p-0 pt-3">
                                        <Col className="ml-0 mt-2 w-100 align-middle  text-wrap sectionquestion">
                                            How do you file your taxes?
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-2 ">
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "schedulec"} onChange={ondocs} value="schedulec"
                                                name={"taxes" + key} id={"schedulec" + key} />
                                            <label id="scheduleclabel" className="zeitro-radio divlink" htmlFor={"schedulec" + key}>Schedule C</label>
                                        </Col>
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "schedulef"} onChange={ondocs} value="schedulef"
                                                name={"taxes" + key} id={"schedulef" + key} />
                                            <label id="scheduleflabel" className="zeitro-radio divlink" htmlFor={"schedulef" + key}>Schedule F</label>
                                        </Col>
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "f1065"} onChange={ondocs} value="f1065"
                                                name={"taxes" + key} id={"f1065" + key} />
                                            <label id="f1065label" className="zeitro-radio divlink" htmlFor={"f1065" + key}>1065 Partnership Return</label>
                                        </Col>
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "f1120S"} onChange={ondocs} value="f1120S"
                                                name={"taxes" + key} id={"f1120S" + key} />
                                            <label id="f1120Slabel" className="zeitro-radio divlink" htmlFor={"f1120S" + key}>1120S for S Corp</label>
                                        </Col>
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "f1120"} onChange={ondocs} value="f1120"
                                                name={"taxes" + key} id={"f1120" + key} />
                                            <label id="f1120label" className="zeitro-radio divlink" htmlFor={"f1120" + key}>1120 for C Corp</label>
                                        </Col>
                                        <Col className=""></Col>
                                    </Form.Row>

                                </div>
                                :
                                <div>
                                    <Form.Row>
                                        <Col className="mt-2 w-100 align-middle  text-wrap sectionquestion">
                                            How do you document your income?
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-2 ml-0 ">
                                        <Col className="mr-2 p-0" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "w2paystub"} onChange={ondocs} value="w2paystub"
                                                name={"taxes" + key} id={"w2paystub" + key} />
                                            <label id="w2paystublabel" className="zeitro-radio divlink" htmlFor={"w2paystub" + key}>W2/Paystubs</label>
                                        </Col>
                                        <Col className="mr-2" xs="auto">
                                            <input type="radio" required checked={this.state.selfincomedoc === "schedulek1"} onChange={ondocs} value="schedulek1"
                                                name={"taxes" + key} id={"schedulek1" + key} />
                                            <label id="schedulek1label" className="zeitro-radio divlink" htmlFor={"schedulek1" + key}>Schedule K1</label>
                                        </Col>
                                        <Col className=""></Col>
                                    </Form.Row>
                                </div>
                        }
                        <Form.Row className="mt-3">
                            <Col>
                                <img src="/images/exclamationMark.png" style={{ width: "14px", marginRight: "10px" }} />
                                <a className="text-wrap" style={{ fontSize: "14px" }}>Please be aware that you need 2 years of history of self employed income for it to be considered for conforming loans.</a>
                            </Col>
                        </Form.Row>
                    </>
                    : ""}

                <Form.Row>
                    <Col className=" px-1 mt-2">
                        <Form.Group controlId="employedbyagent" className="text-left " >
                            <Checkbox onChange={(e) => {

                                this.setState({ employedbyagent: e.target.checked })


                            }} checked={this.state.employedbyagent}>
                                Check if employed by a family member,
                                property seller, real estate agent, or other
                                party to the transaction

                            </Checkbox>
                            {/* <Form.Check type="checkbox" className="mx-3 px-0 text-wrap"
                                checked={this.state.employedbyagent}
                                key={Math.random()}
                                onChange={(e) => { this.setState({ employedbyagent: e.target.checked }) }}
                                label="Check if employed by a family member,
                                            property seller, real estate agent, or other
                                            party to the transaction"></Form.Check> */}
                        </Form.Group>

                    </Col>
                </Form.Row>


            </div>
        )
    }

    canShowOtherIncome = () => {
        if (!this.canShowDividends())
            return false
        if (null === this.props.application[this.state.who].income.hasdividends)
            return false
        if (false === this.props.application[this.state.who].income.hasdividends)
            return true

        if (false === this.props.application[this.state.who].income.hasdividends)
            return true

        if (true === this.props.application[this.state.who].income.hasdividends &&
            this.state.isdividendsvalid)
            return true

        return false
    }

    enoughCurrentJob = () => {
        let years = this.props.application[this.state.who].occupation.yearsatlineofwork
        if (years.length === 0)
            return false
        return parseInt(years) >= 2
    }
    processOtherIncome = (val, index) => {
        if (null === val)
            return ""
        let desc = val.description
        let amount = val.monthlyamount
        let key = val.key
        return this.extraIncome(index, desc, amount, key)
    }

    hideOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }
    showOccupation = () => {
        this.props.updateBorrowerHasOccupation({ target: { checked: true } })
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
        let employment = this.props.application[this.state.who].occupation
        let fieldOccupation = employment.employername === '' || employment.positiontitle === '' || employment.yearsatlineofwork === '' || employment.income.base === ''
        setTimeout(() => {
            if (fieldOccupation) {
                this.editEmployment(0)
            }
        }, 200)
    }
    handleSelfCloseOff = () => {
        this.props.changeCurrentOccupation('employed', this.state.who, 'hasoccupation')
        this.setState({ employmentType: 'employed' })
        this.props.changeCurrentOccupationCheck({ target: { checked: true } }, this.state.who, "selfemployed")

        this.setState({ showSelf: false })
        let employment = this.props.application[this.state.who].occupation
        let fieldOccupation = employment.employername === '' || employment.positiontitle === '' || employment.yearsatlineofwork === '' || employment.income.base === ''
        setTimeout(() => {
            if (fieldOccupation) {
                this.editEmployment(0)
            }
        }, 200)
    }
    addOtherSources = (e) => {
        com.touch()
        if (e.target.value !== "true") {
            this.props.clearBorrowerAdditionalIncome(this.state.who)
        } else {

            this.props.addBorrowerAdditionalIncome(0, this.state.who)
        }
    }
    addMoreSources = () => {
        com.touch()
        this.props.addBorrowerAdditionalIncome(0, this.state.who)

    }
    addOtherSources = (e) => {
        com.touch()
        if (e !== "true") {
            this.props.clearBorrowerAdditionalIncome(this.state.who)
        } else {

            this.props.addBorrowerAdditionalIncome(0, this.state.who)
        }
    }
    fieldOccupation = () => {
        let previous = this.props.application.coborrower.previousoccupations[0]
        return previous.employername === '' || previous.from === '' || previous.to === '' || previous.positiontitle === '' || previous.income === ''
    }
    handleSubmit = e => {
        if (this.props.application.product.mortgageapplied === "nonqm") {
            if (!this.props.application[this.state.who].nonqm.nonqmincomeamount) {
                alert("Please provide your non-QM income amount.")
                return false
            }
        } else {
            if (this.props.application[this.state.who].occupation.hasoccupation === null) {
                alert("Please select your occupation.")
                return false
            }
            if (this.state.employmentType === null || this.state.employmentType === '') {
                return false
            }
        }


        if (this.state.tabAvtive === 0 && this.personalCoborrower()) {
            this.changeTab(1)
            return false
        }
        return true
    }
    handlePrevious = () => {
        if (this.state.tabAvtive === 1) {
            this.changeTab(0)
            return false
        }
        return true
    }
    changeTab = (tab) => {
        this.setState({ tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower }, () => { this.setState({ employmentType: getEmploymentType(this.props.application, this.state.who) }); this.completion(); eventBus.emit('changeProgressValue') })
    }
    editEmployment = (index) => {
        let isotherJob = index !== 0

        let employment = ''
        if (isotherJob) {
            employment = this.props.application[this.state.who].otheroccupations[index - 1]
        } else {
            employment = this.props.application[this.state.who].occupation
        }
        const {
            selfemployed,
            employername,
            positiontitle,
            yearsatlineofwork,
            monthsatlineofwork,
            income: {
                basemonthly,
                basehoursperweek,
                base,
                overtimemonthly,
                overtime,
                bonusesmonthly,
                bonuses,
                commissionsmonthly,
                commissions,
                militarymonthly,
                military,
                othermonthly,
                other
            },
            employedbyagent,
            percentownership,
            incomeorlossperiod,
            incomeorloss,
            selfincomedoc
        } = employment
        this.setState({
            editIndex: index,
            validated: false,
            isEdit: true,
            isotherJob,
            showEmploymentModal: true,
            selfemployed,
            employername,
            positiontitle,
            yearsatlineofwork,
            monthsatlineofwork,
            income: {
                basemonthly,
                basehoursperweek,
                base,
                overtimemonthly,
                overtime: overtime === '' ? 0 : overtime,
                bonusesmonthly,
                bonuses: bonuses === '' ? 0 : bonuses,
                commissionsmonthly,
                commissions: commissions === '' ? 0 : commissions,
                militarymonthly,
                military: military === '' ? 0 : military,
                othermonthly,
                other: other === '' ? 0 : other
            },
            employedbyagent,
            percentownership,
            incomeorlossperiod,
            incomeorloss,
            selfincomedoc
        })
    }
    extraIncome = (index, desc, amount, key) => {
        index = parseInt(index)

        let removePreviousOccupation = (event) => {
            this.props.removeBorrowerAdditionalIncome(index, this.state.who)
        }

        let aupdateBorrowerAdditionalIncome = (who, verb) => {
            return (event) => {
                com.touch()

                this.props.updateBorrowerAdditionalIncome({ target: { value: event } }, who, verb, index)
            }
        }
        let updateBorrowerAdditionalIncome = (who, verb) => {
            return (event) => {
                com.touch()

                this.props.updateBorrowerAdditionalIncome(event, who, verb, index)
            }
        }
        let onSelectAdditionalIncome = e => {
            com.touch()

            this.props.updateBorrowerAdditionalIncome({ target: { value: e } }, this.state.who, "incometype", index)
            let description = ""
            com.othersouresofincome.forEach(x => {
                if (x[0] === e) {
                    description = x[1]
                }
            })
            if (e === "Other")
                description = ""

            this.props.updateBorrowerAdditionalIncome({ target: { value: description } }, this.state.who, "description", index)
        }



        let updateMonthly = (who, verb) => {
            return (event) => {
                com.touch()
                return this.props.updateBorrowerAdditionalIncome(event, who, verb, index)
            }
        }
        let showDescription = () => {
            let tp = this.props.application[this.state.who].income.otherincome[index].incometype
            let description = ""
            com.othersouresofincome.forEach(x => {
                if (x[0] === tp) {
                    description = x[2]
                }
            })
            if (tp === "Other" || description === "")
                return "Description:"
            return tooltip("Description:", description, "auto", "", "")
        }

        return (
            <div className="p-0 mx-0 my-2" key={key}>
                <Row className="mx-0 pt-1 mb-2">
                    <Col >
                        <Form.Row >
                            <Col className="" xs="auto">

                                <Form.Group controlId="firsttime" className="text-left" >
                                    <Form.Label>Period:</Form.Label>
                                    <Form.Control as="select"
                                        //defaultValue={this.props.firsttime}
                                        style={{ width: "120px" }}
                                        type="text"
                                        size="md"
                                        defaultValue={this.props.application[this.state.who].income.otherincome[index].period}
                                        onChange={updateMonthly(this.state.who, "period")}
                                    >
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="">
                                <Form.Group controlId={"description" + index} className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >{showDescription()}</Form.Label>
                                    <InputGroup>
                                        <Form.Control isValid={false} size="md"
                                            readOnly={"Other" !== this.props.application[this.state.who].income.otherincome[index].incometype}
                                            required
                                            pattern="^.+$"
                                            name={"description" + index}
                                            type="text"
                                            value={this.props.application[this.state.who].income.otherincome[index].description}
                                            onChange={updateBorrowerAdditionalIncome(this.state.who, "description")}

                                        />
                                        <DropdownButton
                                            as={InputGroup.Append}
                                            variant="dropdown"
                                            className="incdropdown"
                                            title=""
                                            id="dropdown-income"
                                            size="md"
                                            onSelect={onSelectAdditionalIncome}
                                        >   {com.othersouresofincome.map(st =>
                                        (
                                            <Dropdown.Item key={st[0]} eventKey={st[0]} href="#">{st[1]}</Dropdown.Item>
                                        )
                                        )}
                                        </DropdownButton>
                                        <Form.Control.Feedback type="invalid" >
                                            Please describe the source of income.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" style={{ width: '10.5em' }}>
                                <Form.Group controlId="overtime" className="text-left" >
                                    <Form.Label className="text-left text-nowrap" >Amount</Form.Label>
                                    <div>
                                        <DollarInput size="large" onChange={aupdateBorrowerAdditionalIncome(this.state.who, "amount")} value={this.props.application[this.state.who].income.otherincome[index].amount} />
                                    </div>


                                    <Form.Control.Feedback type="invalid" >
                                        Please provide  income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Form.Row >
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top px-1">
                        <i hidden={index !== this.props.application[this.state.who].income.otherincome.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={this.addMoreSources} ></i>
                        <i hidden={index === this.props.application[this.state.who].income.otherincome.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removePreviousOccupation} ></i>
                    </Col>
                </Row>
            </div>
        )
    }

    render = () => {
        let employment = this.props.application[this.state.who].occupation
        let fieldOccupation = employment.employername === '' || employment.positiontitle === '' || employment.yearsatlineofwork === '' || employment.income.base === ''
        let changeEmploymentTypes = (e, who) => {
            this.setState({ employmentType: e })
            com.touch()
            switch (e) {
                case "employed":
                    this.props.changeCurrentOccupation(e, who, "hasoccupation")
                    this.props.changeCurrentOccupationCheck({ target: { checked: true } }, who, "selfemployed")
                    setTimeout(() => {
                        if (fieldOccupation) {
                            this.editEmployment(0)
                        }
                    }, 200)
                    break
                case "selfemployed":
                    this.props.changeCurrentOccupation("employed", who, "hasoccupation")
                    this.props.changeCurrentOccupationCheck({ target: { checked: false } }, who, "selfemployed")
                    this.setState({ showSelf: true })
                    break
                case "unemployed":
                case "retired":
                    this.props.changeCurrentOccupationCheck({ target: { checked: true } }, who, "selfemployed")
                    this.props.changeCurrentOccupation(e, who, "hasoccupation")
                    break
                default:
                    break
            }
        }
        let changePreviousJob = (e) => {
            if (e === "true") {
                addAdditionaOccupation(e, this.state.who)
                setTimeout(() => {
                    let previous = this.props.application[this.state.who].previousoccupations[0]
                    let fieldOccupation = previous.employername === '' || previous.from === '' || previous.to === '' || previous.positiontitle === '' || previous.income === ''
                    if (fieldOccupation) {
                        editPreviousEmployment(0)
                    }
                }, 200)
            } else {
                clearAdditionalOccupations(e, this.state.who)
            }
        }
        let addAdditionaOccupation = (event, who) => {
            com.touch()
            let reffrom = React.createRef()
            let refto = React.createRef()
            this.refsfrom.push(reffrom)
            this.refsto.push(refto)
            return this.props.addAdditionaOccupation(event, who)

        }
        let clearAdditionalOccupations = (event, who) => {
            com.touch()
            return this.props.clearAdditionalOccupations(event, who)
        }

        let changeHasDividends = (who, verb) => {
            return (event) => {
                com.touch()

                let val = event === "true"
                this.props.updateIncomeAtttribute(val, who, verb)
            }
        }
        let updateMonthlyIncome = (who, verb) => {
            return e => {
                com.touch()
                this.props.updateIncomeAtttribute(e, who, verb)
            }
        }
        let showWarning = () => {
            return <div className="mt-4" style={{ backgroundColor: "rgba(255, 170, 41, 0.3)", padding: "10px", borderRadius: "4px" }}><Row>
                <Col md={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src="/images/warningSignYellow.png" style={{ width: "35px" }} /></Col>
                <Col md={11} style={{ whiteSpace: "pre-wrap", fontSize: "14px" }}>Attention! You have an insufficient job history to qualify for an agency loan. While we might still be able to find a Non-QM loan for you, it will likely have a higher interest rate and/or closing costs.</Col>
            </Row>
            </div>
        }
        let formatAmount = (value) => {
            return value ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '--'
        }
        let formatTaxes = (value) => {
            let obj = {
                schedulec: 'Schedule C',
                schedulef: 'Schedule F',
                f1065: '1065 Partnership Return',
                f1120S: '1120S for S Corp',
                f1120: '1120 for C Corp',
                w2paystub: 'W2/Paystubs',
                schedulek1: 'Schedule K1'
            }
            return obj[value]
        }
        let getEmploymentList = () => {
            let otheroccupations = this.props.application[this.state.who].otheroccupations && this.props.application[this.state.who].otheroccupations.length > 0 ? this.props.application[this.state.who].otheroccupations : []
            let occupations = [...[this.props.application[this.state.who].occupation], ...otheroccupations]

            return occupations.map(obj => [
                {
                    label: 'Employer',
                    value: obj.employername,
                    show: true
                },
                {
                    label: 'Position/Title',
                    value: obj.positiontitle,
                    show: true
                },
                {
                    label: 'Years',
                    value: `${obj.yearsatlineofwork !== '' ? obj.yearsatlineofwork == 0 ? '' : obj.monthsatlineofwork == '' ? obj.yearsatlineofwork + ' years ' : obj.yearsatlineofwork + ' years and' : ''} ${obj.monthsatlineofwork && obj.monthsatlineofwork !== '' ? ` ${obj.monthsatlineofwork} months` : ''}`,

                    span: 12,
                    show: true
                },
                {
                    label: 'Base',
                    value: formatAmount(obj.income.base),
                    show: !obj.selfemployed,
                    monthly: obj.income.basemonthly
                },
                {
                    label: 'Commission',
                    value: formatAmount(obj.income.commissions),
                    show: !obj.selfemployed,
                    monthly: obj.income.commissionsmonthly
                },
                {
                    label: 'Overtime',
                    value: formatAmount(obj.income.overtime),
                    show: !obj.selfemployed,
                    monthly: obj.income.overtimemonthly
                },
                {
                    label: 'Bonus',
                    value: formatAmount(obj.income.bonuses),
                    show: !obj.selfemployed,
                    monthly: obj.income.bonusesmonthly
                },
                {
                    label: 'Military',
                    value: formatAmount(obj.income.military),
                    show: !obj.selfemployed,
                    monthly: obj.income.militarymonthly
                },
                {
                    label: 'Other',
                    value: formatAmount(obj.income.other),
                    show: !obj.selfemployed,
                    monthly: obj.income.othermonthly
                },
                {
                    label: 'Ownership share',
                    value: obj.percentownership !== '' ? obj.percentownership + '%' : '',
                    show: obj.selfemployed
                },
                {
                    label: 'Income or Loss',
                    value: obj.incomeorloss ? `${formatAmount(obj.incomeorloss)}(${obj.incomeorlossperiod})` : '',
                    show: obj.selfemployed
                },
                {
                    label: parseInt(obj.percentownership) >= 25 ? 'Taxes' : 'Document income',
                    value: formatTaxes(obj.selfincomedoc),
                    show: obj.selfemployed
                }
            ])
        }
        let editEmployment = (index) => {
            let isotherJob = index !== 0

            let employment = ''
            if (isotherJob) {
                employment = this.props.application[this.state.who].otheroccupations[index - 1]
            } else {
                employment = this.props.application[this.state.who].occupation
            }


            const {
                selfemployed,
                employername,
                positiontitle,
                yearsatlineofwork,
                monthsatlineofwork,
                income: {
                    basemonthly,
                    basehoursperweek,
                    base,
                    overtimemonthly,
                    overtime,
                    bonusesmonthly,
                    bonuses,
                    commissionsmonthly,
                    commissions,
                    militarymonthly,
                    military,
                    othermonthly,
                    other
                },
                employedbyagent,
                percentownership,
                incomeorlossperiod,
                incomeorloss,
                selfincomedoc
            } = employment
            this.setState({
                employedbyagent,
                editIndex: index,
                validated: false,
                isEdit: true,
                isotherJob,
                showEmploymentModal: true,
                selfemployed,
                employername,
                positiontitle,
                yearsatlineofwork,
                monthsatlineofwork,
                income: {
                    basemonthly,
                    basehoursperweek,
                    base,
                    overtimemonthly,
                    overtime: overtime === '' ? 0 : overtime,
                    bonusesmonthly,
                    bonuses: bonuses === '' ? 0 : bonuses,
                    commissionsmonthly,
                    commissions: commissions === '' ? 0 : commissions,
                    militarymonthly,
                    military: military === '' ? 0 : military,
                    othermonthly,
                    other: other === '' ? 0 : other
                },


                percentownership,
                incomeorlossperiod,
                incomeorloss,
                selfincomedoc
            })
        }
        let deleteEmployment = (index) => {
            return this.props.removeBorrowerOtherOccupation(this.state.who, index - 1)
        }
        let addCurrentOccupation = () => {
            let reffrom = React.createRef()
            this.crefsfrom.push(reffrom)
            return this.props.addBorrowerOtherOccupation(this.state.who)
        }

        let addEmployment = () => {
            this.setState({
                editIndex: -1,
                validated: false,
                isEdit: false,
                isotherJob: true,
                showEmploymentModal: true,
                selfemployed: false,
                employername: '',
                positiontitle: '',
                yearsatlineofwork: '',
                monthsatlineofwork: '',
                income: {
                    basemonthly: '',
                    basehoursperweek: '',
                    base: '',
                    overtimemonthly: '',
                    overtime: 0,
                    bonusesmonthly: '',
                    bonuses: 0,
                    commissionsmonthly: '',
                    commissions: 0,
                    militarymonthly: '',
                    military: 0,
                    othermonthly: '',
                    other: 0
                },
                employedbyagent: false,
                percentownership: '',
                incomeorlossperiod: '',
                incomeorloss: '',
                selfincomedoc: ''
            })
        }
        let previousEmployed = () => {
            let employed = this.props.application[this.state.who].previousoccupations
            return employed.map(obj => [
                {
                    label: 'Previous Employer',
                    value: obj.employername ? obj.employername : '--',
                },
                {
                    label: 'Dates',
                    value: `${obj.from}--${obj.to}`,
                },
                {
                    label: 'Address',
                    value: obj.employeraddress ? obj.employeraddress : '--',
                    span: 12
                },
                {
                    label: 'Position/Title',
                    value: obj.positiontitle ? obj.positiontitle : '--',
                },
                {
                    label: 'Yearly Income',
                    value: formatAmount(obj.income),
                }
            ])
        }
        let addPreviousEmployment = () => {
            this.setState({
                isEdit: false,
                validated: false,
                showPreviousEmploymentModal: true,
                previousoccupation: {
                    employername: '',
                    from: '',
                    to: '',
                    employeraddress: '',
                    positiontitle: '',
                    income: '',
                    selfemployed: false,
                    employedbyagent: false
                }
            })
        }
        let deletePreviousEmployment = (event, index) => {
            this.props.removeAdditionalOccupation(event, this.state.who, index)
        }
        let editPreviousEmployment = (index) => {
            let employment = this.props.application[this.state.who].previousoccupations[index]
            const {
                employername,
                from,
                to,
                employeraddress,
                positiontitle,
                income,
                selfemployed,
                employedbyagent
            } = employment
            this.setState({
                isEdit: true,
                validated: false,
                editIndex: index,
                showPreviousEmploymentModal: true,
                previousoccupation: {
                    employername,
                    from: from == '' ? null : dayjs(from),
                    to: to == '' ? null : dayjs(to),
                    employeraddress,
                    positiontitle,
                    income,
                    selfemployed,
                    employedbyagent
                }
            })
        }
        let employmentModal = () => {
            let creditCheckContinue = () => {
                let index = 0
                const {
                    selfemployed,
                    employername,
                    positiontitle,
                    yearsatlineofwork,
                    monthsatlineofwork,
                    percentownership,
                    selfincomedoc,
                    income,
                    incomeorlossperiod,
                    incomeorloss,
                } = this.state

                const employmentData = {
                    selfemployed,
                    employername,
                    positiontitle,
                    yearsatlineofwork,
                    monthsatlineofwork,
                    percentownership,
                    selfincomedoc,
                    incomeorlossperiod,
                    incomeorloss,
                }
                const incomeData = {
                    basemonthly: income.basemonthly,
                    basehoursperweek: income.basehoursperweek,
                    base: income.base,
                    overtimemonthly: income.overtimemonthly,
                    overtime: income.overtime,
                    bonusesmonthly: income.bonusesmonthly,
                    bonuses: income.bonuses,
                    commissionsmonthly: income.commissionsmonthly,
                    commissions: income.commissions,
                    militarymonthly: income.militarymonthly,
                    military: income.military,
                    othermonthly: income.othermonthly,
                    other: income.other
                }
                if (this.state.isEdit) {
                    if (this.state.editIndex !== 0) {
                        index = this.state.editIndex - 1
                    }
                } else {
                    addCurrentOccupation()
                    index = this.props.application[this.state.who].otheroccupations ? this.props.application[this.state.who].otheroccupations.length : 0
                }
                if (this.state.editIndex !== 0) {
                    Object.entries(employmentData).forEach(([key, value]) => {
                        this.props.changeBorrowerOtherOccupationValue(value, index, this.state.who, key)
                    })
                    Object.entries(incomeData).forEach(([key, value]) => {
                        this.props.changeBorrowerOtherOccupationIncome(value, index, this.state.who, key)
                    })
                    this.props.changeBorrowerOtherOccupationValue(this.state.employedbyagent, index, this.state.who, "employedbyagent")
                } else {
                    Object.entries(employmentData).forEach(([key, value]) => {
                        this.props.changeOccupationInfo({ target: { value } }, this.state.who, key)

                    })
                    Object.entries(incomeData).forEach(([key, value]) => {
                        this.props.updateIncomeMonthly({ target: { value } }, this.state.who, key)
                    })
                    this.props.changeOccupationCheck({ target: { checked: this.state.employedbyagent } }, this.state.who, "employedbyagent")
                }
                this.setState({ showEmploymentModal: false })
            }
            let submitEmployment = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.form.current.reportValidity() === false) {
                    this.setState({ validated: true })
                    return false
                }
                creditCheckContinue()
            }
            let employment = this.props.application[this.state.who].occupation
            let fieldOccupation = employment.employername === '' || employment.positiontitle === '' || employment.yearsatlineofwork === '' || employment.income.base === ''
            let closeModal = () => {
                this.setState({ showEmploymentModal: false })
                if (this.state.editIndex === 0 && fieldOccupation) {
                    changeEmploymentTypes("unemployed", this.state.who)
                }
            }
            return (
                <Modal className="employment-modal" size="lg" show={this.state.showEmploymentModal} onHide={closeModal}>
                    <Modal.Header closeButton >
                        <Modal.Title>Add employment</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitEmployment} validated={this.state.validated}  >
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 16px 0' }}>* Indicates required</div>

                            {this.EmploymentForm()}

                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }
        let PreviousEmploymentModal = (event) => {
            let saveContinue = () => {
                let index = 0
                if (this.state.isEdit) {
                    index = this.state.editIndex
                } else {
                    this.props.addAdditionaOccupation(0, this.state.who)
                    index = this.props.application[this.state.who].previousoccupations.length
                }
                const {
                    employername,
                    employeraddress,
                    positiontitle,
                    income,
                    from,
                    to,
                    selfemployed,
                    employedbyagent
                } = this.state.previousoccupation
                const employmentData = {
                    employername,
                    employeraddress,
                    positiontitle,
                    income,
                }
                Object.entries(employmentData).forEach(([key, value]) => {
                    this.props.changePastOccupationInfo({ target: { value } }, this.state.who, key, index)
                })
                this.props.changePastOccupationDate(dayjs(from).format('MM/DD/YYYY'), this.state.who, "from", index)
                this.props.changePastOccupationDate(dayjs(to

                ).format('MM/DD/YYYY'), this.state.who, "to", index)
                this.props.changePastOccupationCheck({ target: { checked: selfemployed } }, this.state.who, "selfemployed", index)
                this.props.changePastOccupationCheck({ target: { checked: employedbyagent } }, this.state.who, "employedbyagent", index)
                this.setState({ showPreviousEmploymentModal: false })
            }
            let submitEmployment = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.form.current.reportValidity() === false) {
                    this.setState({ validated: true })
                    return false
                }
                saveContinue()
            }
            let closeModal = () => {
                this.setState({ showPreviousEmploymentModal: false })
                let previous = this.props.application[this.state.who].previousoccupations[0]
                let fieldOccupation = previous.employername === '' || previous.from === '' || previous.to === '' || previous.positiontitle === '' || previous.income === ''
                if (this.state.editIndex === 0 && fieldOccupation) {
                    clearAdditionalOccupations('false', this.state.who)
                }
            }
            return (
                <Modal className="employment-modal" size="lg" show={this.state.showPreviousEmploymentModal} onHide={closeModal} >
                    <Modal.Header closeButton >
                        <Modal.Title>Add employment</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitEmployment} validated={this.state.validated} >
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 16px 0' }}>* Indicates required</div>
                            {this.PreviousEmploymentForm()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }

        let displayConventional = () => {
            if (this.props.application.product.mortgageapplied === "nonqm") {
                return ""
            }
            return (
                <div>
                    <div className="pt-4">
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Are you currently employed or/and self-employed?
                            </Form.Label>
                            <CheckGroup
                                data={[{
                                    value: 'employed',
                                    label: 'Employed',
                                },
                                {
                                    value: 'selfemployed',
                                    label: 'Self-employed',
                                },
                                {
                                    value: 'unemployed',
                                    label: 'Unemployed',
                                },
                                {
                                    value: 'retired',
                                    label: 'Retired',
                                }
                                ]}
                                onChangeSelect={e => changeEmploymentTypes(e, this.state.who)}
                                value={this.state.employmentType}
                                type='radio'
                                direction="horizontal"
                                required={true} />
                        </Form.Group>
                    </div>
                    {this.props.application[this.state.who].occupation.hasoccupation === "employed" && (
                        getEmploymentList().map((employment, index) => (
                            <div className="employment-list mt-2" key={index}>
                                <div className="employment-item">
                                    <div className="employment-top">
                                        <div className="employment-top-l">
                                            <div className="employment-icon"><img src="/images/employmentIcon.png" /></div>
                                            <span className="employment-name" >Employment {index + 1}</span>
                                        </div>
                                        <div className="employment-top-r">
                                            <div className="employment-edit" onClick={() => editEmployment(index)}><img src="/images/employmentEdit.svg" /></div>
                                            {index !== 0 && <div className="employment-delete" onClick={() => deleteEmployment(index)}><img src="/images/employmentDelete.svg" /></div>}
                                        </div>
                                    </div>
                                    <Row className="employment-form mb-2">
                                        {employment.map((form, formIndex) => (
                                            form.show && (
                                                <Col className="employment-form-item" md={form.span ? form.span : 6} key={formIndex}>
                                                    <div className="employment-form-label">{form.label}:</div>
                                                    <div style={form.label === 'Employer/Business Name' ? { marginLeft: 70 } : {}} className="employment-form-value">{form.value} <span style={{ marginLeft: 10 }}>{form.monthly === "yearly" ? "/year" : form.monthly === "monthly" ? "/month" : ""}</span></div>
                                                </Col>
                                            )
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        ))
                    )
                    }
                    {
                        this.props.application[this.state.who].occupation.hasoccupation === "employed" && (
                            <div className="add-job" style={{ marginTop: 22 }} onClick={addEmployment}>
                                <img src="/images/bluePlusInCircleMark.png" style={{ width: 18, height: 18 }} /><span>I have another job</span>
                            </div>
                        )
                    }

                    <div className="text-left">
                        <Modal dialogClassName=" align-center employed "
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showSelf} onHide={this.handleSelfClose} >
                            <Modal.Header closeButton>
                                <Modal.Title>Are you self employed?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body as="div" className="">
                                <div className="mb-4 text-wrap">You only qualify for self employed status if you own 25% of your company or more.</div>
                                <div> Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                            </Modal.Body>
                            <Modal.Footer style={{ fontSize: '0.8em' }} className="text-center">
                                <Row className="modalfooter">
                                    <Col className="mt-2">
                                        <But type="outline" onClick={this.handleSelfCloseOff} className="text-nowrap" style={{ minWidth: 180 }}>No, I own less than 25%</But>
                                    </Col>
                                    <Col className="mt-2">
                                        <But type="primary" onClick={this.handleSelfClose} className="text-nowrap" style={{ minWidth: 180 }}>Yes, I own 25% or more</But>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </Modal>


                        {this.props.application[this.state.who].occupation.hasoccupation === "employed" && this.enoughCurrentJob() ? "" :
                            <div >

                                <div className="mt-4 w-600px text-wrap" >
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            If you claim employment income, you need to demonstrate at least two years of employment (including tertiary education).
                                        </Form.Label>
                                        <Form.Label>Have you been employed in the previous two years?</Form.Label>
                                        <CheckGroup
                                            data={[{
                                                value: "false",
                                                label: "No",
                                            },
                                            {
                                                value: "true",
                                                label: 'Yes',
                                            },
                                            ]}
                                            onChangeSelect={e => changePreviousJob(e)}
                                            value={
                                                (null !== this.props.application[this.state.who].previousoccupations && this.props.application[this.state.who].previousoccupations.length > 0) ? "true" : "false"
                                            }
                                            type='radio'
                                            direction="horizontal" />
                                    </Form.Group>
                                </div>

                                {this.props.application[this.state.who].previousoccupations === null ?
                                    "" : (this.props.application[this.state.who].previousoccupations.length === 0 ?
                                        showWarning() :
                                        <>
                                            {
                                                previousEmployed().map((employment, index) => (
                                                    <div className="employment-list" key={index}>
                                                        <div className="employment-item">
                                                            <div className="employment-top">
                                                                <div className="employment-top-l">
                                                                    <div className="employment-icon"><img src="/images/employmentIcon.png" /></div>
                                                                    <span className="employment-name" >Employment {index + 1}</span>
                                                                </div>
                                                                <div className="employment-top-r">
                                                                    <div className="employment-edit" onClick={() => editPreviousEmployment(index)}><img src="/images/employmentEdit.svg" /></div>
                                                                    <div className="employment-delete" onClick={(e) => deletePreviousEmployment(e, index)}><img src="/images/employmentDelete.svg" /></div>
                                                                </div>
                                                            </div>
                                                            <Row className="employment-form">
                                                                {
                                                                    employment.map((form, formIndex) => (
                                                                        <Col className="employment-form-item" md={form.span ? form.span : 6} key={formIndex}>
                                                                            <div className="employment-form-label">{form.label}:</div><div className="employment-form-value">{form.value}</div>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className="add-job" style={{ marginTop: 22 }} onClick={addPreviousEmployment}>
                                                <img src="/images/bluePlusInCircleMark.png" style={{ width: 18, height: 18 }} /><span>I have another job</span>
                                            </div>
                                        </>
                                        // this.props.application[this.state.who].previousoccupations.map(this.process)
                                    )
                                }
                            </div>
                        }
                        {true || this.canShowDividends() ?
                            <div>
                                <div className="mt-4 w-600px text-wrap" >
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            Do you have income in the form of dividends/interest (tax form 1099-DIV or 1099-INT)?
                                        </Form.Label>
                                        <CheckGroup
                                            data={[{
                                                value: "false",
                                                label: "No",
                                            },
                                            {
                                                value: "true",
                                                label: 'Yes',
                                            },
                                            ]}
                                            onChangeSelect={changeHasDividends(this.state.who, "hasdividends")}
                                            value={
                                                true === this.props.application[this.state.who].income.hasdividends ? "true" : "false"
                                            }
                                            type='radio'
                                            direction="horizontal" />
                                    </Form.Group>
                                </div>
                                {this.props.application[this.state.who].income.hasdividends ?
                                    <Form.Row className="mt-4">
                                        <Col className="mr-3" xs="auto">
                                            <Form.Group controlId="firsttime" className="text-left" >
                                                <Form.Label>Dividends/interest</Form.Label>
                                                <div>
                                                    <Select
                                                        style={{ width: '100%', height: 44 }}
                                                        value={this.props.application[this.state.who].income.dividendsmonthly}
                                                        onChange={e => this.props.updateIncomeAtttribute(e, this.state.who, "dividendsmonthly")}
                                                    >
                                                        <option value="yearly">Yearly</option>
                                                        <option value="monthly">Monthly</option>
                                                    </Select>

                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col xs="auto" >
                                            <Form.Group controlId="dividend" className="text-left" >
                                                <Form.Label className="text-left text-nowrap" >Amount</Form.Label>
                                                <div>
                                                    <DollarInput
                                                        onChange={updateMonthlyIncome(this.state.who, "dividends")}
                                                        value={this.props.application[this.state.who].income.dividends}>
                                                    </DollarInput>

                                                </div>

                                                <Form.Control.Feedback type="invalid" >
                                                    Please provide dividends.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Form.Row>
                                    : ""}
                            </div> : ""
                        }
                        <div className="mt-4">
                            <Military who={this.state.who} />
                        </div>
                    </div >
                </div>
            )
        }

        let displayNonQM = () => {
            if (this.props.application.product.mortgageapplied !== "nonqm") {
                return ""
            }
            return <div>
                <div className="mt-4">
                    <Form.Group className="inputSection">
                        <Form.Label>
                            Which income documentation would you like to submit for loan qualification?
                        </Form.Label>
                        <Checkbox.Group
                            className="w-600px d-flex flex-column gap-3"
                            onChange={(value) => this.props.changeNonQMInfo(value, this.state.who, "nonqmincometype")}
                            value={this.props.application[this.state.who].nonqm.nonqmincometype}
                            required
                        >
                            <Checkbox value="VOE">VOE</Checkbox>
                            <Checkbox value="DebtServiceCoverageRatio">Debt Service Coverage Ratio (DSCR)</Checkbox>
                            <Checkbox value="PersonalBankStatement12Mo">1 year personal bank statement</Checkbox>
                            <Checkbox value="PersonalBankStatement24Mo">2 years personal bank statement</Checkbox>
                            <Checkbox value="BusinessBankStatement12Mo">1 year business bank statement</Checkbox>
                            <Checkbox value="BusinessBankStatement24Mo">2 years business bank statement</Checkbox>
                            <Checkbox value="Form109912Mo">1 year Form 1099</Checkbox>
                            <Checkbox value="Form109924Mo">2 years Form 1099</Checkbox>
                            <Checkbox value="PL12">1 year profit and loss</Checkbox>
                            <Checkbox value="PL24">2 years profit and loss</Checkbox>
                            <Checkbox value="AssetDepletion">Asset depletion</Checkbox>
                        </Checkbox.Group>
                    </Form.Group>
                </div>
                <div className="mt-4">
                    <Form.Group controlId="nonqmamount" className="text-left" style={{
                        alignItems: 'baseline', width: '100%', justifyContent: 'space-between'

                    }}>
                        <Form.Label className="text-left text-nowrap employment-label" >What is your estimated income?</Form.Label>
                        <div className="w-600px">
                            <Space.Compact className="w-100">
                                <DollarInput value={this.props.application[this.state.who].nonqm.nonqmincomeamount} onChange={(e) => this.props.changeNonQMInfo(e, this.state.who, "nonqmincomeamount")} />
                                <Select style={{ height: 44,width:'40%' }} defaultValue="monthly" value={this.props.application[this.state.who].nonqm.nonqmincomeperiod}
                                    onChange={(e) => { this.props.changeNonQMInfo(e, this.state.who, "nonqmincomeperiod") }}
                                >

                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </Select>
                            </Space.Compact>
                        </div>
                    </Form.Group>
                </div>
            </div>

        }

        if (this.state.isNonQMCustomer === null) {
            return <div className="p-5">
                <Skeleton active />
            </div>
        }

        return (
            <div className="text-left income-application newInterviewPaneEmployment">
                <div className="prequalInterviewTitle income-title">Employment</div>
                {employmentModal()}
                {PreviousEmploymentModal()}
                {
                    this.personalCoborrower() && (
                        <div className="applicant-tab">
                            {
                                this.state.tabs.map((item, index) => (
                                    <div className={this.state.tabAvtive === index ? 'applicant-tab-active' : ''} key={index}>
                                        <div className="applicant-tab-title">{item.tab}</div>
                                        <div className="applicant-tab-name">{item.value}</div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                {this.state.isNonQMCustomer && <div className="mt-4  text-wrap" >
                    <Form.Group className="inputSection">
                        <Form.Label>
                            Can you provide full documentation of your income for the past two years, including tax returns and W-2 forms? <Tips
                                content={<>
                                    <div>Full documentation typically includes:
                                        <ul>
                                            <li>Tax returns for the past 2 years</li>
                                            <li>W-2 forms for the past 2 years</li>
                                            <li>Recent pay stubs</li>
                                        </ul>
                                    </div>

                                    <div>Conventional loans usually require full documentation to verify your income.</div>

                                    <div className="mt-2">Non-QM (Non-Qualified Mortgage) loans may be an option if you can't provide full documentation. These loans are designed for:</div>
                                    <ul>
                                        <li>Those with non-traditional income sources</li>
                                        <li>Borrowers with unique financial situations</li>
                                    </ul>

                                    <div>Your answer helps us guide you to the most appropriate loan type for your situation.</div>
                                </>}
                                placement="top"
                                title="Income Documentation Explanation"
                            />
                        </Form.Label>
                        <div>
                            <CheckGroup
                                className="w-600px w-100"
                                data={[
                                    {
                                        value: "true",
                                        label: 'Yes',
                                    },
                                    {
                                        value: "false",
                                        label: "No",
                                    },
                                ]}
                                onChangeSelect={e => {
                                    if (e === "true") {
                                        this.props.updateProduct('conventional', "mortgageapplied")
                                    } else {
                                        this.props.updateProduct("nonqm", "mortgageapplied")
                                    }
                                }}
                                value={
                                    (this.props.application.product.mortgageapplied !== "nonqm" ? "true" : "false")
                                }
                                type='radio'
                                direction="horizontal" />
                        </div>
                    </Form.Group>
                </div>}

                {displayNonQM()}
                {displayConventional()}
            </div >
        )
    }
};

Income = connect(mapStateToPropsDetails, mapDispatchToPropsDetails, null, { forwardRef: true })(Income)
export class OtherIncome extends Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef()
        this.form = React.createRef()
        this.state = {
            tabAvtive: 0,
            showEmploymentModal: false,
            incometype: '',
            description: '',
            amount: '',
            who: com.borrower,
            isEdit: false,
            editIndex: -1,
            validated: false,
            hasborrowerTab: true,
            tabs: [
                {
                    tab: 'Primary',
                    value: this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
                }
            ]
        }
    }
    componentDidMount() {
        sessionStorage.setItem("progressValue", 100)
        if (this.personalCoborrower()) {
            this.setState(prevState => ({
                tabs: [
                    ...prevState.tabs,
                    {
                        tab: 'Co-borrower',
                        value: this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname
                    }
                ]
            }))
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() { }
    personalCoborrower = () => {
        return this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)

    }
    canShowDividends = () => {
        if (this.props.application[this.state.who].occupation.hasoccupation === null)
            return false

        if (this.props.application[this.state.who].occupation.hasoccupation !== "employed")
            return true

        if (this.state.isbasevalid && this.props.application[this.state.who].income.hasbonuses === false)
            return true

        if (this.state.isbasevalid && this.props.application[this.state.who].income.hasbonuses === true &&
            this.state.iscomissionvalid && this.state.isovertimevalid && this.state.isbonusesvalid
        )
            return true

        return false
    }
    canShowOtherIncome = () => {
        if (!this.canShowDividends())
            return false
        if (null === this.props.application[this.state.who].income.hasdividends)
            return false
        if (false === this.props.application[this.state.who].income.hasdividends)
            return true

        if (false === this.props.application[this.state.who].income.hasdividends)
            return true

        if (true === this.props.application[this.state.who].income.hasdividends &&
            this.state.isdividendsvalid)
            return true

        return false
    }
    addOtherSources = (e) => {


        com.touch()
        if (e !== "true") {
            this.props.clearBorrowerAdditionalIncome(this.state.who)
        } else {
            // console.log(e);
            setTimeout(() => {
                let employment = this.props.application[this.state.who].income.otherincome[0]
                this.setState({ isEdit: true, validated: false, editIndex: 0, amount: employment.amount, description: employment.description, incometype: employment.incometype, showEmploymentModal: true })
            }, 200)
            //

            this.props.addBorrowerAdditionalIncome(0, this.state.who)
        }
    }
    processOtherIncome = (val, index) => {
        if (null === val)
            return ""
        let desc = val.description
        let amount = val.monthlyamount
        let key = val.key
        return this.extraIncome(index, desc, amount, key)
    }
    addMoreSources = () => {
        com.touch()
        this.props.addBorrowerAdditionalIncome(0, this.state.who)

    }
    handleSubmit = e => {
        if (this.state.tabAvtive === 0 && this.personalCoborrower()) {
            this.changeTab(1)
            return false
        }
        return true
    }
    handlePrevious = () => {
        if (this.state.tabAvtive === 1) {
            this.changeTab(0)
            return false
        }
        return true
    }
    changeTab = (tab) => {
        this.setState({ tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower }, () => { eventBus.emit('changeProgressValue') })
    }
    render() {
        let formatAmount = (value) => {
            return value ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '--'
        }
        let getEmploymentList = () => {
            let otherincome = this.props.application[this.state.who].income.otherincome ? this.props.application[this.state.who].income.otherincome : []
            return otherincome.map(obj => [
                {
                    // label: 'Employer',
                    label: 'Amount',
                    value: formatAmount(obj.amount) + '(monthly)',
                },
                {
                    label: 'Income type',
                    value: obj.incometype ? com.splitByCapitalLetter(obj.incometype) : '--',
                },
                // {
                //     label: 'Additional info',
                //     value: obj.description ? obj.description : '--',
                //     span: 12
                // }
            ])
        }
        let editEmployment = (index) => {
            let employment = this.props.application[this.state.who].income.otherincome[index]
            this.setState({ isEdit: true, validated: false, editIndex: index, amount: employment.amount, description: employment.description, incometype: employment.incometype, showEmploymentModal: true })
        }
        let deleteEmployment = (index) => {
            this.props.removeBorrowerAdditionalIncome(index, this.state.who)
        }

        let addEmployment = () => {
            this.setState({ isEdit: false, validated: false, amount: '', description: '', incometype: '', showEmploymentModal: true })
        }
        let onSelectAdditionalIncome = (value) => {
            com.touch()
            let description = ""
            com.othersouresofincome.forEach(x => {
                if (x[0] === value) {
                    description = x[1]
                }
            })
            if (value === "Other")
                description = ""
            this.setState({ incometype: value, description: description })
        }
        let employmentModal = () => {
            let creditCheckContinue = () => {
                let index = 0
                if (this.state.isEdit) {
                    index = this.state.editIndex
                } else {
                    this.addMoreSources()
                    index = this.props.application[this.state.who].income.otherincome.length
                }
                this.props.updateBorrowerAdditionalIncome({ target: { value: this.state.amount } }, this.state.who, "amount", index)
                this.props.updateBorrowerAdditionalIncome({ target: { value: this.state.incometype } }, this.state.who, "incometype", index)
                this.props.updateBorrowerAdditionalIncome({ target: { value: this.state.description } }, this.state.who, "description", index)
                this.setState({ showEmploymentModal: false })
            }
            let submitIncome = (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (this.state.amount === '') {
                    this.setState({ validated: true })
                    return false
                }
                creditCheckContinue()
            }
            return (
                <Modal className="employment-modal" size="lg" show={this.state.showEmploymentModal} onHide={() => this.setState({ showEmploymentModal: false })}>
                    <Modal.Header closeButton >
                        <Modal.Title>Add income</Modal.Title>
                    </Modal.Header>
                    <Form ref={this.form} noValidate onSubmit={submitIncome} validated={this.state.validated}>
                        <Modal.Body style={{ padding: '0 40px 14px', maxHeight: '80vh', minHeight: '500px', overflow: 'auto' }}>
                            <div style={{ fontSize: '14px', lineHeight: '20px', color: '#6E6E70', margin: '14px 0 0px 0' }}>* Indicates required</div>


                            <Form.Row >
                                <Col xs="12" sm="6" style={{ width: '100%' }} className="mt-4">
                                    <Form.Group controlId="overtime" className="text-left" >
                                        <Form.Label className="text-left text-nowrap employment-label" >Amount*</Form.Label>
                                        <div style={{ position: 'relative' }}>
                                            <div>
                                                <DollarInput suffix='Monthly' onChange={(e) => {
                                                    this.setState({ amount: e < 0 ? 0 : e })
                                                }} value={this.state.amount}>

                                                </DollarInput>
                                            </div>
                                        </div>
                                        {this.state.validated ? <div style={{ color: 'red' }}>
                                            Please provide  income.
                                        </div> : <></>}
                                    </Form.Group>
                                </Col>
                                <Col xs="12" sm="6" className="mt-4">
                                    <Form.Group controlId="description" className="text-left" >
                                        <Form.Label className="text-left text-nowrap employment-label" >What’s the type of this income?</Form.Label>
                                        <div>
                                            <Select
                                                className="w-100"
                                                style={{ height: 44, width: "100%" }}
                                                value={this.state.incometype}
                                                onChange={onSelectAdditionalIncome}

                                            >

                                                {com.othersouresofincome.map(st =>
                                                (
                                                    <Select.Option value={st[0]}>{st[1]}</Select.Option>
                                                )
                                                )}

                                            </Select>

                                        </div>
                                        <InputGroup>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Form.Row >
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="zeitro-primary" className="income-save-btn">
                                Save
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )
        }
        return (
            <div className="text-left income-application">
                <div className="prequalInterviewTitle income-title">Other income</div>
                {employmentModal()}
                {
                    this.personalCoborrower() && (
                        <div className="applicant-tab">
                            {
                                this.state.tabs.map((item, index) => (
                                    <div className={this.state.tabAvtive === index ? 'applicant-tab-active' : ''} key={index}>
                                        <div className="applicant-tab-title">{item.tab}</div>
                                        <div className="applicant-tab-name">{item.value}</div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <Form ref={this.formRef}>
                    {true || this.canShowOtherIncome() ?
                        <div className="mt-4 text-left newInterviewPaneEmployment">
                            <div className="w-600px text-wrap" >
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Do you have other source of income? <Tips
                                            content="You don't need to provide information about alimony or child support unless you want it considered in determining your qualification for the loan."
                                            placement="top"
                                            title=""
                                        />
                                    </Form.Label>
                                    <CheckGroup
                                        data={[{
                                            value: "false",
                                            label: "No",
                                        },
                                        {
                                            value: "true",
                                            label: 'Yes',
                                        },
                                        ]}
                                        onChangeSelect={this.addOtherSources}
                                        value={
                                            (this.props.application[this.state.who].income.otherincome !== null && this.props.application[this.state.who].income.otherincome.length !== 0) ? "true" : "false"
                                        }
                                        type='radio'
                                        direction="horizontal" />
                                </Form.Group>
                            </div>
                            {/* {this.props.application[this.state.who].income.otherincome === null ? "" : this.props.application[this.state.who].income.otherincome.map(this.processOtherIncome)} */}
                            {
                                getEmploymentList().map((employment, index) => (
                                    <div className="employment-list mt-3" key={index}>
                                        <div className="employment-item income-item">
                                            <div className="employment-top">
                                                <div className="employment-top-l">
                                                    <div className="employment-icon"><img src="/images/employmentIcon.png" /></div>
                                                    <span className="employment-name" >Other income {index + 1}</span>
                                                </div>
                                                <div className="employment-top-r">
                                                    <div className="employment-edit" onClick={() => editEmployment(index)}>
                                                        <img src="/images/employmentEdit.svg" />
                                                    </div>
                                                    <div className="employment-delete" onClick={() => deleteEmployment(index)}>
                                                        <img src="/images/employmentDelete.svg" />
                                                    </div>
                                                </div>
                                            </div>
                                            <Row className="employment-form mb-2">
                                                {
                                                    employment.map((form, formIndex) => (
                                                        <Col className="employment-form-item mb-2" md={form.span ? form.span : 6} key={formIndex}>
                                                            <div className="employment-form-label">{form.label}:</div><div className="employment-form-value">{form.value}</div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                this.props.application[this.state.who].income.otherincome && this.props.application[this.state.who].income.otherincome.length > 0 && (
                                    <div className="add-job" style={{ marginTop: 26 }} onClick={addEmployment}>
                                        <img src="/images/bluePlusInCircleMark.png" style={{ width: 18, height: 18 }} /><span>I have another source of income</span>
                                    </div>
                                )
                            }
                        </div> : ""}
                </Form>
            </div>
        )
    }
}
OtherIncome = connect(mapStateToPropsDetails, mapDispatchToPropsDetails, null, { forwardRef: true })(OtherIncome)
