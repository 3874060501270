import React, { useEffect, useContext } from "react";
import "./Component.css";
import { Form, Col, Button, Row, Container, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div onClick={decoratedOnClick}>
      <img
        src={
          isCurrentEventKey
            ? "/images/blueMinusInCircleMark.png"
            : "/images/bluePlusInCircleMark.png"
        }
        style={{ height: "14px", marginTop:"7px", cursor:"pointer"}}
      />
    </div>
  );
}
const Collapsible = ({ content, title, expanded, onToggle }) => {
  const accordionSelect = (key) => {
    if (onToggle) {
      onToggle(key)
    }
  }
  return (
    <Accordion defaultActiveKey={expanded ? "0" : ""} onSelect={accordionSelect}>
      <div className="collapsibleHeader">
        <div>
          <Row style={{flexWrap:"nowrap"}}>
            <Col xs={11} className="d-flex">
              <img src="/images/iconPeople.png" style={{ height: "27px", width:"27px" }} />
              <div style={{padding:"3px 0 0 10px", fontSize:"16px"}}>{title}</div>
            </Col>
            <Col xs={1} >
              <ContextAwareToggle eventKey="0"/>
            </Col>
          </Row>
        </div>
        <Accordion.Collapse eventKey="0">
          <div className="collapsibleContent">{content}</div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default Collapsible;
