import React, {Component, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../BorrowerApplication.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DateOfBirth from "../DateOfBirth";
import * as com from "../Common.js";
import NumericalInput from "../NumericalInput";
import {Checkbox, Input} from "antd";
import {connect} from "react-redux";
import * as act from "../Store/actions";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Autocomplete from "../Autocomplete";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import {tooltip} from "../Common/Tooltip";
import {FutureExpenses} from "../State";
import {titles} from "../occupations.js";
import CheckGroup from "../Common/components/CheckGroup";
import {Tips} from "../Common/Tooltip";
import {isEmpty} from "lodash";
import eventBus from "../Bus";

const mapStateToProps = (state) => {
  return {
    application: state.application,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeDeclaration: (event, who, verb) => {
    dispatch(act.ChangeDeclaration(event, who, verb));
  },
});

export default function Military(props) {
  let changeDeclaration = (verb) => {
    return (event) => {
      com.touch();
      props.changeDeclaration(event, props.who, verb);
    };
  };
  let changeMilitary = (verb, isdate) => {
    let m = {...props.application[props.who].declarations.militaryservice}
    return (e) => {
        com.touch();
        let value = isdate ? e.target.value : ""
        if (isdate){
            if (/^[\d/]*$/.test(value)){
                let formattedValue = value.replace(/\D/g,'')
                if (formattedValue.length > 2){
                    formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4) + "/" + formattedValue.slice(4, 8)
                } else if (formattedValue.length > 2){
                    formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4)
                }
                value = formattedValue   
            }
        }
        m[verb] = isdate ? value : e.target.checked
      props.changeDeclaration(m, props.who, "militaryservice");
    };
  };
  let dec = props.application[props.who].declarations;
  return (
    <div>
      <div className="w-600px text-wrap">
        <Form.Group className="inputSection mt-4">
          <Form.Label>
            Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States
            Armed Forces?
          </Form.Label>
          <CheckGroup
            data={[
              {
                value: "no",
                label: "No",
              },
              {
                value: "yes",
                label: "Yes",
              },
            ]}
            onChangeSelect={changeDeclaration("servearmedforces")}
            value={dec.servearmedforces === "yes" ? "yes" : "no"}
            type="radio"
            direction="horizontal"
          />
        </Form.Group>
      </div>
      {dec.servearmedforces === "yes" && (
        <div className="mt-2 d-flex flex-column" style={{whiteSpace:"normal"}}>
          <div className="d-flex flex-column">
            <Checkbox  className="mb-2" checked={dec.militaryservice.currentlyserving===true} onChange={changeMilitary("currentlyserving")}>Currently serving on active duty with projected expiration date of service/tour</Checkbox>
            <Input style={{width:"200px", marginLeft:"20px", marginBottom:"10px"}} placeholder="MM/DD/YYYY" type="text" value={dec.militaryservice.projectedexpirationdate} onChange={changeMilitary("projectedexpirationdate", true)} />
          </div>
          <Checkbox className="mb-2" checked={dec.militaryservice.currentlyretired===true} onChange={changeMilitary("currentlyretired")}>Currently retired, discharged, or separated from service</Checkbox>
          <Checkbox className="mb-2" checked={dec.militaryservice.nonactive===true} onChange={changeMilitary("nonactive")}>Only period of service was as a non-activated member of the Reserve or National Guard</Checkbox>
          <Checkbox checked={dec.militaryservice.survivingspouse===true} onChange={changeMilitary("survivingspouse")}>Surviving spouse</Checkbox>
        </div>
      )}
    </div>
  );
}

Military = connect(mapStateToProps, mapDispatchToProps)(Military);
