import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import '../App.css'
import { connect } from 'react-redux'
import { InboxOutlined, CloseOutlined, CaretDownOutlined, LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import heic2any from 'heic2any'
import { Button, Input, Table, Modal, ConfigProvider, Select, Form, Col, Row, DatePicker, Spin, Upload, message, Popconfirm, Dropdown, Popover, AutoComplete, Pagination, Skeleton, Divider, Tooltip, Empty, Switch } from 'antd'
import PreviewModal from "./PreviewModal"
import "./DocumentChecklist.css"
import { useSelector } from 'react-redux'
import * as JSZip from "jszip"
import { saveAs } from 'file-saver'
const { Dragger } = Upload
const { TextArea } = Input
const EditableContext = React.createContext(null)
const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({

})

export default function Tasks(props) {
    const Info = useSelector(state => {
        return state.common.currentInfo
    })
    const [form] = Form.useForm()
    const tableRef = useRef(null)
    const [modal, contextHolder] = Modal.useModal()
    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [tasksLoading, setTasksLoading] = useState(false)
    const [taskDocuments, setTaskDocuments] = useState([])
    const [pageWidth, setPageWidth] = useState(680)
    const [currentID, setCurrentID] = useState("")
    const [currentDoc, setCurrentDoc] = useState(null)
    const [documentList, setDocumentList] = useState([])
    const [originalDocumentList, setOriginalDocumentList] = useState([])
    const [uncategorizedDoc, setUncategorizedDoc] = useState([])
    const [originalUncategorizedDoc, setOriginalUncategorizedDoc] = useState([])
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [fileList, setFileList] = useState([])
    const [searchKeyword, setSearchKeyword] = useState('')
    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedSwitch, setSelectedSwitch] = useState(false)
    const [uncategorizedOpen, setUncategorizedOpen] = useState(true)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [previewMode, setPreviewMode] = useState("preview")
    const [handleForm, setHandleForm] = useState("")
    const [handleRecord, setHandleRecord] = useState(null)
    const [uploadMode, setUploadMode] = useState("")
    const [file, setFile] = useState("")
    const [pageNumber, setPageNumber] = useState(1)
    const [numPages, setNumPages] = useState(1)
    const [scale, setScale] = useState(1)
    const [task, setTask] = useState("")
    const identification = useRef(null)
    const income = useRef(null)
    const assets = useRef(null)
    const property = useRef(null)
    const miscellaneous = useRef(null)
    const searchKeywordRef = useRef(searchKeyword)
    const selectedCategoryRef = useRef(selectedCategory)
    const selectedSwitchRef = useRef(selectedSwitch)
    const documentListRef = useRef(documentList)
    // const internalDocuments = useRef(null)
    const refs = {
        identification,
        income,
        assets,
        property,
        miscellaneous,
        // internalDocuments
    }
    const checklistWrapRef = useRef(null)
    const [visiblePopover, setVisiblePopover] = useState(null)
    const handlePopoverVisibility = (recordId, visibility) => {
        if (visibility) {
            setVisiblePopover(recordId)
        } else {
            setVisiblePopover(null)
        }
    }
    const handleExpand = (type) => {
        const updatedList = documentList.map(item => {
            if (item.type === type) {
                return { ...item, open: !item.open }
            }
            return item
        })
        setDocumentList(updatedList)
    }
    useEffect(() => {
        getdocuments()
        const interval = setInterval(() => {
            getdocuments(false)
        }, 5000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        searchKeywordRef.current = searchKeyword
        selectedCategoryRef.current = selectedCategory
        selectedSwitchRef.current = selectedSwitch
    }, [searchKeyword, selectedCategory, selectedSwitch])
    useEffect(() => {
        documentListRef.current = documentList
    }, [documentList])
    useEffect(() => {
        if (tableRef.current) {
            const tableContainer = tableRef.current.querySelector('.document-table .ant-table-body')
            if (tableContainer) {
                tableContainer.scrollTop = tableContainer.scrollHeight
            }
        }
    }, [taskDocuments.length])

    useEffect(() => {
       
        if (Info.currentCategory) {
            const targetRef = refs[Info.currentCategory]
            if (targetRef && targetRef.current && checklistWrapRef.current) {
                const elementTop = targetRef.current.getBoundingClientRect().top
                const wrapTop = checklistWrapRef.current.getBoundingClientRect().top
                const offsetTop = elementTop - wrapTop
                const currentScroll = checklistWrapRef.current.scrollTop

                checklistWrapRef.current.scrollTo({
                    top: currentScroll + offsetTop,
                    behavior: 'smooth'
                })
            }
        }
    }, [Info])
    const transformData = (data) => {
        const transformed = {}
        categoryOptions.forEach(category => {
            const existingItem = documentListRef.current.find(item => item.type === category.value)
            const openState = existingItem ? existingItem.open : true
    
            transformed[category.value] = {
                type: category.value,
                open: openState,
                list: []
            }
        })
        data.forEach(item => {
            const { TaskID } = item

            if (transformed[TaskID]) {
                transformed[TaskID].list.push(item)
            }
        })
        Object.values(transformed).forEach(category => {
            category.list.sort((a, b) => a.ID - b.ID)
        })
        return Object.values(transformed)
    }
    const getdocuments = (showLoading = true) => {
        let token = sessionStorage.getItem("ZeitroA")
        if (showLoading) {
            setTasksLoading(true)
        }
        let loanid = '' // Temporarily empty
        let api = '/docs/getdocuments/' + loanid
        fetch(api, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "Content-Type": "application/json",
            },

        }).then(
            response => {
                if (showLoading) {
                    setTasksLoading(false)
                }
                if (response.status !== 200) {
                    return
                }
                response.json().then(data => {
                    let originalData = data
                    const uncategorizedList = data.filter(task => task.TaskID === 'uncategorized')
                    .flatMap(task => 
                        task.Documents.map(document => ({
                            ...document,
                            Updated: task.Updated,
                            docType: task.TaskID,
                            ExtraInfo: document.ExtraInfo ? JSON.parse(document.ExtraInfo) : {}
                        }))
                    )
                    const list = transformData(originalData)
                    setDocumentList(list)
                    setOriginalDocumentList(list)
                    setUncategorizedDoc(uncategorizedList)
                    setOriginalUncategorizedDoc(uncategorizedList)
                    if (searchKeywordRef.current !== '' || selectedCategoryRef.current.length > 0 || selectedSwitchRef.current) {
                        filterData(searchKeywordRef.current, selectedCategoryRef.current, selectedSwitchRef.current, list, uncategorizedList)
                    }
                    if (handleForm === 'move') {
                        let record = uncategorizedDoc[0]
                        setHandleRecord({ ...record, num: 1, total: uncategorizedDoc.length})
                        downloadAll(record.ID, 'move', record.FileName)
                    }
                })
            }

        ).catch((err) => {
            if (showLoading) {
                setTasksLoading(false)
            }
        })
    }

    const isApproachingDeadline = (deadline) => {
        const deadlineDate = dayjs(deadline).startOf('day')
        const now = dayjs().startOf('day')
        const oneDayBeforeDeadline = deadlineDate.subtract(1, 'day')
        return now.isSame(oneDayBeforeDeadline, 'day') || now.isAfter(oneDayBeforeDeadline, 'day')
    }
    const filterData = (keyword, categories, switchFilter, list, uncategorizedList) => {
        const newFilteredUncategorizedData = uncategorizedList.filter(doc => {
            const fileNameMatch = doc.FileName.toLowerCase().includes(keyword)
            return fileNameMatch
        })
        const newFilteredData = list.map(cat => {
            if (categories.length > 0 && !categories.includes(cat.type)) {
                return { ...cat, list: [] }
            }

            const filteredList = cat.list.filter(item => {
                const taskNameMatch = item.TaskName.toLowerCase().includes(keyword)
                const documentsMatch = item.Documents.some(doc => doc.FileName.toLowerCase().includes(keyword))
                const statusMatch = !switchFilter || (item.Status === 'requested' || item.Status === 'pendingUpload' || item.Status === 'resubmit' || isApproachingDeadline(item.Deadline))
                return (taskNameMatch || documentsMatch) && statusMatch
            })

            return { ...cat, list: filteredList }
        })
        setDocumentList(newFilteredData)
        setUncategorizedDoc(newFilteredUncategorizedData)
    }
    const showCurrentTasks = () => {
        const formatStatus = {
            requested: {
                label: 'Not uploaded',
                tooltip: ''
            },
            pendingUpload: {
                label: 'Not uploaded',
                tooltip: ''
            },
            uploaded: {
                label: 'Uploaded',
                tooltip: ''
            },
            resubmit: {
                label: 'Re-upload needed',
                tooltip: ''
            },
            verified: {
                label: 'Reviewed',
                tooltip: ''
            },
            uncategorized: {
                label: 'Uncategorized',
                tooltip: ''
            },
            readyForReview: {
                label: 'Uploaded',
                tooltip: ''
            }
        }
        const mapStatusTagStyle = (status) => {
            switch (status) {
                case 'resubmit':
                case 'uncategorized':
                    return { color: '#9C7000', backgroundColor: '#FFE6A6' }
                case 'verified':
                    return { color: '#2E605A', backgroundColor: '#E1F2E2' }
                case "uploaded":
                case "readyForReview":
                    return { color: '#13358D', backgroundColor: '#D8EAFF' }
                default:
                    return { color: '#222222', backgroundColor: '#E0E0E0' }
            }
        }
        const mapViewStyle = (status) => {
            switch (status) {
                case 'uploaded':
                case "resubmit":
                case 'readyForReview':
                    return { color: '#325CEB', backgroundColor: '#FCFCFC', border: '1px solid #325CEB' }
                case 'verified':
                    return { color: '#979797', backgroundColor: '#FCFCFC', border: '1px solid #ECECEC', cursor: 'no-drop' }
                default:
                    return { color: '#FFFFFF', backgroundColor: '#325CEB' }
            }
        }
        const mapViewText = (status) => {
            switch (status) {
                case 'requested':
                case 'pendingUpload':
                    return 'Upload'
                case 'uploaded':
                case 'verified':
                case "resubmit":
                case "readyForReview":
                    return 'Re-upload'
                default:
                    return 'View'
            }
        }
        const dropdownMenu = (record) => {
            const dropdownItems = [
                {
                    label: 'Download all',
                    key: 'download',
                    disabled: record.Documents.length === 0,
                }
            ]
            return dropdownItems
        }
        const handleDocument = (e, record) => {
            setCurrentID(record.ID)
            if (e.key === 'download') {
                downloadAll(record.ID, 'all', record.TaskName)
            }
        }
        const handleUncategorized = (e, record) => {
            downloadAll(record.ID, 'single', record.FileName)
        }

        const handleUpload = (record) => {
            setCurrentDoc(record)
            setShowDocumentModal(true)
        }
        const actionDoc = (record) => {
            if (!['verified'].includes(record.Status)) {
                if (record.Status === 'uploaded' || record.Status === 'resubmit' || record.Status === 'readyForReview') {
                    setUploadMode('reupload')
                } else {
                    setUploadMode('single')
                }
                handleUpload(record)
            }
        }
        const uncategorizedColumns = [
            {
                title: "",
                width: 48,
            },
            {
                title: (
                    <div className="document-header-name">
                        <span>{'UNcategorized Documents'.toUpperCase()}</span>
                    </div>
                ),
                width: '30%',
                ellipsis: true,
                render: (_, record) => (
                    <Tooltip arrow={false} placement="bottomLeft" title={record.FileName}>
                        <div className="document-name">{record.FileName}</div>
                    </Tooltip>
                ),
            },
            {
                title: "",
                width: '20%',
            },
            {
                title: <span style={{ color: '#545459', fontWeight: 500 }}>Last update</span>,
                dataIndex: 'Updated',
                key: 'Updated',
                width: '15%',
                sorter: (a, b) => new Date(a.Updated) - new Date(b.Updated),
                render: (text) => <span style={{ color: '#545459' }}>{text ? dayjs(text).format('MM/DD/YYYY') : "-"}</span>,
            },
            {
                title: <span style={{ color: '#545459', fontWeight: 500 }}>Status</span>,
                dataIndex: 'ExtraInfo',
                key: 'ExtraInfo',
                width: '20%',
                sorter: (a, b) => a.ExtraInfo.status.localeCompare(b.ExtraInfo.status),
                render: (_, record) => {
                    const { status } = record.ExtraInfo;
                    return status === 'processing' ? (
                        <div className='doc-processing'><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /><span className='processing-text'>Processing</span></div>
                    ) : (
                        <div style={mapStatusTagStyle(status)} className='document-status'>
                            <span>{formatStatus[status]?.label}</span>
                        </div>
                    );
                },
            },
            {
                title: (
                    <div className='document-action-wrap uncategorized-wrap'>
                        <div style={mapViewStyle('view')} className='document-view view-all' onClick={() => viewAll()}>View all</div>
                        <img style={{ cursor: 'pointer' }} className={uncategorizedOpen ? 'up' : 'down'} onClick={() => setUncategorizedOpen(!uncategorizedOpen)} src='/images/document-collapse.svg' />
                    </div>
                ),
                dataIndex: '',
                key: '',
                fixed: 'right',
                width: 160,
                render: (_, record, index) => (
                    <div className='document-action-wrap'>
                        <div style={mapViewStyle(record.ExtraInfo.status)} className='document-view' onClick={() => moveDoc(record, index, 'move')}>{mapViewText(record.ExtraInfo.status)}</div>
                        <Dropdown
                            overlayClassName="document-checklist-dropdown"
                            menu={{
                                items: [
                                    {
                                        label: 'Download',
                                        key: 'download',
                                    }
                                ],
                                onClick: (e) => handleUncategorized(e, record)
                            }}
                            placement="bottomRight"
                            trigger={['click']}
                        >
                            <div className='document-more'><img src='/images/document-more.svg' /></div>
                        </Dropdown>
                    </div>
                ),
            },
        ]
        const messageList = (record) => {
            const filterResubmission = record.OperationRecord.ResubmissionRequest
                ? record.OperationRecord.ResubmissionRequest.filter(item => item.Description !== '')
                : []
        
            if (record.Status === 'resubmit' && filterResubmission.length > 0) {
                return (
                    <div className='doc-popover'>
                        <div className='doc-popover-title'>Message from LO:</div>
                        {filterResubmission.map(item => (
                            <div className='doc-popover-content'><span style={{fontWeight: 600, marginRight: 6}}>{item.Deadline}</span>{item.Description}</div>
                        ))}
                    </div>
                )
            } else if (record.Description !== '') {
                return (
                    <div className='doc-popover'>
                        <div className='doc-popover-title'>Message from LO:</div>
                        <div className='doc-popover-content'>{record.Description}</div>
                    </div>
                )
            }
        }
        const previewFile = (record) => {
            if (record.Documents.length > 0) {
                previewDoc(record, record.Documents[0], 0, 'preview')
            }
        }
        const columnsData = (type) => {
            const documentColumns = [
                {
                    title: (
                        <div className="document-header-name">
                            <span>{getLabelByValue(categoryOptions, type).toUpperCase()}</span>
                        </div>
                    ),
                    width: '30%',
                    ellipsis: true,
                    render: (_, record) => {
                        const isVisible = visiblePopover === record.ID
                        const reUploadMsg = record.Status === 'resubmit' && record.OperationRecord.ResubmissionRequest && record.OperationRecord.ResubmissionRequest.filter(item => item.Description !== '').length > 0
                        const initualMsg = record.Description !== ''
                        return (
                            <div className='document-name-wrap'>
                                <Tooltip arrow={false} placement="bottomLeft" title={record.TaskName}>
                                    <div className="document-name" onClick={() => previewFile(record)}>{record.TaskName}</div>
                                </Tooltip>
                                {
                                    (reUploadMsg || initualMsg) && (
                                        <Popover
                                            overlayClassName={`initual-doc document-popover ${reUploadMsg ? 're-upload' : 'initual-msg'}`}
                                            placement="top"
                                            trigger="hover"
                                            open={isVisible}
                                            onOpenChange={(visible) => handlePopoverVisibility(record.ID, visible)}
                                            content={
                                                <div style={{ position: 'relative' }}>
                                                    {messageList(record)}
                                                    <CloseOutlined
                                                        className='doc-popover-close'
                                                        onClick={() => handlePopoverVisibility(record.ID, false)}
                                                    />
                                                </div>
                                            }
                                        >
                                            <img src={`/images/document-message-${reUploadMsg ? 'yellow' : 'grey'}.svg`} />
                                        </Popover>
                                    )
                                }
                            </div>
                        )
                    },
                },
                {
                    title: <span style={{ color: '#545459', fontWeight: 500 }}>Time frame</span>,
                    dataIndex: 'TimeRange',
                    key: 'TimeRange',
                    width: '20%',
                    render: (text) => <span style={{ color: '#545459' }}>{getLabelByValue(timeRangeOptions, text)}</span>,
                },
                {
                    title: <span style={{ color: '#545459', fontWeight: 500 }}>Due date</span>,
                    dataIndex: 'Deadline',
                    key: 'Deadline',
                    width: '15%',
                    sorter: (a, b) => new Date(a.Deadline) - new Date(b.Deadline),
                    render: (text) => <span style={isApproachingDeadline(text) ? { color: '#C90000', fontWeight: 600 } : { color: '#545459' }}>{text ? dayjs(text).format('MM/DD/YYYY') : "-"}</span>,
                },
                {
                    title: <span style={{ color: '#545459', fontWeight: 500 }}>Status</span>,
                    dataIndex: 'Status',
                    key: 'Status',
                    width: '20%',
                    sorter: (a, b) => a.Status.localeCompare(b.Status),
                    render: (_, record, index) => (
                        <div style={mapStatusTagStyle(record.Status)} className='document-status'>
                            <span>{formatStatus[record.Status] ? formatStatus[record.Status].label : record.Status}</span>
                        </div>
                    ),
                },
                {
                    title: (
                        <div className='collapse-header-arrow'>
                            {!documentList.find(item => item.type === type).list.some(doc => doc.TaskName !== '') && <img style={{ cursor: 'pointer' }} onClick={() => specialUpload(type)} src='/images/upload-document.svg' />}
                            <img style={{ cursor: 'pointer' }} className={documentList.find(item => item.type === type).open ? 'up' : 'down'} onClick={() => handleExpand(type)} src='/images/document-collapse.svg' />
                        </div>
                    ),
                    dataIndex: '',
                    key: '',
                    fixed: 'right',
                    width: 160,
                    render: (_, record, index) => (
                        <div className='document-action-wrap'>
                            <div style={mapViewStyle(record.Status)} className='document-view' onClick={() => actionDoc(record)}>{mapViewText(record.Status)}</div>
                            <Dropdown
                                overlayClassName="document-checklist-dropdown"
                                menu={{
                                    items: dropdownMenu(record),
                                    onClick: (e) => handleDocument(e, record)
                                }}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div className='document-more'><img src='/images/document-more.svg' /></div>
                            </Dropdown>
                        </div>
                    ),
                },
            ]
            return documentColumns
        }

        const previewDoc = (data, record, index, type) => {
            setTask(data)
            setPreviewMode(type)
            setHandleRecord({ ...record, taskName: data.TaskName, num: index + 1, total: data.Documents.length })
            downloadAll(record.ID, 'preview', record.FileName)
        }
        const viewAll = () => {
            setPreviewMode('move')
            let record = uncategorizedDoc[0]
            setHandleRecord({ ...record, num: 1, total: uncategorizedDoc.length})
            downloadAll(record.ID, 'move', record.FileName)
        }
        const moveDoc = (record, index, type) => {
            setPreviewMode(type)
            setHandleRecord({ ...record, num: index + 1, total: uncategorizedDoc.length})
            downloadAll(record.ID, type, record.FileName)
        }
        const expandedRowRender = (data) => {
            const nestedColumns = [
                {
                    title: '',
                    dataIndex: 'FileName',
                    key: 'FileName',
                    ellipsis: true,
                    render: (_, record, index) => (
                        <Tooltip arrow={false} placement="bottomLeft" title={removeFileExtension(record.FileName)}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '6px', marginLeft: 32 }}>
                                <img src={`/images/${record.MimeType === 'application/pdf' ? 'pdf' : 'img'}-icon-grey.svg`} /><div className='document-filename' onClick={() => previewDoc(data, record, index, 'preview')}>{removeFileExtension(record.FileName)}</div>
                            </div>
                        </Tooltip>
                    ),
                },
                {
                    title: '',
                    key: 'action',
                    width: 280,
                    render: (_, record, index) => (
                        <div className='document-action-wrap'>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <div style={{color: '#545459', lineHeight: '20px', marginRight: 12}}>Last update: {record.Uploaded ? dayjs(record.Uploaded).format('MM/DD/YYYY') :"-"}</div>
                                <div style={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => downloadAll(record.ID, 'single', record.FileName)}><img style={{ width: 20, height: 20 }} src='/images/document-download-grey.svg' /></div>
                            </div>
                        </div>
                    ),
                },
            ]

            return <Table rowKey="ID" className='document-file-table' columns={nestedColumns} dataSource={data.Documents} pagination={false} showHeader={false} />
        }
        const handleSwitch = (checked) => {
            setSelectedSwitch(checked)
            filterData(searchKeyword, selectedCategory, checked, originalDocumentList, originalUncategorizedDoc)
        }
        const onSearchDoc = (e) => {
            const keyword = e.target.value.toLowerCase()
            setSearchKeyword(keyword)
            filterData(keyword, selectedCategory, selectedSwitch, originalDocumentList, originalUncategorizedDoc)
        }

        const handleChangeCategory = (value) => {
            setSelectedCategory(value)
            filterData(searchKeyword, value, selectedSwitch, originalDocumentList, originalUncategorizedDoc)
        }

        const bulkUpload = () => {
            setUploadMode('multiple')
            setShowDocumentModal(true)
        }
        const specialUpload = (type) => {
            setUploadMode('special')
            handleUpload({ TaskID: type })
        }
        return <div className="currentChecklistWrap" >
            <Spin spinning={tasksLoading}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className="checklist-title">
                        <span className='checklist-title-l'>Documents</span>
                    </div>
                    <div className='add-document-bar'>
                        <div className='document-switch'><Switch className='document-aciton' checked={selectedSwitch} onChange={handleSwitch} /><span>Action required</span></div>
                        <div className='action-bar'>
                            <Input
                                placeholder="Search documents"
                                onChange={onSearchDoc}
                                allowClear
                                prefix={<img src='/images/search-icon.svg' />}
                                style={{ width: 284, backgroundColor: '#FCFCFC', borderRadius: '6px', border: '1px solid #ECECEC' }}
                            />
                            <Select
                                className='category-select'
                                style={{
                                    minWidth: 180,
                                    height: 45
                                }}
                                mode="multiple"
                                maxTagCount={1}
                                allowClear
                                placeholder="All Categories"
                                onChange={handleChangeCategory}
                                suffixIcon={<CaretDownOutlined style={{ color: '#6E6E70' }} />}
                                options={categoryOptions}
                            />
                            <div className='upload-documents-btn' onClick={bulkUpload}>Upload documents</div>
                        </div>
                    </div>
                    <div className='document-checklist' ref={checklistWrapRef}>
                        {
                            uncategorizedDoc.length > 0 && selectedCategory.length === 0  && (
                                <div className={`${uncategorizedOpen ? '' : 'uncategorized-expand'} document-table-wrap`}>
                                    <Table className='document-table uncategorized-doc'
                                        rowKey="ID"
                                        rowHoverable={false}
                                        pagination={false}
                                        columns={uncategorizedColumns}
                                        dataSource={uncategorizedDoc}
                                    />
                                </div>
                            )
                        }
                        {
                            documentList.map(item => {
                                const defaultExpandedRowKeys = item.list.filter(record => record.TaskName === '').map(record => record.ID)
                                return (
                                    item.list.length > 0 ? (
                                        <div className={`${item.open ? '' : 'expand'} document-table-wrap`} ref={refs[item.type]} key={item.type}>
                                            <Table className='document-table'
                                                rowKey="ID"
                                                rowHoverable={false}
                                                // scroll={{ x: 810 }} 
                                                pagination={false}
                                                columns={columnsData(item.type)}
                                                dataSource={item.list}
                                                rowClassName={(record) => {
                                                    if (record.TaskName === '') {
                                                        return 'hidden-document'
                                                    } else if (record.Status === 'resubmit') {
                                                        return 'resubmit-document'
                                                    } else if (isApproachingDeadline(record.Deadline)) {
                                                        return 'overdue-document'
                                                    } else {
                                                        return ''
                                                    }
                                                }}
                                                expandable={{
                                                    defaultExpandedRowKeys: defaultExpandedRowKeys,
                                                    expandedRowRender,
                                                    rowExpandable: (record) => record.Documents.length > 0,
                                                    expandIcon: ({ expanded, onExpand, record }) =>
                                                        record.Documents.length === 0 ? null :
                                                            expanded ? (
                                                                <img src='/images/collapse-bottom-arrow-black.svg' onClick={e => onExpand(record, e)} />
                                                            ) : (
                                                                <img src='/images/collapse-right-arrow-black.svg' onClick={e => onExpand(record, e)} />
                                                            )
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        !selectedSwitch && searchKeyword === '' && selectedCategory.length === 0 ? (
                                            <Row className='document-checklist-item'>
                                                <Col className='checklist-item-title'>{getLabelByValue(categoryOptions, item.type).toUpperCase()}</Col>
                                                <Col className='checklist-item-num'>0  document</Col>
                                            </Row>
                                        ) : null
                                    )
                                )
                            })
                        }
                        {documentList.every(item => item.list.length === 0) && (selectedSwitch || searchKeyword !== '' || selectedCategory.length > 0) && <Empty style={{ margin: '100px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                </div>
            </Spin>
        </div>
    }
    const categoryOptions = [
        {
            label: 'Identification',
            value: 'identification'
        },
        {
            label: 'Income',
            value: 'income'
        },
        {
            label: 'Assets & debts',
            value: 'assets'
        },
        {
            label: 'Property',
            value: 'property'
        },
        {
            label: 'Miscellaneous',
            value: 'miscellaneous'
        },
        // {
        //     label: 'Process documents',
        //     value: 'internalDocuments'
        // }
    ]
    const timeRangeOptions = [
        {
            label: 'Past 30 days',
            value: 'recent30Days'
        },
        {
            label: 'Past 2 months',
            value: 'recent2Months'
        },
        {
            label: 'Past 3 months',
            value: 'recent3Months'
        },
        {
            label: 'Past 6 months',
            value: 'recent6Months'
        },
        {
            label: 'Past year',
            value: 'recent1Year'
        },
        {
            label: 'Past 2 year',
            value: 'recent2Years'
        }
    ]
    const isHeicOrHeif = (fileName) => {
        return /\.(heic|heif)$/i.test(fileName)
    }
    const draggerProps = {
        fileList: fileList,
        multiple: true,
        showUploadList: false,
        onChange(info) {
            const fileList = [...info.fileList]
            setFileList(fileList)
        },
        onDrop(e) {
            
        },
        beforeUpload: async (file) => {
            if (isHeicOrHeif(file?.name?.toLowerCase())) {
                let blob = undefined
                try {
                    blob = await heic2any({ blob: file, toType: "image/png" })
                } catch (error) {
                    message.warning("You can only upload image and PDF files.")
                    return Upload.LIST_IGNORE
                }
                const convertedFile = new File([blob], file?.name?.replace(/\.(heic|heif)$/i, ".png"), {
                    type: "image/png",
                })
                return convertedFile
            } else {
                const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf']
                const fileType = file.type
                const isAllowed = allowedFileTypes.includes(fileType)
                if (!isAllowed) {
                    message.warning('You can only upload image and PDF files.')
                    return Upload.LIST_IGNORE
                }
            }
            return true
        },
    }

    const handleValuesChange = (changedValues, allValues) => {

    }
    const getLabelByValue = (options, value) => {
        const foundOption = options.find(option => option.value === value)
        return foundOption ? foundOption.label : null
    }
    const handleDeleteDoc = (index) => {
        const updatedFile = [...fileList]
        updatedFile.splice(index, 1)
        setFileList(updatedFile)
        form.setFieldsValue({ selectFile: updatedFile })
    }
    const removeFileExtension = (fileName) => {
        return fileName.replace(/\.[^/.]+$/, "")
    }
    const showCreateNewTasks = () => {
        return (
            <div className="task-modal-wrap">
                <Spin className="task-spin" size="large" tip="Auto generating documents for your task" spinning={loading}>
                    <Form
                        className='task-form'
                        form={form}
                        onValuesChange={handleValuesChange}
                    >
                        <div className="create-task-title">Upload document</div>
                        <div className="create-task-content">
                            {
                                (uploadMode === 'single' || uploadMode === 'reupload' || uploadMode === 'special') && (
                                    <Row gutter={20}>
                                        <Col className="gutter-row" span={24} md={12}>
                                            <div className="form-item-label">Document name</div>
                                            <div className='edit-wrap'>{currentDoc && currentDoc.TaskName ? currentDoc.TaskName : '-'}</div>
                                        </Col>
                                        <Col className="gutter-row" span={24} md={12}>
                                            <div className="form-item-label">Category</div>
                                            <div className='edit-wrap'>{currentDoc && currentDoc.TaskID ? getLabelByValue(categoryOptions, currentDoc.TaskID) : '-'}</div>
                                        </Col>
                                        <Col className="gutter-row" span={24} md={24}>
                                            <div className="form-item-label">Message from loan officer</div>
                                            <div style={{marginBottom: 12}} className='edit-wrap'>{currentDoc && currentDoc.Description ? currentDoc.Description : '-'}</div>
                                        </Col>
                                    </Row>
                                )
                            }
                            <Row>
                                <Col className="gutter-row" span={24} md={24}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        name="selectFile"
                                        valuePropName="fileList"
                                        getValueFromEvent={e => e.fileList}
                                    >
                                        <Dragger {...draggerProps}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <Col span={24} className='upload-tips'>Accepted formats: PDF, JPEG, JPG, PNG, HEIC, HEIF</Col>
                                <div className='file-list'>
                                    {
                                        fileList.map((item, index) => (
                                            <div key={item.uid} className='upload-doc-item'><div><img className='pdf-icon' src={`/images/${item.type === 'application/pdf' ? 'pdf' : 'img'}-icon.svg`} /><span>{removeFileExtension(item.name)}</span></div><img src='/images/document-delete.svg' onClick={() => handleDeleteDoc(index)} className='delete-doc' /></div>
                                        ))
                                    }
                                </div>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </div>
        )
    }
    const bulkupload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const formData = new FormData()
        fileList.forEach(item => {
            formData.append(`upload`, item.originFileObj)
        })
        setSaveLoading(true)
        fetch('/docs/bulkuploaddocuments', {
            cache: 'no-cache',
            method: 'POST',
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
        }).then(response => {
            setSaveLoading(false)
            if (response.status !== 200) {
                message.error('something went wrong please try later')
                return
            }
            taskCancel()
            getdocuments()

        }).catch(error => {
            setSaveLoading(false)
            message.error('something went wrong please try later')
        })
    }

    const sendEmailRequest = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/docs/notifyloaboutuploadeddocuments', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
        })
    }

    const uploadDocuments = () => {
        let token = sessionStorage.getItem("ZeitroA")
        const formData = new FormData()
        fileList.forEach(item => {
            formData.append(`upload`, item.originFileObj)
        })
        setSaveLoading(true)
        let api = uploadMode === 'single' ? '/docs/uploaddocumentsforrequest' : '/docs/reuploaddocumentsforrequest'
        fetch(api, {
            cache: 'no-cache',
            method: 'POST',
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-TASKID": currentDoc.ID,
                "X-LOAN": ''
            },
        }).then(response => {
            setSaveLoading(false)
            if (response.status !== 200) {
                message.error('something went wrong please try later')
                return
            }
            // Call sendEmailRequest after 10 minutes
            setTimeout(() => {
                sendEmailRequest();
            }, 10 * 1000);
            // }, 10 * 60 * 1000);
            
            taskCancel()
            getdocuments()

        }).catch(error => {
            setSaveLoading(false)
            message.error('something went wrong please try later')
        })
    }
    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }
    const downloadAll = (id, type, name) => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/docs/downloaddocuments', {
            method: 'POST',
            body: JSON.stringify({ LoanID: '', TaskID: type === 'all' ? id : 0, DocID: type !== 'all' ? id : 0 }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(async response => {
            if (response.status !== 200) {
                return
            }
            await response.json().then(js => {
                let files = js.Documents
                if (type === 'preview' || type === 'move') {
                    let file = base64ToArrayBuffer(files[0].Content)
                    setFile(file)
                    setScale(1)
                    setShowPreviewModal(true)
                } else if (files.length === 0) {

                } else if (files.length === 1) {
                    let file = base64ToArrayBuffer(files[0].Content)
                    const modifiedPdfBlob = new Blob([file], { type: 'application/pdf' })
                    const downloadLink = document.createElement('a')
                    downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
                    downloadLink.download = files[0].Name
                    downloadLink.click()
                } else {
                    let filename = `${name}.zip`
                    let zip = new JSZip()
                    let filesFolder = zip.folder(`${name}`)
                    for (let i = 0; i < files.length; i++) {
                        let { Name, Content } = files[i]
                        filesFolder.file(Name, Content, { base64: true })
                    }
                    zip.generateAsync({ type: "blob" }).then(blob => saveAs(blob, filename))
                }
            })
        }).catch(() => {

        })
    }
    const documentSave = () => {
        if (uploadMode === 'single' || uploadMode === 'reupload') {
            uploadDocuments()
        } else if (uploadMode === 'multiple') {
            bulkupload()
        } else {
            
        }
    }
    const taskCancel = () => {
        setShowDocumentModal(false)
        setFileList([])
        form.resetFields()
    }
    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1)
        setNumPages(numPages)
    }
    const handleZoom = (val) => {
        setScale(val)
    }
    const onChangePage = (page, pageSize) => {
        setPageNumber(page)
    }
    const previewCancel = () => {
        setPageNumber(1)
        setNumPages(1)
        setHandleForm('')
        setShowPreviewModal(false)
    }
    const moveFile = (taskID) => {
        let token = sessionStorage.getItem("ZeitroA")
        let data = {
            TaskID: taskID,
            DocID: handleRecord.ID
        }
        const matchedDocument = uncategorizedDoc.find(item => item.ID === handleRecord.ID)
        if (!matchedDocument) {
            message.error("The current document's status has changed. Please close the window and try again.")
        } else {
            fetch('/docs/movedoc', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "Content-Type": "application/json",
                },
        
            }).then(
                response => {
                    if (response.status !== 200) {
                        message.error('something went wrong please try later')
                        return;
                    }
                    if (response.status === 200 ) {
                        setShowPreviewModal(false)
                        getdocuments()
                    }
                }
        
            ).catch((err) => {
                
                message.error('something went wrong please try later')
            })
        }
    }
    const changeFile = (num) => {
        if (previewMode === 'move') {
            let record = uncategorizedDoc[num - 1]
            setHandleRecord({ ...record, num: num, total: uncategorizedDoc.length})
            downloadAll(record.ID, 'move', record.FileName)
        } else {
            let data = task
            let record = data.Documents[num - 1]
            setHandleRecord({ ...record, taskName: data.TaskName, num: num, total: data.Documents.length })
            downloadAll(record.ID, 'preview', record.FileName)
        }
    }
    const handleDocument = (type) => {
        setHandleForm('')
        if (type === 'upload') {
            setHandleForm('move')
            setUploadMode('multiple')
            setShowDocumentModal(true)
        } else {
            downloadAll(handleRecord.ID, 'single', handleRecord.FileName)
        }
    }
    const footer = [
        <Button className="task-btn-cancel" type="link" onClick={taskCancel}>Cancel</Button>,
        <Button className="task-btn-save" disabled={form.getFieldValue('selectFile') === undefined || (form.getFieldValue('selectFile') && form.getFieldValue('selectFile').length === 0)} loading={saveLoading} onClick={documentSave}>Upload</Button>
    ]
    return (
        <div style={{ height: '100%' }}>
            {contextHolder}
            {showCurrentTasks()}
            <Modal
                className="create-task-modal full-modal"
                width={976}
                centered={true}
                destroyOnClose
                open={showDocumentModal}
                title=""
                onCancel={taskCancel}
                footer={footer}
            >
                {showCreateNewTasks()}

            </Modal>
            <PreviewModal
                mode={previewMode}
                open={showPreviewModal}
                data={handleRecord}
                docmentList={documentList}
                file={file}
                pageNumber={pageNumber}
                numPages={numPages}
                width={pageWidth}
                scale={scale}
                moveFile={moveFile}
                change={changeFile}
                onCancel={previewCancel}
                handleDocument={handleDocument}
                handleZoom={(val) => handleZoom(val)}
                handlePage={(page, pageSize) => onChangePage(page, pageSize)}
                loadSuccess={(page) => onDocumentLoadSuccess(page)}
            >

            </PreviewModal>
        </div>
    )
}
Tasks = connect(mapStateToProps, mapDispatchToProps)(Tasks)