import './styles/newSideBarApplication.css'
import { useSelector, useDispatch } from 'react-redux'
import * as com from "../../Common"
import { Link, useParams } from 'react-router-dom'
import { Dropdown, Tooltip, Badge } from 'antd'
import { CheckSignInMethod, checkCustomerInfo } from '../api/api'

import { addTaskStatusUpdateListener, removeTaskStatusUpdateListener, updateTaskStatus } from '../../AppEvents'

import {
    LeftOutlined
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
function NewSideBarApplication(props) {
    const [method, setMethod] = useState('')
    const [menuExpand, setMenuExpand] = useState(false)
    const [taskCount, setTaskCount] = useState(0)
    const [companyName, setCompanyName] = useState('')
    const dispatch = useDispatch()
    const [currentCategory, setCurrentCategory] = useState(true)
    const customerid = com.getCustomerId()
    const application = useSelector(state => {

        return state.application
    })
    const progress = useSelector(state => {

        return state.progress
    })

    useEffect(() => {

        if (progress.interview.step == com.stageToNumberApplication.docs) {
            setMenuExpand(true)
        }
    }, [progress.interview.step])
    useEffect(async () => {

        console.log(customerid)

        updateTaskStatus()
        addTaskStatusUpdateListener((e) => {

            const taskDataLength = e.detail
            setTaskCount(taskDataLength)

        })
        try {
            const { Method } = await CheckSignInMethod({
                CustomerID: sessionStorage.getItem('cid'),
                Email: application.borrower.email


            })
            setMethod(Method)
            const { CompanyName } = await checkCustomerInfo({
                "X-CustomerId": com.getCustomerId()

            })
            setCompanyName(CompanyName)
        } catch (error) {

        }

        return () => {

            removeTaskStatusUpdateListener((e) => {

                const taskDataLength = e.detail
                setTaskCount(taskDataLength)

            })
        }
    }, [])
    let creditPulled = application.scre !== 0
    const items = [

        {
            label: (
                <Link
                    to={method === 'email' ? '/application/password' : '/application/changepassword'}

                    className='DropdownP'>

                    {
                        method === 'email' ? 'Set up password' : "Change password"
                    }
                </Link>
            ),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <Link to='/application/logout' className='DropdownP'>
                Sign out
            </Link>,
            key: '3',

        },
    ]
    const categoryOptions = [
        {
            label: 'Identification',
            value: 'identification'
        },
        {
            label: 'Income',
            value: 'income'
        },
        {
            label: 'Assets & debts',
            value: 'assets'
        },
        {
            label: 'Property',
            value: 'property'
        },
        {
            label: 'Miscellaneous',
            value: 'miscellaneous'
        },
        // {
        //     label: 'Process documents',
        //     value: 'internalDocuments'
        // }
    ]
    const selectCategory = (item) => {
        setCurrentCategory(item.value)
        dispatch({ type: "SUBSCRIPTION_INFO", data: { currentCategory: item.value } })

        // changeMenu(item.value)
    }
    let clickMenu = () => {
        setMenuExpand(!menuExpand)
    }

    let getApplicationUrl = () => {
        if (progress.interview.max) {
            return '/application/interview/main/applicant/' + progress.interview.max
        }
        return '/application/interview/main/applicant'
    }
    return (
        <div className="new-sidenav-application">
            <div >
                <div className='w-100 d-flex align-items-center  ' style={{ height: 70, borderBottom: '1px solid #DBDCDE', paddingLeft: 30 }}>
                    <div className='text-left' style={{
                        fontSize: 20, fontWeight: 500,
                        overflow: 'hidden',
                        fontWeight: 600,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        <Tooltip placement="bottom" title={companyName} >
                            {
                                companyName

                            }
                        </Tooltip>

                        {/* <img src='/logo3.svg'></img> */}
                    </div>
                </div>
                <div className='mt-2 text-left' style={{ cursor: 'pointer', paddingLeft: 26 }} onClick={() => {
                    window.open('/application/myloans', '_self')
                }}>
                    <LeftOutlined /> <span style={{ color: '#222', fontSize: 14, marginLeft: '10px' }}>Back to Loans</span>
                </div>
                <div style={{ paddingLeft: 26, paddingRight: 10 }} className='mt-3'>
                    <div className='d-flex align-items-start  slideInfo'>
                        {application.property.purpose !== 'purchase' ? <img src='/money.svg'></img> : <img src='/house.svg'></img>}
                        <div className='text-left ml-2'>
                            <div style={{ color: "#333", fontWeight: 500, fontSize: 14 }}>
                                {application.property.purpose == null ? '--' : application.property.purpose === 'cashoutrefinance' ? 'Cash out' : application.property.purpose == 'purchase' ? 'Purchase' : 'Refinance'}

                            </div>
                            <div style={{ color: "#333", fontWeight: 600, fontSize: 16 }}>{application.property.county == '' ? 'Address TBD' : com.removeCountySuffix(application.property.county) + ", " + com.convertStateToAbbr(application.property.state)}</div>
                            <div style={{ color: "#62707C", fontWeight: 400, fontSize: 14 }}> {application.property.propertytype == '' ? 'Purpose TBD' : com.getPropertyTypeName(application.property.propertytype)}  </div>
                        </div>

                    </div>
                </div>
                <div className='sideBarLink mt-3'>
                    <Link to={getApplicationUrl()} className="p-0">
                        <button id="btn-sub" className={progress.interview.step <= 11 ? "sidebar-button-selected sidebar-app selectbar-text" :
                            "sidebar-button sidebar-app"} ><span className="sidebartext-app">My  <span style={{ textTransform: 'lowercase' }}>application</span></span>
                        </button>
                    </Link>
                    {
                        com.getNumericalStatus(application.status) > 0 ?

                            <Link to='/application/interview/main/overview' className="p-0">
                                <button id="btn-sub" className={progress.interview.step === com.stageToNumberApplication.overview ? "sidebar-button-selected sidebar-app selectbar-text" :
                                    "sidebar-button sidebar-app"} ><span className="sidebartext-app" >Overview    </span>
                                </button>
                            </Link> : <a className='sidebarDisable-app p-0'>
                                <span className="sidebardisabletext-app" >Overview</span>
                                <img src="/images/sidebarLock.svg" style={{ width: 24, height: 24 }} />
                            </a>
                    }
                    {customerid == 'serenityhomeloans' ? null : <>     {
                        creditPulled && application.property.purpose === "purchase" ?
                            <Link to='/application/interview/main/prequalletter' className="p-0">
                                <button id="btn-sub" className={progress.interview.step === com.stageToNumberApplication.prequalletter ? "sidebar-button-selected sidebar-app selectbar-text" :
                                    "sidebar-button sidebar-app"} ><span className="sidebartext-app" >Pre-<span style={{ textTransform: 'lowercase' }}>qual letter</span></span>
                                </button>
                            </Link> : !creditPulled && application.property.purpose === "purchase" ? <a className='sidebarDisable-app p-0'>
                                <span className="sidebardisabletext-app" >Pre-<span style={{ textTransform: 'lowercase' }}>qual letter</span></span>
                                <img src="/images/sidebarLock.svg" style={{ width: 24, height: 24 }} />
                            </a> : ""
                    }</>}


                    {
                        creditPulled ?
                            <Link to='/application/interview/main/rates' className="p-0">
                                <button id="btn-sub" className={progress.interview.step === com.stageToNumberApplication.rates ? "sidebar-button-selected sidebar-app selectbar-text" :
                                    "sidebar-button sidebar-app"} >


                                    <span className="sidebartext-app" >My <span style={{ textTransform: 'lowercase' }}>rates</span></span>



                                </button>
                            </Link> : <a className='sidebarDisable-app p-0'>

                                <span className="sidebardisabletext-app" >My <span style={{ textTransform: 'lowercase' }}>rates</span></span>

                                <img src="/images/sidebarLock.svg" style={{ width: 24, height: 24 }} />
                            </a>
                    }
                    {
                        creditPulled ?
                            <Link to='/application/interview/main/declarations' className="p-0">
                                <button id="btn-sub" className={progress.interview.step == com.stageToNumberApplication.declarations ? "sidebar-button-selected sidebar-app selectbar-text" :
                                    "sidebar-button sidebar-app"} ><span className="sidebartext-app" > Declarations</span>
                                </button>
                            </Link> :
                            <a className='sidebarDisable-app p-0'>
                                <span className="sidebardisabletext-app" >Declarations</span>
                                <img src="/images/sidebarLock.svg" style={{ width: 24, height: 24 }} />
                            </a>
                    }

                    <Link to='/application/interview/main/docs' className="p-0" onClick={clickMenu}>
                        <button id="btn-sub" className={progress.interview.step == com.stageToNumberApplication.docs ? "sidebar-button-selected sidebar-app selectbar-text" :
                            "sidebar-button sidebar-app"} ><span className="sidebartext-app">Documents</span>
                            <img style={{ marginRight: 7 }} className={!menuExpand ? 'menuExpandIcon expand' : 'menuExpandIcon'} src="/images/menuExpandIcon.svg" />
                        </button>

                    </Link>
                    <div className="sidebar-menu-item" style={{ height: menuExpand ? 'auto' : 0 }}>
                        {
                            categoryOptions.map(item => (
                                <div className={`document-sidebar-children-item ${currentCategory === item.value ? 'children-active' : ''}`} onClick={() =>

                                    selectCategory(item)


                                }>{item.label}</div>
                            ))
                        }
                    </div>
                    <Link to='/application/interview/main/task' className="p-0">
                        <button id="btn-sub" className={progress.interview.step == com.stageToNumberApplication.task ? "sidebar-button-selected sidebar-app selectbar-text" :
                            "sidebar-button sidebar-app"} ><span className="sidebartext-app">Tasks</span>
                            {taskCount === 0 ? <></> : <div className='taskCount'>{taskCount}</div>}
                        </button>
                    </Link>



                </div>
                <div>
                </div>


            </div>
            <div style={{
                borderTop
                    : '1px solid #DBDCDE'
            }}>
                <div style={{ padding: '0 26px' }}>
                    <Dropdown menu={{ items }} >
                        <div className='d-flex  align-items-center pt-2 pb-2' >
                            <div className='NameBar'>{application.borrower.firstname.charAt(0)}</div>
                            <div className='ml-2 '>
                                <Tooltip placement="top" style={{ width: 200 }} title={<><span>{application.borrower.firstname}</span> <span>{application.borrower.lastname}</span></>} >
                                    <div className='text-left overflowH' style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}>{application.borrower.firstname}    {application.borrower.lastname}</div>
                                </Tooltip>
                                <Tooltip placement="top" title={application.borrower.email} >
                                    <div className='text-left overflowH' style={{
                                        color: "#6E6E70",
                                        fontSize: 14,
                                        fontWeight: 400
                                    }}>{application.borrower.email}</div>
                                </Tooltip>

                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>


        </div>


    )
}



export default NewSideBarApplication